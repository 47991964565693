import './style.css'
import UserPlaceholder from '../../images/user.png'
import { useNavigate } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useEffect, useState } from 'react'
import { Button } from '../../components/Button/Button'
import { useData } from '../../components/DataProvider/DataProvider'

export function NewConnector() {

    const navigate = useNavigate()
    const { data, updateData, showPopup } = useData()    
    const SELECT_CONFIG = "select_config"


    let [vendor, setVendor] = useState(null)
    let [technology, setTechnology] = useState(null)
    let [projectID, setProjectID] = useState(null)
    let [isCompleted, setIsCompleted] = useState(false)
    let [finalDict, setFinalDict] = useState({})

    let config = {
        "azure": [
            {
                "type": "azure-sql-server",
                "vendor": "Azure",
                "descriptor": "Azure SQL Server"
            },
            {
                "type": "azure-synapse-analytics",
                "vendor": "Azure",
                "descriptor": "Azure Synapse Analytics"
            },
            {
                "type": "azure-blob-storage",
                "vendor": "Azure",
                "descriptor": "Azure Storage Account"
            }
        ],
        "google": [
            {
                "type": "google-cloud-storage",
                "vendor": "Google",
                "descriptor": "Google Cloud Storage"
            },
            {
                "type": "google-cloud-bigquery",
                "vendor": "Google",
                "descriptor": "Google Cloud BigQuery"
            },
            {
                "type": "google-cloud-firestore",
                "vendor": "Google",
                "descriptor": "Google Cloud Firestore"
            }
        ],
        "aws": [
            {
                "type": "aws-s3",
                "vendor": "AWS",
                "descriptor": "AWS S3 Bucket"
            }
        ],
        "mongodb": [
            {
                "type": "mongo-db",
                "vendor": "MongoDB",
                "descriptor": "MongoDB"
            }
        ],
        "service-now": [
            {
                "type": "service-now-basic-auth",
                "vendor": "service-now",
                "descriptor": "ServiceNow REST API (Basic Auth)"
            }
        ]
    }

    let fields = {
        "google-cloud-bigquery": {
            "project_id": {
                "label": "Project ID",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "dataset_name": {
                "label": "BigQuery Dataset Name",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            }
        },
        "azure-blob-storage":{
            "connection_string":  {
                "label": "Connection String",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "container_name":{
                "label": "Container name",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "subfolder_path":{
                "label": "Subfolder path",
                "type": "text",
                "form_text": "In case of full loading, point the folder containing the files you want to load. In case of delta load, point the folder parent you want to analyse.",
                "mandatory":false
            },
            "load_mode": {
                "label": "Load mode",
                "type": "select",
                SELECT_CONFIG:{
                    "options" : [{
                        "value": "full",
                        "description": "Full load"  
                    },
                    {
                        "value": "delta",
                        "description": "Delta load"  
                    },
                    {
                        "value": "cmd",
                        "description": "Common Data Model",
                        "is_disabled": true
                    }],
                    "description": "Select a Loading mode"
                },
                "form_text": "Available full load (single file), delta (multiple files), Common Data Model. This is a mandatory field",
                "mandatory":true
            }
        },
        "azure-sql-server": {
            "server":  {
                "label": "Server host",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "database": {
                "label": "Database",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "username": {
                "label": "Username",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "password": {
                "label": "Password",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            }
        },
        "azure-synapse-analytics": {
            "server":  {
                "label": "Server host",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "database": {
                "label": "Database",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "username": {
                "label": "Username",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "password": {
                "label": "Password",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            }
        },
        "service-now-basic-auth":{
            "dataset_name": {
                "label": "Table name",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "username": {
                "label": "Username",
                "type": "text",
                "form_text": "ServiceNow authentication username. This is a mandatory field.",
                "mandatory":true
            },
            "password": {
                "label": "Password",
                "type": "password",
                "form_text": "ServiceNow authentication password. This is a mandatory field.",
                "mandatory":true
            },
            "server": {
                "label": "ServiceNow URL",
                "type": "text",
                "form_text": "This a mandatory field.",
                "mandatory":true
            },
            "column_list": {
                "label": "Filter columns",
                "type": "text",
                "form_text": "Column names to be selected separated by commas.",
                "mandatory":false
            },
            "where": {
                "label": "Row filter",
                "type": "text",
                "form_text": 
                <div>Row-wise filter (documentation at this <a href="https://developer.servicenow.com/dev.do#!/reference/api/sandiego/rest/c_TableAPI#table-GET" target="_blank"><u>link</u></a>, sysparm_query parameter).</div>,
                "mandatory":false
            },
            
        }
    }

    let text_fields_type = ["password", "text"]
    let select_fields_type = ["select"]

    // fields -> technology -> keys -> mandatory
   let mandatory_fields = Object.fromEntries(Object.entries(fields).map(([technology, keys]) => [technology, Object.keys(keys).filter(k=>keys[k]["mandatory"])]))
    /**
     * Validate the selected vendor.
     */
    function validateVendor(value) {
        let old_value = vendor
        if (value.length > 0)
            setVendor(value)
        else
            setVendor(null)
        
        if (old_value!==value){
            setTechnology(null)
        }

    }

    /**
     * Validate the selected technology.
     */
    function validateTechnology(value) {
        if (value.length > 0)
            setTechnology(value)
        else
            setTechnology(null)
    }

    /**
     * Validate the project ID.
     */
    function validateProjectID(value) {
        if (value.length > 0)
            setProjectID(value)
        else
            setProjectID(null)
    }

    function validateSelectGeneric(k, v) {
        if (v.length > 0)
            addValue(k, v)
    }

    /**
     * Add in the final dictionary the value V for the key K.
     */
    function addValue(k, v){
        let tmp = {...finalDict}
        tmp[k] = v

        if (v.length === 0)
            delete tmp[k]

        setFinalDict(tmp)
    }

    /**
     * Create a key in order to disable key warning
    */
    function buildKeyOfElement(el, type, index){
        return el + "-" + type + "-" + index
    }

    /**
     * API call to create a new project.
     */
    function createNewConnector() {
        updateData({
            request: "create_new_connector",
            payload: {
                type: technology,
                vendor: vendor,
                project_id: projectID,
                config: finalDict
            },
            onSuccess: () => {
                navigate(-1)
                showPopup("success", "Success!", "The new connector has been created.")
            },
            onFail: () => {
                showPopup("error", "Failed!", "Something went wrong with the connector creation.")
            }
        })
    }

    

    const isSubset = (array1, array2) =>
            array2.every((element) => array1.includes(element));

    /**
     * Set the isCompleted field that indicates if the form is filled correctly.
     */
    //FIXME connectors that do not populate finalDict cannot be created
    useEffect(() => {
        setIsCompleted(vendor !== null && technology !== null && projectID !== null && 
            (technology in fields?
                isSubset(Object.keys(finalDict), mandatory_fields[technology]):
                    Object.keys(finalDict).length>0)) // default is that at least one object is in finalDict if it is not present in fields
    }, [vendor, technology, projectID, finalDict])
    return <BaseTheme title="Create a new connector" activeItem="connections">
        <div className="container-fluid">
            <div className="row">
                {/* First column */}
                <div className='col-12 col-md-6'>

                    <div className='row'>

                        {/* SELECT THE VENDOR */}
                        <div className="mb-4 col-6">
                            <label className={"text-lg form-label"}>Vendor</label>
                            <select onChange={(e) => validateVendor(e.target.value)} className={"form-select" + (vendor !== null ? " is-valid" : "")}>
                                <option value="">Select a vendor...</option>
                                {Object.keys(config).map((vendor, i) => {
                                    return <option key={i+vendor} value={vendor}>{vendor}</option>
                                })}
                            </select>
                            <div id="emailHelp" className="form-text">Select the vendor of the data source.</div>
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>

                        {/* SELECT THE TECHNOLOGY */}
                        <div className="mb-4 col-6">
                            <label className={"text-lg form-label"}>Technology</label>
                            <select onChange={(e) => validateTechnology(e.target.value)} disabled={vendor === null} className={"form-select" + (technology !== null ? " is-valid" : "")}>
                                <option value="">Select a technology...</option>
                                {vendor !== null && config[vendor].map((tech, i) => {
                                    return <option key={i+tech.type} value={tech.type}>{tech.descriptor}</option>
                                })}
                            </select>
                            <div id="emailHelp" className="form-text">Select the technlogy of the data source.</div>
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>

                    {/* Project ID */}
                    <div className="mb-4">
                        <label className={"text-lg form-label"}>Project ID</label>
                        <select onChange={(e) => validateProjectID(e.target.value)} disabled={technology === null} className={"form-select" + (projectID !== null ? " is-valid" : "")}>
                            <option value="">Select a project...</option>
                            {technology !== null && data.projects.map((p, i) => {
                                return <option key= {i+p.project_id} value={p.project_id}>{p.project_name}</option>
                            })}
                        </select>
                        <div id="emailHelp" className="form-text">Select a project to which link the new connector.</div>
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    
                    {(technology !== null && technology in fields) && Object.keys(fields[technology]).map((item, index) =>
                        <div key={buildKeyOfElement("div", item, index)} className="mb-4">
                            <label className={"text-lg form-label"}>{fields[technology][item].label}</label>
                            {
                            (text_fields_type.includes(fields[technology][item].type)) &&
                            <input disabled={projectID === null} type={fields[technology][item].type} onChange={(e) => addValue(item, e.target.value)} className={"form-control" + ((item in finalDict) ? " is-valid" : "")}/>
                            }
                            {
                            (select_fields_type.includes(fields[technology][item].type)) &&
                            <select disabled={projectID === null}
                                    onChange={(e) => validateSelectGeneric(item, e.target.value)}
                                    className={"form-select" + ((item in finalDict) ? " is-valid" : "")}>
                                <option value="" key = {"select-" + item + "-default"}>
                                    {fields[technology][item].SELECT_CONFIG["description"]}
                                </option>

                                {fields[technology][item].SELECT_CONFIG["options"].map((p, i) => {
                                    return <option value={p.value} disabled={p.is_disabled} key={buildKeyOfElement("select", item, i)}>{p.description}</option>
                                })}
                            </select>
                            }

                            <div id="emailHelp" className="form-text">{fields[technology][item].form_text}</div>
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>)
                    }

                    {/* Button */}
                    <div className="mb-4">
                        <Button action={() => createNewConnector()} disabled={!isCompleted} color="btn-outline-1" icon="bi bi-boxes">Create the new connector</Button>
                    </div>

                </div>

            </div>
        </div>
    </BaseTheme>
}