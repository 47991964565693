import './style.css'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { useEffect, useLayoutEffect, useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export function Quality() {

  const navigate = useNavigate()
  const { data, updateData } = useData()

  const params = useParams()
  const projectName = params.id
  const datasetID = params.dataset_id

  let [qualityData, setQualityData] = useState(null)

  useEffect(() => {
    if (data !== null)
        setQualityData(data.projects[projectName].datasets.ds2)
  }, [data])

  useLayoutEffect(() => {

    if (qualityData != null) {
      
      let root = am5.Root.new("chartdiv");

      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout
      }));


      // Create axes and their renderers
      let yRenderer = am5xy.AxisRendererY.new(root, {
        visible: false,
        minGridDistance: 20,
        inversed: true
      });

      yRenderer.grid.template.set("visible", false);

      let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        renderer: yRenderer,
        categoryField: "index"
      }));

      let xRenderer = am5xy.AxisRendererX.new(root, {
        visible: false,
        minGridDistance: 30,
        opposite: true
      });

      xRenderer.labels.template.setAll({
        rotation: -90,
      });

      xRenderer.grid.template.set("visible", false);

      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "column",
      }))


      // Create series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        calculateAggregates: true,
        stroke: am5.color(0xffffff),
        clustered: false,
        xAxis: xAxis,
        yAxis: yAxis,
        categoryXField: "column",
        categoryYField: "index",
        valueField: "value"
      }));

      series.columns.template.setAll({
        //tooltipText: "{value}",
        strokeOpacity: 0,
        strokeWidth: 0,
        width: am5.percent(100),
        height: am5.percent(100)
      });

      series.columns.template.events.on("pointerover", function (event) {
        let di = event.target.dataItem;
        if (di) {
          heatLegend.showValue(di.get("value", 0));
        }
      });

      series.events.on("datavalidated", function () {
        heatLegend.set("startValue", series.getPrivate("valueHigh"));
        heatLegend.set("endValue", series.getPrivate("valueLow"));
      });


      // Set up heat rules
      // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
      series.set("heatRules", [{
        target: series.columns.template,
        min: am5.color(0xef4343),
        max: am5.color(0xDDDDDD),
        dataField: "value",
        key: "fill"
      }]);


      // Add heat legend
      // https://www.amcharts.com/docs/v5/concepts/legend/heat-legend/
      let heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
        orientation: "horizontal",
        endColor: am5.color(0xef4343),
        startColor: am5.color(0xDDDDDD)
      }));


      // Set data
      // https://www.amcharts.com/docs/v5/charts/xy-chart/#Setting_data
      let vals = qualityData.quality.data

      series.data.setAll(vals);

      yAxis.data.setAll(qualityData.quality.indexes);

      xAxis.data.setAll(qualityData.quality.cols);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
      chart.appear(1000, 100);

      return () => {
        root.dispose();
      }

    }
  }, [qualityData]);

  return <BaseTheme title={"Customer Analytics from GA"} activeItem="projects" to="project page">
    <div className="container-fluid">

        <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12 col-md-auto me-md-3'>
              <div className='col-12 text-xl'>
                {projectName}
              </div>
              <div className='col-12 text-secondary'>
                ID of the current inspected project
              </div>
            </div>
            <div className='col-12 col-md-auto'>
              <div className='col-12 text-xl'>
                {datasetID}
              </div>
              <div className='col-12 text-secondary'>
                ID of the current inspected dataset
              </div>
            </div>
            <div className='col-12 col-md-auto'>
              <div className='col-12 text-xl'>
                2020-01-03 21:19:02
              </div>
              <div className='col-12 text-secondary'>
                Date and time of metadata inspection
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-5'>
        <div id="chartdiv" style={{ width: "100%", height: "800px" }}></div>
      </div>

    </div>
  </BaseTheme>

}