import axios from "axios"

async function make_call(operation, payload = {}, onSuccess = null, onFail = null) {

  try {
    let data = await axios.post(process.env.REACT_APP_API_SERVER + '/perform?ts=' + Math.floor(Math.random() * 1000000), {
      "operation": operation,
      "token": localStorage.getItem("adq-token"),
      "username": localStorage.getItem("adq-username"),
      "payload": payload
    }, {
      headers: {
        "Content-type": "application/json"
      },
    })

    let response = data.data

    // Get the status
    let responseStatus = data.status
    let status = response.status

    // Print in the console the exception
    if (status !== 200) {
      console.error('------ ' + operation + ' ------')
      console.error(payload)
      console.error('Status -> ' + status)
      console.error(response)
      console.error('------------------')
    }

    // If the call success and onSuccess object is different from null than execute the operation specified by the user
    if (status === 200 && onSuccess !== null)
      onSuccess(response.payload)

    // If the call fails and onFail object is different from null than execute the operation specified by the user
    if (status !== 200 && onFail !== null) {
      onFail(response)
    }

    return status === 200 ? response.payload : []

  } catch (e) {
    if (e !== null && e.response !== undefined && e.response !== null && Object.keys(e.response).includes("status") && e.response.status === 403) {
      localStorage.removeItem("adq-username")
      localStorage.removeItem("adq-token")
    }

    switch (e.message.toLowerCase()) {
      case "network error":
        return "NE";
      default:
        break;
    }

    console.error(e)
    return "KO"
  }
}

export async function call(api, payload, onSuccess, onFail) {

  // If the payload is still null return KO because the function is
  // not yet enabled!
  if (payload == null)
    return "KO"

  // Perform the call
  let data = await make_call(api, payload, onSuccess, onFail)

  // Return the result payload
  return data

}

// GET ALL PROJECTS
export async function get_projects(payload) {

  return await make_call("get_projects", payload)

}

// GET ALL USERS
export async function get_users() {

  return await make_call("get_users")

}

// GET ALL USERS
export async function get_all_projects_id() {

  return await make_call("get_all_projects_id")

}

// GET ALL TABLES
export async function get_all_tables() {

  return await make_call("get_all_tables")

}

// GET ALL TABLES OF A SPECIFIC PROJECT
export async function get_tables_by_project() {

  return await make_call("get_tables_by_project")

}

// GET ALL CONNECTORS RELATED TO THE PROJECT
export async function get_connectors(payload) {

  return await make_call("get_connectors", payload)

}

// GET ALL JOBS
export async function get_jobs(payload) {

  return await make_call("get_jobs", payload)

}

// GET CONFIGURATIONS
export async function get_config(payload) {

  return await make_call("get_config", payload)

}

// GET CONFIGURATIONS
export async function get_license() {

  let res = await make_call("get_license", {})

  if (res === "NE" || res === "KO")
    return null

  return res

}

// GET PROBE FEEDBACK
export async function get_probe_feedback(payload) {

  return await make_call("get_probe_feedback", payload)

}

// UPDATE PROBE FEEDBACK
export async function update_probe_feedback(payload) {

  return await make_call("update_probe_feedback", payload)

}

export async function get_consumes() {

  let res = await make_call("get_tool_usage", {})

  if (res === "NE" || res === "KO")
    return null

  return res

}