import { useEffect } from 'react'
import { useState } from 'react'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { Block } from '../../components/Block/Block'
import { Button } from '../../components/Button/Button'
import { useData } from '../../components/DataProvider/DataProvider'
import './style.css'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from 'react'
import { getDate } from '../../utils/utils'
import { SmartComponent } from '../../components/SmartComponent/SmartComponent'

export function Home() {

  const { data, updateData, showPopup } = useData()

  let [editLicense, setEditLicense] = useState(false)
  let [key, setKey] = useState(null)
  let [clientID, setClientID] = useState(null)

  useEffect(() => {
    if (data !== null && "license" in data) {
      setEditLicense(data.license === null)
    }
  }, [data])

  useLayoutEffect(() => {

    if (data !== null && data.consumes !== null && data.consumes.length > 0) {

      let root = am5.Root.new("consumes-plot");

      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      getNumericalSeries(root, data.consumes)

      return () => {
        root.dispose();
      };
    }

  }, [data]);

  const setLicenseKey = (val) => {
    setKey(val !== null && val.length > 1 ? val : null)
  }

  const setClient = (val) => {
    setClientID(val !== null && val.length > 1 ? val : null)
  }

  const activate = () => {

    updateData({
      request: "activate_license",
      payload: {
        key: key,
        client_id: clientID
      },
      onSuccess: () => {
        showPopup("success", "Success!", "License activated successfully!")
        setEditLicense(false)
      },
      onFail: () => {
        showPopup("error", "Failed!", "Provided license is not valid!")
        setEditLicense(true)
      }
    })
  }

  function getNumericalSeries(root, plotData) {

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        panX: false,
        layout: root.verticalLayout
      })
    );

    // Craete Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "month", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series with breaks",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "period",
        valueYField: "data_volume",
        openValueYField: "job_id"
      })
    );
    series.strokes.template.setAll({
      strokeWidth: 3
    });
    series.fills.template.setAll({
      fillOpacity: 0.5,
      visible: true
    });

    series.data.setAll(plotData);

  }

  function getEuros() {
    // if (data !== null && data.consumes !== null && data.consumes.length > 0) {
    //   let v = (parseInt(data.consumes[data.consumes.length - 1].data_volume / 1000)) * 15
    //   return (v).toLocaleString('it-IT', {
    //     style: 'currency',
    //     currency: 'EUR',
    //   })
    // }
    return "-.-- €"
  }

  return <BaseTheme title="Home" activeItem="home" nav={false}>
    <div className="container-fluid">
      <div className='row'>
        <div className='col-4'>
          <Block>
            <div className='col-12 p-4 pb-0'>

              {/* Pill */}
              <div className='row'>
                <div className='col-auto'>
                  <div className='pill-blue-dark'>
                    License
                  </div>
                </div>
              </div>

              <SmartComponent isLoaded={data !== null && data.license !== undefined} className='mt-4'>
                <div className='row'>
                  <div className="col-12">

                    {/* First info block */}
                    {(editLicense === false && data !== null && data.license !== null) && <div className='row'>

                      <div className="col-12 mb-3">
                        <div className="project-title text-lg">
                          License status
                        </div>
                      </div>

                      <div className='col-12 mb-4'>
                        <div className='text-sm text-bold'>Key</div>
                        <div className='w-100 text-break'>{data.license.key}</div>
                      </div>

                      {/* <div className='col-4'>
                        

                      </div> */}

                      <div className='col-4 mb-4'>
                        <div className='text-sm text-bold'>Client ID</div>
                        <div>{data.license.client_id}</div>
                      </div>

                      <div className='col-4 mb-4'>
                        <div className='text-sm text-bold'>Kind</div>
                        <div>Pay-as-you-go</div>
                      </div>

                      <div className='col-4 mb-4'>
                        <div className='row'>
                          <div className='text-sm text-bold'>Status</div>
                          <div className='col-auto'>
                            <div className='pill-green'>
                              ACTIVE
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-4 mb-4'>
                        <div className='text-sm text-bold'>Activation date</div>
                        <div>{getDate(data.license.timestamp)}</div>
                      </div>

                      <div className='col-4 mb-4'>
                        <div className='text-sm text-bold'>Expiration date</div>
                        <div>{getDate(data.license.expiration_date)}</div>
                      </div>

                    </div>}

                    {(editLicense === true && data !== null && data.license === null) && <div className='row mt-4'>

                      <div className="col-12 mb-3">
                        <div className="project-title text-lg">Insert a RelAI license</div>
                      </div>

                      <div className='col-12 mb-3'>
                        <div className='text-sm text-bold'>License key</div>
                        <div>
                          <input onChange={(e) => setLicenseKey(e.target.value)} className='w-100' type="text" name="key" />
                        </div>
                      </div>

                      <div className='col-12'>
                        <div className='text-sm text-bold'>Client ID</div>
                        <div>
                          <input onChange={(e) => setClient(e.target.value)} className='w-100' type="text" name="key" />
                        </div>
                      </div>

                    </div>}

                  </div>

                  {/* Action block */}
                  <div className='col-12 m-0 py-3 mt-3 px-0 action-block d-flex flex-row justify-content-end'>
                    {editLicense === false && <Button icon="bi bi-door-open" action={() => setEditLicense(!editLicense)}>Provide a license</Button>}
                    {editLicense === true && <Button disabled={key === null || clientID === null} icon="bi bi-door-open" action={() => activate()}>Save license</Button>}
                  </div>

                </div>
              </SmartComponent>
            </div>
          </Block>

        </div>

        <div className='col-8'>
          <Block>
            <div className='col-12 p-4'>

              {/* Pill */}
              <div className='row'>
                <div className='col-auto'>
                  <div className='pill-blue-dark'>
                    Tool usage
                  </div>
                </div>
              </div>

              <SmartComponent isLoaded={data !== null && data.consumes !== null} className='mt-4'>
                <>
                  <div className='row'>
                    <div className='col-auto me-5 mb-3'>
                      <div className='text-sm text-bold'>Current month billing</div>
                      <div className='text-xl'>
                        {getEuros()}
                      </div>
                    </div>
                    <div className='col-auto me-5 mb-3'>
                      <div className='text-sm text-bold'>Current month processed data</div>
                      <div className='text-xl'>
                        {(data !== null && data.consumes !== null && data.consumes.length > 0) && parseInt(data.consumes[data.consumes.length - 1].data_volume).toLocaleString('it-IT')} <span className='text-sm'>records</span>
                      </div>
                    </div>
                  </div>

                  {/* First info block */}
                  {(data !== null && data.consumes !== null && data.consumes.length > 0) && <div className='row mt-4'>

                    <div className="col-12 mb-3">
                      <div className="project-title text-lg">Tool usage overview</div>
                    </div>

                    <div id={"consumes-plot"} className="p-0" style={{ width: "100%", height: "250px" }}></div>

                  </div>}
                </>
              </SmartComponent>

            </div>
          </Block>
        </div>
      </div>
    </div>
  </BaseTheme>
}