import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5 from "@amcharts/amcharts5";

export const getDescription = (cluster) => {
    switch (cluster.probe) {
        case "column_outlier":
            return "Column outlier: " + cluster.target_column + " has anomalous values."
        case "null_fixing":
            return "Null fixing: " + cluster.target_column + " has values to be fixed."
        case "row_outlier":
            return "Row outlier: the following rows are anomalous."
        case "ts_univariate_probe":
            return cluster.description
        case "formatting_probe":
            return cluster.description
        case "deduplication_probe":
            return cluster.description
        case "join_check":
            return cluster.value
        default:
            console.log("TO BE IMPLEMENTED", cluster.probe)
    }
}

export function getCategorical(root, plotData, metric) {
    // metrics whose rootcauses values lie within the [0, 1] range
    const metrics_0_1_range = ["consistency", "completeness", "timeliness", "integrity"]

    var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panY: false,
            panX: false,
            layout: root.verticalLayout
        })
    );

    // Create Y-axis
    if (metrics_0_1_range.includes(metric))
        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                max: 1,
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );
    else
        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

    var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({
        rotation: 0,
        centerY: am5.p50,
        centerX: am5.p50,
        paddingRight: 0,
        paddingTop: 10,
        oversizedBehavior: "wrap",
        maxWidth: 100
    });

    // Create X-Axis
    var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: "index",
            renderer: xRenderer
        })
    );

    yAxis.maximum = 1

    xAxis.data.setAll(plotData);

    var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            name: "Series with breaks",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            categoryXField: "index",
        })
    );

    series.columns.template.setAll({
        fillOpacity: 0.5,
        strokeWidth: 3,
        cornerRadiusTL: 5,
        cornerRadiusTR: 5
    });

    series.data.setAll(plotData);

}

export function getNumericalSeries(root, plotData, anomalies) {
    var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panY: false,
            panX: false,
            layout: root.verticalLayout
        })
    );

    // Craete Y-axis
    var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        })
    );

    // Create X-Axis
    var xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            /* baseInterval: { timeUnit: "day", count: 1 }, */
            renderer: am5xy.AxisRendererX.new(root, {}),
        })
    );

    var series = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: "Series with breaks",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "index"
        })
    );
    series.strokes.template.setAll({
        strokeWidth: 3
    });
    series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
    });

    series.data.setAll(plotData);

    var series = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: "Series with breaks",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "index"
        })
    );
    series.strokes.template.setAll({
        strokeWidth: 3
    });
    series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
    });

    series.strokes.template.setAll({
        templateField: "strokeSettings",
        strokeOpacity: 0

    });

    series.bullets.push(function () {
        return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
                strokeWidth: 3,
                stroke: root.interfaceColors.get("negative"),
                radius: 5,
                fill: root.interfaceColors.get("background")
            })
        });
    });

    series.data.setAll(anomalies);

}

export function getNumericalTimeSeries(root, plotData, anomalies) {
    plotData.forEach(function (dataPoint) {
        dataPoint.index = new Date(dataPoint.index).toISOString(); // Converti il timestamp in oggetto Date
    });
    plotData.sort((a, b) => new Date(a.index) - new Date(b.index));
    plotData.forEach(function (dataPoint) {
        dataPoint.index = new Date(dataPoint.index).getTime(); // Converte la data in timestamp Unix in millisecondi
    });
    plotData = plotData.slice()

    anomalies.forEach(function (dataPoint) {
        dataPoint.index = new Date(dataPoint.index).toISOString(); // Converti il timestamp in oggetto Date
    });
    anomalies.sort((a, b) => new Date(a.index) - new Date(b.index));
    anomalies.forEach(function (dataPoint) {
        dataPoint.index = new Date(dataPoint.index).getTime(); // Converte la data in timestamp Unix in millisecondi
    });
    anomalies = anomalies.slice();

    var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panY: false,
            panX: false,
            layout: root.verticalLayout
        })
    );

    // Craete Y-axis
    var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        })
    );

    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "hour", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {})
      })
    );

    var series = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: "Series with breaks",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "index",
        })
    );
    series.strokes.template.setAll({
        strokeWidth: 3
    });
    series.fills.template.setAll({
        fillOpacity: 0,
        visible: true
    });

    series.data.setAll(plotData);

    var series = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: "Series with breaks",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "index",
            fill: "transparent"
        })
    );
    series.strokes.template.setAll({
        strokeWidth: 3
    });
    series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
    });

    series.strokes.template.setAll({
        templateField: "strokeSettings",
        strokeOpacity: 0

    });

    series.bullets.push(function () {
        return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
                strokeWidth: 3,
                stroke: root.interfaceColors.get("negative"),
                radius: 5,
                fill: root.interfaceColors.get("background")
            })
        });
    });

    series.data.setAll(anomalies);

}

export const getFormattingDescription = (key) => {
    let tmp = key.replace("_", " ")
    tmp = tmp.substring(0, 1).toUpperCase() + tmp.substring(1).toLowerCase()

    if (tmp.toLowerCase().includes("digits"))
        return "Average count of digits"
    else if (tmp.toLowerCase().includes("len"))
        return "Average string length"
    else if (tmp.toLowerCase().includes("others"))
        return "Average count of special chars"
    else if (tmp.toLowerCase().includes("letters"))
        return "Average count of letters chars"
    else if (tmp.toLowerCase().includes("spaces"))
        return "Average count of letters chars"

    return tmp
}


// Check if a variable is a dictionary
export const isArrayOfDict = (variable) => {
    if (variable === null || variable === undefined)
        return false
    return variable.length > 0 && typeof variable[0] === 'object'
}

export const isValidRootCause = (variable, metric) => {
    if (variable == null)
        return false
    if (metric != "validity")
        return isArrayOfDict(variable)
    // for validity both arrayOfDict format and kde+anomalies format are valid
    return isArrayOfDict(variable) || (variable.kde != null && variable.anomalies != null && isArrayOfDict(variable.kde) && isArrayOfDict(variable.anomalies))
}