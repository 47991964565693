import './style.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { useEffect, useState } from 'react'
import { Block } from '../../components/Block/Block'
import { Button } from '../../components/Button/Button'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { MiniBlock } from '../../components/MiniBlock/MiniBlock'
import { getDescription, getCategorical, getNumericalSeries, getNumericalTimeSeries, getFormattingDescription, isValidRootCause, isArrayOfDict } from '../../utils/cluster_utils'

export function Cluster() {

  const { data, updateData, showPopup } = useData()
  const { hash } = useLocation();
  const navigate = useNavigate()
  const params = useParams()

  // ID of the current project
  const projectID = params.id

  // ID of the current table
  const tableID = params.table_id

  // ID of the current jobs
  const jobID = params.job_id

  // ID of the current metric
  const metric = params.cluster

  // metrics whose rootcauses values lie within the [0, 1] range
  const metrics_0_1_range = ["consistency", "completeness", "timeliness", "integrity"]

  const offset = 5

  // Current project
  let [clusters, setClusters] = useState(null)
  let [anomalies, setAnomalies] = useState(null)
  let [hidePlot, setHidePlot] = useState([])
  let [currentClusters, setCurrentClusters] = useState(null)
  let [page, setPage] = useState(0)
  let [maxPages, setMaxPages] = useState(0)

  useEffect(() => {
    if (clusters === null && data !== null && Object.keys(data).includes("clusters")
      && Object.keys(data.clusters).includes(projectID)
      && Object.keys(data.clusters[projectID]).includes(tableID)
      && Object.keys(data.clusters[projectID][tableID].jobs).includes(jobID)) {

      let newClusters = data.clusters[projectID][tableID].jobs[jobID]
      setClusters(newClusters)

      if (newClusters !== null) {
        let maxPages = Math.ceil(Object.keys(newClusters.clusters[metric].clusters).length / offset)
        setMaxPages(maxPages)
      }

    }
  }, [])

  useEffect(() => {
    if(clusters !== null)
        sortClusters()
  }, [clusters]);


  useEffect(() => {
    if (page >= 0 && page < maxPages && clusters !== null) {
      let currentClustersArray = Object.entries(clusters.clusters[metric].clusters).slice(page * offset, (page + 1) * offset)
      setCurrentClusters(currentClustersArray)
    }

  }, [page, maxPages, clusters])

  useEffect(() => {
    setPage(parseInt(hash.slice(1)))
  }, [hash])

  useEffect(() => {
    if (currentClusters !== null) {
      buildPlots()
    }
  }, [currentClusters])

  const getProbeName = (name) => {
    if (name.toLowerCase().includes('time series')) {
      return "ts_univariate_probe"
     }
    else if (name.toLowerCase().includes('row')) {
      return "row_outlier"
      }
    else if (name.toLowerCase().includes('null')) {
      return "null_fixing"
      }
    else if (name.toLowerCase().includes('col')) {
      return "column_outlier"
      }
    else
      console.log("TO BE IMPLEMENTED", name)
  }

  function maybeDisposeRoot(divId) {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root !== undefined && root.dom.id == divId) {
        root.dispose();
      }
    });
  };

  const sortClusters = () => {
    // cycle over elements of "clusters.clusters":
    // - if element.clusters is not empty, order its sub-elements wrt "element[sub-element].anomalies"

    const ordinaElementiPerAnomalies = (elemento) => {
      var clusters = elemento.clusters;
      var clusterKeys = Object.keys(clusters);

      clusterKeys.forEach(function (key) {
        var currentCluster = clusters[key];
        if (currentCluster.clusters && Object.keys(currentCluster.clusters).length > 0) {
          currentCluster.clusters = Object.fromEntries(
            Object.entries(currentCluster.clusters).sort((a, b) => b[1].anomalies - a[1].anomalies)
          );
        }
      });

      elemento.clusters = Object.fromEntries(
        Object.entries(elemento.clusters).sort((a, b) => b[1].anomalies - a[1].anomalies)
      );
    }

    var clusterKeys = Object.keys(clusters.clusters);

    clusterKeys.forEach(function (key) {
      ordinaElementiPerAnomalies(clusters.clusters[key]);
    });

  }

  const buildPlots = () => {

    currentClusters.map(([cluster_name, cluster], index) => {
      let div_id = "deepdiveplotcluster" + metric + "" + (index).toString()

      // for the probes having root causes
      let metricsWithRootCauses = ["validity", "consistency", "accuracy", "completeness", "timeliness", "integrity"]
      // for the probes not having root causes
      let metricsWithoutRootCauses = ["time validity"]

      var plotDisplayCondition = true
      // use the display condition that best fits the type of metric we are dealing with
      if(metricsWithoutRootCauses.includes(metric))
        plotDisplayCondition = !cluster
      else if(metricsWithRootCauses.includes(metric))
        plotDisplayCondition = !cluster || !cluster.cluster_root_cause || !isValidRootCause(cluster.cluster_root_cause, metric)


      if (plotDisplayCondition)
        setHidePlot(prev => [...prev, cluster_name])
      else {
        var root = null
        try {
          maybeDisposeRoot(div_id);
          root = am5.Root.new(div_id);

          root.setThemes([
            am5themes_Animated.new(root)
          ]);

        } catch (e) {
          console.error("Cannot refresh the plot! " + div_id)
          console.error(e)
          return false
        }

        var plotData = null
        switch (metric) {
          case "validity":
            plotData = cluster.cluster_root_cause
            break;
          case "time validity":
            plotData = {
              anomalies: [],
              kde: []
            };
            // Converti il campo 'data' in 'kde'
            plotData.kde = Object.values(cluster.data)
            // Converti il campo 'indices' in 'anomalies'
            plotData.anomalies = cluster.indices.map(indexItem => {
              const matchingDataItem = plotData.kde.find(dataItem => new Date(dataItem.index).getTime() === new Date(indexItem.timestamp).getTime());
              if (matchingDataItem) {
                return {
                  index: indexItem.timestamp,
                  value: matchingDataItem.value
                };
              } else {
                return {
                  index: indexItem.timestamp,
                  value: null
                };
              }
            });
            break;
          case "consistency":
            plotData = cluster.cluster_root_cause
            break;
          case "accuracy":
            plotData = cluster.cluster_root_cause
            break;
          case "completeness":
            plotData = cluster.cluster_root_cause
            break;
          case "timeliness":
            plotData = cluster.cluster_root_cause
            break;
          case "integrity":
            plotData = cluster.cluster_root_cause
            break;
          default:
            let ix = parseInt(cluster[0])
            anomalies[getProbeName(cluster_name)][ix] !== null && anomalies[getProbeName(cluster_name)][ix].anomalies.map((an, ik) => {
              if (an !== null)
                plotData = an.root_causes
            })
            break;
        }

        if (plotData && plotData !== null)
          if(metric === "time validity")
            getNumericalTimeSeries(root, plotData["kde"], plotData["anomalies"])
          else
            if (isArrayOfDict(plotData))
              getCategorical(root, plotData, metric)
            else
              getNumericalSeries(root, plotData["kde"], plotData["anomalies"])



        return () => {
          root.dispose();
        };
      }
    })
  }


  const getRootCausePlot = (cluster_id, cluster, index) => {
    switch (cluster.probe.replace("_probe", "")) {

      // FORMATTING CASE
      case "formatting":
        return <div className='col-12 mt-2 bg-light rounded p-3 mb-2'>
          <div className="row">
            <div className='col-12 text-center'>
              Root cause KPIs
              <hr className='mt-1'></hr>
            </div>

          </div>
          <div className="row mt-1">
            {Object.keys(cluster.global_kpis).map((key, index) => {
              if (cluster.global_kpis[key] !== cluster.local_kpis[key])
                return <div className='col' key={key}>
                  <div className='row'>
                    <div className='col-12 text-center'>
                      <span className='text-lg red text-bold'>{cluster.local_kpis[key]}</span><span className='text-sm ms-2 green'>[AVG = {cluster.global_kpis[key]}]</span>
                    </div>
                    <div className='text-center'>{getFormattingDescription(key)}</div>
                  </div>
                </div>
            })}
            <div className='col'>
              <div className='row'>
                <div className='col-12 text-center'>
                  <span className='text-lg red text-bold'>{cluster.score}%</span>
                </div>
                <div className='text-center'>Average reconstruction error</div>
              </div>
            </div>
          </div>
        </div>

      case "column_outlier":
        return <div className='mt-2 col-12 cluster-plot'>
          {!hidePlot.includes(cluster_id) && <div id={"deepdiveplotcluster" + metric + "" + (index).toString()} className="p-0" style={{ width: "100%", height: "250px" }}></div>}
          {clusters === null && <div height={250} className="loading-cluster-plot justify-content-center align-items-center d-flex">
            Generating the root cause plot...
          </div>}
        </div>
        break;

       case "ts_univariate":
        return <div className='mt-2 col-12 cluster-plot'>
          {!hidePlot.includes(cluster_id) && <div id={"deepdiveplotcluster" + metric + "" + (index).toString()} className="p-0" style={{ width: "100%", height: "250px" }}></div>}
          {clusters === null && <div height={250} className="loading-cluster-plot justify-content-center align-items-center d-flex">
            Generating the root cause plot...
          </div>}
        </div>
        break;

      // DEFAULT CASE
      default:
        return <div className='mt-2 col-12 cluster-plot'>
          {!hidePlot.includes(cluster_id) && <div id={"deepdiveplotcluster" + metric + "" + (index).toString()} className="p-0" style={{ width: "100%", height: "250px" }}></div>}
          {clusters === null && <div height={250} className="loading-cluster-plot justify-content-center align-items-center d-flex">
            Generating the root cause plot...
          </div>}
        </div>
    }
  }

  return <BaseTheme title={tableID} activeItem="projects" to={projectID}>

    <div className="container-fluid">

      {/* Paging buttons series */}
      <div className='row'>
        <div className='col-12 justify-content-center d-flex'>
          {/* foreach item to maxPage */}
          {Array.from(Array(maxPages).keys()).map((i) => (i < 50) &&
            <button key={i} className={"paging-box " + (i === page ? "active" : "")} onClick={() => navigate("#" + (i).toString(), { replace: true })}>
              {i + 1}
            </button>
          )}
        </div>
      </div>


      <div className='row'>

        {currentClusters !== null && currentClusters.map(([cluster_id, cluster], index) => (cluster.hidden === undefined || cluster.hidden === false) && <div key={index + cluster_id} className={'mt-3 ' + (metric !== "accuracy" ? "col-12" : "col-6")}>
          <Block>
            <div className='col-12 p-4'>

              {/* Index of the current cluster */}
              <div className='row'>
                <div className='col-auto'>
                  <div className='pill-blue-dark'>
                    {cluster_id}
                  </div>
                </div>
              </div>

              <div className='row mt-3'>

                {/* Description of the cluster */}
                <div className={'col-12 mb-3'}>
                  <span className='fw-bold'>{getDescription(cluster)}</span>
                </div>

                {/* Amount of anomalies per cluster */}
                <div className={'col-md-auto me-3'}>
                  <MiniBlock icon={"bi bi-exclamation-triangle"} color={"red"} title="anomalies">{cluster.anomalies !== undefined ? cluster.anomalies : (cluster.indices !== undefined ? cluster.indices.length : cluster.length)}</MiniBlock>
                </div>

                {/* Amount of anomalies per cluster */}
                <div className={'col-md-auto'}>
                  <MiniBlock icon={"bi bi-eyedropper"} color={"red"} title="probe">{cluster.probe !== undefined ? cluster.probe.replace("_", " ").replace("probe", "") : metric}</MiniBlock>
                </div>

                {/* Root causes plot */}
                {cluster.probe !== undefined && getRootCausePlot(cluster_id, cluster, index)}

                {/* Show table button */}
                <div className='col-12'>{/*TODO check */}
                  <Button disabled={clusters === null} action={() => navigate("/projects/" + projectID + "/table/" + tableID + "/anomalies/" + jobID + "/clusters/" + metric + "/inspect/" + cluster_id)} className="mt-2" icon="bi bi-search">Inspect cluster</Button>
                </div>

              </div>

            </div>

          </Block>
        </div>)}

      </div>
    </div>

  </BaseTheme>
}