function convertToMilliseconds(time) {
    return parseInt(time / 1000000000000) === 0 ? time * 1000 : time
}

export function getDate(ts) {
    if (ts === undefined || ts === null || ts < 0 || ts >= 999999999999999)
        return "-"

    try {
        var date = new Date(convertToMilliseconds(ts))
        // var userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime()).toLocaleString("en-GB")
    } catch (e) {
        console.error(e)
        return "-"
    }
}

export function getElapsed(ts, tf) {
    if (ts === undefined || ts === null || ts >= 999999999999999)
        return "-"

    try {
        var date = new Date(convertToMilliseconds(ts));

        var now = new Date();
        var utc_timestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
            now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());

        var now = (tf > 0) ? convertToMilliseconds(tf) : utc_timestamp;
        var deltaS = (now - date) / 1000

        if (deltaS < 0)
            return "NOT VALID"

        else if (deltaS < 60)
            return parseInt(deltaS) + " sec"

        else if (deltaS < 3600)
            return parseInt(deltaS / 60) + " min " + parseInt(deltaS - parseInt(deltaS / 60) * 60) + " sec";

        else if (deltaS < (3600 * 24))
            return parseInt(deltaS / 3600) + " hrs " + parseInt((deltaS - parseInt(deltaS / 3600) * 3600) / 60) + " min";

        else
            return parseInt(deltaS / (3600 * 24)) + " d " + parseInt((deltaS - parseInt(deltaS / (3600 * 24)) * (3600 * 24)) / 3600) + " hrs " + parseInt((deltaS - parseInt(deltaS / 3600) * 3600) / 60) + " min";

    } catch (e) {
        console.error(e)
        return "-"
    }
}

export function getIcon(status) {
    if (status === "waiting" || status === "scheduled")
        return <i className="bi bi-clock"></i>
    else if (status === "completed")
        return <i className="bi bi-check-circle-fill"></i>
    else if (status === "failed")
        return <i className="bi bi-x-circle-fill"></i>
    else if (status === "running")
        return <i className="bi bi-hourglass-split"></i>
}

export const sortItems = (arr) => {
    let sorted = []

    Object.values(arr).sort((a, b) => a.timestamp > b.timestamp ? 1 : -1).map((it, index) => {
        if (Object.keys(it).includes("job_id"))
            sorted.push(it.job_id)
        else
            sorted.push(it.jobs[0].job_id)
    })

    return sorted
}

export const icons = {
    "google": "https://nuovasocieta.it/wp-content/uploads/2022/07/googlecloud.png",
    "azure": "https://riccardotreso.it/img/azure.jpeg",
    "aws": "https://pbs.twimg.com/profile_images/1473756532827246593/KRgw2UkV_400x400.jpg",
    "mongodb": "https://cdn.icon-icons.com/icons2/2415/PNG/512/mongodb_plain_wordmark_logo_icon_146423.png",
    "service-now": "https://companieslogo.com/img/orig/NOW-6d5a01eb.png?t=1647966832"
}