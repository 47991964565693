import './style.css'
import { Button } from '../../components/Button/Button'
import { Block } from '../../components/Block/Block'
import { useNavigate } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { useEffect } from 'react'
import { icons } from '../../utils/utils'

export function Sources() {

	const navigate = useNavigate();
	const { data, updateData, showPopup } = useData()

	/**
	 * Function to download connectors.
	 */
	function downloadDataSources() {
		updateData({
			request: "get_connectors",
			payload: {
				username: localStorage.getItem("adq-username")
			},
			onSuccess: () => { 
				
			 },
			onFail: () => {
				showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_connectors().")
			}
		})
	}

	/**
	 * Function to delete connectors.
	 */
	function deleteConnector(project_id) {
		updateData({
			request: "delete_connector",
			payload: {
				project_id: project_id,
			},
			onSuccess: () => {
				showPopup("success", "Success!", "The connector has been deleted successfully!")
			},
			onFail: () => {
				showPopup("error", "Failed!", "Communication error with the RelAI API server while performing delete_connector().")
			}
		})
	}

	function getTitle(item) {
		if (item.vendor === "google")
			if (item.type === "google-cloud-bigquery")
				return item.config.project_id + "/" + item.config.dataset_name
		if (item.vendor === "service-now")
			return item.config.server + "/" + item.config.dataset_name
		if (item.vendor === "azure")
			if (item.type === "azure-blob-storage"){
				var title = item.config.container_name + "/" + item.config.subfolder_path;
				if (title.length > 50){
					var len = title.length;
					title = title.substring(0, 25) + "..." + title.substring(len-20, len)
				}
				return title
			}
		return "ND"
	}

	return <BaseTheme title="Connections" refresh={downloadDataSources} activeItem="connections" nav={false} additionalButtons={[{
		"icon": "bi bi-plus-circle",
		"action": () => navigate("/connections/new"),
		"text": "Add new data source"
	}]}>
		<div className="container-fluid" id="connectors">

			{/* Summary */}
			<div className='row'>
				{data != null && Object.values(data.connectors).map((item, index) => <div key={index} className='col-md-6 col-12 mb-4'>
					<Block>
						<div className='col-12'>
							<div className='row'>
								<div className='col-8 col-md-10 col-xl-12'>

									{/* First info block */}
									<div className='row p-4 pe-1'>

										<div className="col-12">
											<div className='text-lg text-bold'>{getTitle(item)}</div>
										</div>

										<div className="col-12 col-sm-3 mt-3">
											<div className='text-md fw-light'>Technology Vendor</div>
											<div className='text-md text-bold'>{item.vendor}</div>
										</div>

										<div className="col-12 col-sm-3 mt-3">
											<div className='text-md fw-light'>Technology ID</div>
											<div className='text-md text-bold'>{item.type}</div>
										</div>

										<div className="col-12 col-sm-3 mt-3">
											<div className='text-md fw-light'>RelAI Project ID</div>
											<div className='text-md text-bold'>{item.project_id}</div>
										</div>

										<div className="col-12 col-sm-3 mt-3">
											<div className='text-md fw-light'>Status</div>
											<div className='text-md text-bold text-uppercase green'>ACTIVE</div>
										</div>
									</div>

								</div>

								<img className="vendor-icon" src={icons[item.vendor]} />

								{/* Action block */}
								<div className='col-12 m-0 px-4 py-3 mt-3 bg-light action-block d-flex flex-row justify-content-end'>
									<Button className="me-2" action={() => deleteConnector(item.project_id)} icon="bi bi-trash3">Delete</Button>
								</div>
							</div>
						</div>
					</Block>
				</div>
				)}
			</div>

		</div>
	</BaseTheme>
}