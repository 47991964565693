import './style.css'
import { Button } from '../../components/Button/Button'
import { Block } from '../../components/Block/Block'
import { useNavigate } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'

export function Projects() {

  const navigate = useNavigate();
  const { data, updateData, showPopup } = useData()

  function deleteProject(project_id) {

    updateData({
      request: "delete_project",
      payload: {
        project_id: project_id,
        project_owner: localStorage.getItem("adq-username")
      },
      onSuccess: () => {
        showPopup("success", "Success!", "The project has been deleted successfully.")
      },
      onFail: () => {
        showPopup("error", "Failed!", "Failed to delete the project from the database.")
      }
    })

  }

  const getColumns = (col_index) => {
    return Object.values(data.projects).map((item, index) => {
      let flag = data.connectors.filter(conn => conn.project_id === item.project_id).length > 0
      if ((index - col_index) % 3 === 0)
        return <div key={index} className='col-12 mb-4'>
          <Block>
            <div onClick={flag === true ? () => navigate("/projects/" + item.project_id) : () => {}} className={"col-12 p-4 " + (flag === true ? "on-hover on-hover-bg-blue" : "back-grey on-hover-denied")}>

              {/* Pill */}
              <div className='row'>
                <div className='col-auto'>
                  <div className='pill-blue-dark'>
                    PROJECT
                  </div>
                </div>
              </div>

              {/* First info block */}
              <div className='row mt-3'>

                {/* Title bar */}
                <div className="col-12">
                  <div className="project-title text-lg">{item.project_name}</div>
                </div>

                {/* Project owner */}
                <div className='col-12 text-sm'>
                  Owner: {item.project_owner}
                </div>

                {/* Description */}
                <div className='col-12 text-secondary text-md mt-3'>
                  {item.project_description}
                </div>

                {/* Project owner */}
                <div className={'col-12 mt-3 text-sm fw-bold ' + (flag === true ? "green" : "red")}>
                  Data source status: {flag === true ? "Available" : "Not reachable"}
                </div>

              </div>

            </div>

            {/* Action block */}
            {/* <div className='col-12 m-0 px-4 py-3 mt-3 bg-light action-block d-flex flex-row justify-content-end'>
                <Button className="me-2" icon="bi bi-trash3" action={() => deleteProject(item.project_id)}>Delete</Button>
                <Button disabled={data !== null && data.connectors.filter(conn => conn.project_id === item.project_id).length !== 1} icon="bi bi-door-open" action={() => navigate("/projects/" + item.project_id)}>Open project</Button>
              </div> */}
          </Block>
        </div>
    })
  }

  return <BaseTheme title="Projects" activeItem="projects" nav={false} additionalButtons={[{
    "icon": "bi bi-plus-circle",
    "action": () => navigate("/projects/new"),
    "text": "Add new project"
  }]}>

    <div className="container-fluid">
      {/* Summary */}
      <div className='row'>
        <div className='col-12 col-sm-6 col-lg-4'>
          {data != null && getColumns(0)}
        </div>
        <div className='col-12 col-sm-6 col-lg-4'>
          {data != null && getColumns(1)}
        </div>
        <div className='col-12 col-sm-6 col-lg-4'>
          {data != null && getColumns(2)}
        </div>
      </div>

    </div>
  </BaseTheme>
}