import axios from "axios";
import md5 from "md5";

// A mock function to mimic making an async request for data
export function fetchLogin(username, password, setAuthenticated) {

  axios.post(process.env.REACT_APP_API_SERVER + '/login', {
    "username": username,
    "password": md5(password)
  }).then(function (response) {
    // Set the local storage with the received user package
    localStorage.setItem("adq-token", response.data.token)
    localStorage.setItem("adq-username", response.data.username)
    localStorage.setItem("adq-role", response.data.role)
    localStorage.setItem("adq-pic", response.data.pic)

    // Trigger the local storage event
    window.dispatchEvent( new Event('storage') )

    // Set the flag for the redirect
    setAuthenticated(true)

  }).catch(function (error) {

    alert("Wrong credentials, please try again!")

    // Print out the exception
    console.error(error);

  })
}