import './style.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useData } from '../../components/DataProvider/DataProvider'
import { Button } from '../../components/Button/Button'
import { useEffect, useState } from 'react'
import { getDate, getElapsed, getIcon, sortItems } from '../../utils/utils'
import { SelectFromWhereBlock } from '../../components/SelectFromWhereBlock/SelectFromWhereBlock'
import { Popup } from '../../components/Popup/Popup'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

export function TableExecutions({ projectID, table, pipelines, tableNames = null }) {

  const { data, updateData, showPopup } = useData()
  const params = useParams()
  const navigate = useNavigate()

  let [phase, setPhase] = useState(null)
  let [select, setSelect] = useState(null)
  let [where, setWhere] = useState(null)
  let [probes, setProbes] = useState([])
  let [showAdditionalFields, setShowAdditionalFields] = useState(false)
  let [metadata, setMetadata] = useState(null)

  // Join check variables
  let [showJoinCheckAdditionalFields, setShowJoinCheckAdditionalFields] = useState(false)
  let [joinCheckTable, setjoinCheckTable] = useState("")
  let [joinCheckSelect, setJoinCheckSelect] = useState(null)
  let [joinCheckWhere, setJoinCheckWhere] = useState(null)
  let [joinCheckColumns, setJoinCheckColumns] = useState(null)

  // Deduplication variables
  let [deduplicationColumns, setDeduplicationColumns] = useState([])
  let [showDeduplicationAdditionalFields, setShowDeduplicationAdditionalFields] = useState(false)
  let [columnOptions, setColumnOptions] = useState([])


  useEffect(() => {
    /* Prepare metadata */
    let tableName = table.table
    if (data && data !== null && Object.keys(data).includes("metadata") && Object.keys(data.metadata).includes(projectID) && Object.keys(data.metadata[projectID]).includes(tableName))
      setMetadata(data.metadata[projectID][tableName])
  }, [data])

  useEffect(() => {
    if (metadata != null && metadata["columns"] != null) {
      let columns = metadata["columns"].filter(col => !col.excluded).map(col => col.col_name)
      setColumnOptions(columns)
    }
  }, [metadata])

  function setDeduplicationColumnsFromOptions(event, options) {
    setDeduplicationColumns(options)
  }


  function start_new_metadata() {
    updateData({
      request: "create_new_job",
      payload: {
        project_id: projectID,
        table: table.table,
        job_name: "metadata_learner",
        username: localStorage.getItem("adq-username"),
        stage: "training"
      },
      onSuccess: () => {
        showPopup("success", "Success!", "New metadata analysis started.")
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing create_new_job().")
      }
    })
  }

  function start_new_execution() {
    let request = "start_new_" + phase
    let tmpProbes = [...probes]

    if (tmpProbes.includes("join_check")) {
      sendStartNewRequest(request, ["join_check"], getProbeAdditionalFields("join_check"))
    }
    if (tmpProbes.includes("deduplication_probe")) {
      sendStartNewRequest(request, ["deduplication_probe"], getProbeAdditionalFields("deduplication_probe"))
    }
    tmpProbes = tmpProbes.filter(v => !["join_check", "deduplication_probe"].includes(v))
    if (tmpProbes.length === 0)
      return
    sendStartNewRequest(request, tmpProbes, {})
  }

  function getProbeAdditionalFields(probeName) {
    let additionalFields = {}
    if (probeName === "join_check") {
      additionalFields = {
        fuzzy_matching: {
          table_join: joinCheckTable,
          query_join: {
            select: joinCheckSelect,
            from: joinCheckTable,
            where: joinCheckWhere
          },
          join_check_columns: joinCheckColumns
        }
      }
    }
    if (probeName === "deduplication_probe") {
      additionalFields = {
        fuzzy_matching: {
          filtering_columns: deduplicationColumns,
        }
      }
    }
    return additionalFields
  }

  function sendStartNewRequest(request, reqProbes, additionalFields = {}) {
    if (additionalFields === null || additionalFields === undefined)
      additionalFields = {}
    updateData({
      request: request,
      payload: {
        project_id: projectID,
        table_id: table.table,
        probes: reqProbes,
        query: {
          select: select,
          from: table.table,
          where: where,
        },
        ...additionalFields
      },
      onSuccess: () => {
        showPopup("success", "Success!", "New " + phase + " started!")
        resetVariables()
      },
      onFail: () => {
        showPopup("error", "Failed!", "Something went wrong! Please retry!")
      }
    })
  }

  function resetVariables() {
    setPhase(null)
    setProbes([])
    setSelect(null)
    setWhere(null)

    setjoinCheckTable("")
    setJoinCheckSelect(null)
    setJoinCheckWhere(null)
    setJoinCheckColumns(null)

    setDeduplicationColumns([])
  }

  function getFontStyle(status) {

    if (status === "completed" || status === "running")
      return "green"
    else if (status === "failed")
      return "red"
    else if (status === "waiting")
      return "text-info"
    else if (status === "scheduled")
      return "text-info"

  }

  const update = (value) => {
    let tmp = [...probes]
    if (!probes.includes(value))
      tmp.push(value)
    else
      tmp = tmp.filter(v => v !== value)
    setProbes(tmp)
    console.log(tmp)
  }

  useEffect(() => {
    if (probes != null) {
      let showJoinCheck = probes.includes("join_check")
      let showDeduplication = probes.includes("deduplication_probe")
      setShowJoinCheckAdditionalFields(showJoinCheck)
      setShowDeduplicationAdditionalFields(showDeduplication)
      setShowAdditionalFields(showJoinCheck || showDeduplication)
    }
  }, [probes])

  const joinCheckAdditionalFields = () => {

    return <div className='row'>
      <div className='flex-col mt-3 text-lg'>
        Fill in the following fields with the information about the join table.
      </div>
      <div className='flex-col mt-4'>
        3. Select the table containing join data: <div className=" col-6">
          <select onChange={(e) => setjoinCheckTable(e.target.value)} disabled={tableNames === null} className={"form-select" + (joinCheckTable !== "" ? " is-valid" : "")}>
            <option value={""}>Select a table</option>
            {tableNames !== null && tableNames.map((t, i) => {
              return <option key={i} value={t}>{t}</option>
            })}
          </select>
          <div id="emailHelp" className="form-text">Select a table.</div>
        </div>
      </div>

      <SelectFromWhereBlock select={joinCheckSelect} from={joinCheckTable} where={joinCheckWhere} setSelect={setJoinCheckSelect} setWhere={setJoinCheckWhere} number={4} />


      <div className='flex-col mt-2'>
        <div className='w-100 text-sm mb-1 fw-bold'>Columns corresponding to the join ones</div>
        <input type="text" className="w-100 form-control" onChange={(e) => setJoinCheckColumns(e.target.value)} placeholder={"colA, colB, colC"} />
        <small id="emailHelp" className="form-text text-muted">Provide a list of columns, separated by commas. Enter "*" to select all columns.</small>
      </div>
    </div>
  }

  const joinCheckComplete = () => {
    return !probes.includes("join_check") ||
      (joinCheckTable !== null && joinCheckSelect !== null && joinCheckColumns !== null &&
        joinCheckTable.length !== 0 && joinCheckSelect.length !== 0 && joinCheckColumns.length !== 0)
  }

  function FormCheckBox({ value, label, onUpdateFunc, selectedChecks, id = null }) {
    if (id == null)
      id = value

    return <div className="col-3">
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value={value} id={id} onChange={(e) => onUpdateFunc(e.target.value)} checked={selectedChecks.includes(value)} />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  }

  const deduplicationComplete = () => {
    return !probes.includes("deduplication_probe") || (deduplicationColumns != null && deduplicationColumns.length > 0)
  }

  const deduplicationAdditionalFields = () => {

    return <>
      <div className='flex-col mt-3 text-lg'>
        Select a list of columns to find duplicate records.
      </div>
      <Autocomplete
        multiple
        loading={columnOptions.length === 0}
        id="deduplication-autocomplete"
        value={deduplicationColumns}
        onChange={setDeduplicationColumnsFromOptions}
        options={columnOptions}
        autoComplete
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Deduplication columns" placeholder="Columns"  margin="dense" size="small"/>
        )}
      />
    </>
  }

  const executionForm = () => {
    return <>
      <div className='col-12 text-xl fw-bold'>
        Start new {phase}
      </div>
      <div className="row">
        <div className="col">
          <SelectFromWhereBlock from={table.table} setSelect={setSelect} setWhere={setWhere} number={1} />

          <div className='col-12 mt-4 pt-2'>
            2. Select the probes to execute:
          </div>
          <div className='col-12 mt-2'>
            <div className="row">
              <FormCheckBox value={"row_outlier"} label={"Row Outlier"} id={"row_outlier"} onUpdateFunc={update} selectedChecks={probes} />
              <FormCheckBox value={"column_outlier"} label={"Column Outlier"} id={"column_outlier"} onUpdateFunc={update} selectedChecks={probes} />
              <FormCheckBox value={"null_fixing"} label={"Null Fixing"} id={"null_fixing"} onUpdateFunc={update} selectedChecks={probes} />
              <FormCheckBox value={"formatting_probe"} label={"Formatting"} id={"formatting_probe"} onUpdateFunc={update} selectedChecks={probes} />
              <FormCheckBox value={"ts_univariate_probe"} label={"Univariate Time Series Anomaly Detection"} id={"ts_univariate_probe"} onUpdateFunc={update} selectedChecks={probes} />
            </div>
            {phase === "inference" && <div className="row">
              <FormCheckBox value={"join_check"} label={"Join Check"} id={"join_check"} onUpdateFunc={update} selectedChecks={probes} />
              <FormCheckBox value={"deduplication_probe"} label={"Deduplication"} id={"deduplication_probe"} onUpdateFunc={update} selectedChecks={probes} />
            </div>}
          </div>
        </div>

        {showAdditionalFields && <div className='col'>
          {showJoinCheckAdditionalFields && joinCheckAdditionalFields()}
          {showDeduplicationAdditionalFields && deduplicationAdditionalFields()}
        </div>}
      </div>

    </>
  }


  return <div className="container-fluid">

    {phase !== null &&
      <Popup children={executionForm()}
        button={<Button disabled={probes.length === 0 || select === null || select.length === 0 || !joinCheckComplete() || !deduplicationComplete()} action={() => start_new_execution()} icon="bi bi-lightbulb">Start new {phase}</Button>}
        onPopupClose={resetVariables} />
    }

    <div className='row mt-4'>
      <div className='row'>
        <div className='col-auto'>
          <div className='pill-blue-dark'>
            Metadata analysis
          </div>
        </div>
      </div>
    </div>

    <div className='row'>
      <div className='col-12 mt-3'>
        <Button action={() => start_new_metadata()} icon="bi bi-bar-chart-line">Run metadata analysis</Button>
      </div>
    </div>

    <table className='execution-table table table-hover mt-3'>
      <thead className='back-blue'>
        <tr>
          <th>#</th>
          <th>Start time</th>
          <th>Finish time</th>
          <th>Duration</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {pipelines !== null && sortItems(pipelines.metadata_learner).map((value, index) => <tr className='on-hover' key={index} onClick={() => navigate("/projects/" + projectID + "/table/" + table.table + "/job/" + value)}>
          <td className='align-middle'>
            {pipelines.metadata_learner[value].job_id}
            {/* {index} */}
          </td>
          <td className='align-middle'>
            {getDate(pipelines.metadata_learner[value].timestamp)}
          </td>
          <td className='align-middle'>
            {getDate(pipelines.metadata_learner[value].complete)}
          </td>
          <td className='align-middle'>
            {getElapsed(pipelines.metadata_learner[value].timestamp, pipelines.metadata_learner[value].complete)}
          </td>
          <td className={'align-middle fw-bold ' + getFontStyle(pipelines.metadata_learner[value].status)}>
            {getIcon(pipelines.metadata_learner[value].status)} {pipelines.metadata_learner[value].status === "completed" ? 1 : 0}/1
          </td>
        </tr>)}
      </tbody>
    </table>

    <div className='row mt-4'>
      <div className='row'>
        <div className='col-auto'>
          <div className='pill-blue-dark'>
            Trainings
          </div>
        </div>
      </div>
    </div>

    <div className='row'>
      <div className='col-12 mt-3'>
        <Button action={() => setPhase("training")} icon="bi bi-lightbulb">Train RelAI probes</Button>
      </div>
    </div>

    <table className='table mt-3 table-hover'>
      <thead className='back-blue'>
        <tr>
          <th>#</th>
          <th>Start time</th>
          <th>Finish time</th>
          <th>Duration</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {pipelines !== null && sortItems(pipelines.training).map((value, index) => <tr className='on-hover' key={index} onClick={() => navigate("/projects/" + projectID + "/table/" + table.table + "/job/" + value)}>
          <td className='align-middle'>
            {value}
            {/* {index} */}
          </td>
          <td className='align-middle'>
            {getDate(pipelines.training[value].timestamp)}
          </td>
          <td className='align-middle'>
            {getDate(pipelines.training[value].complete)}
          </td>
          <td className='align-middle'>
            {getElapsed(pipelines.training[value].timestamp, pipelines.training[value].complete)}
          </td>
          <td className={'align-middle fw-bold ' + getFontStyle(pipelines.training[value].status)}>
            {getIcon(pipelines.training[value].status)} {pipelines.training[value].completed}/{pipelines.training[value].jobs.length}
          </td>
        </tr>)}
      </tbody>
    </table>

    <div className='row mt-4'>
      <div className='row'>
        <div className='col-auto'>
          <div className='pill-blue-dark'>
            DQ Evaluations
          </div>
        </div>
      </div>
    </div>

    <div className='row'>
      <div className='col-12 mt-3'>
        <Button action={() => setPhase("inference")} icon="bi bi-lightbulb">Run DQ evaluation</Button>
      </div>
    </div>

    <table className='table mt-3 table-hover'>
      <thead className='back-blue'>
        <tr>
          <th>#</th>
          <th>Start time</th>
          <th>Finish time</th>
          <th>Duration</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {pipelines !== null && sortItems(pipelines.inference).map((value, index) => <tr key={index} onClick={() => navigate("/projects/" + projectID + "/table/" + table.table + "/job/" + value)} className='on-hover'>
          <td className='align-middle'>
            {value}
          </td>
          <td className='align-middle'>
            {getDate(pipelines.inference[value].timestamp)}
          </td>
          <td className='align-middle'>
            {getDate(pipelines.inference[value].complete)}
          </td>
          <td className='align-middle'>
            {getElapsed(pipelines.inference[value].timestamp, pipelines.inference[value].complete)}
          </td>
          <td className={'align-middle fw-bold ' + getFontStyle(pipelines.inference[value].status)}>
            {getIcon(pipelines.inference[value].status)} {pipelines.inference[value].completed}/{pipelines.inference[value].jobs.length}
          </td>
        </tr>)}
      </tbody>
    </table>

  </div>
}