import { createContext, useContext, useEffect, useState } from "react"
import { useAuth } from "../AuthProvider/AuthProvider";
import { call, get_connectors, get_config, get_projects, get_license, get_consumes, get_users } from "./api";

// Initialize the context
const DataContext = createContext(null);

// Create Data context provider
export function DataContextProvider({ children }) {

    // Call useAuth to know if the user is authenticated
    let { isAuthenticated } = useAuth()

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [popup, setPopup] = useState(null)

    useEffect(() => {

        if (isAuthenticated === true && data === null && loading === true)
            download()

        if (data !== null)
            setLoading(false)

    }, [data, isAuthenticated, loading])

    function create_new_project(payload, onSuccess, onFail) {
        // Call upload API
        call("create_new_project", payload, (response) => {

            // Update local data withou recalling the server
            let tmp = { ...data }

            tmp['projects'].push(response)
            setData(tmp)

            // Execute the onSuccess function
            onSuccess()
        }, onFail)
    }

    function create_new_connector(payload, onSuccess, onFail) {
        // Call upload API
        call("create_new_connector", payload, (response) => {

            // Update local data withou recalling the server
            let tmp = { ...data }
            tmp['connectors'].push(payload)
            setData(tmp)

            // Execute the onSuccess function
            onSuccess()
        }, onFail)
    }

    function delete_connector(payload, onSuccess, onFail) {
        // Call upload API
        call("delete_connector", payload, (response) => {

            // Update local data withou recalling the server
            let tmp = { ...data }
            delete tmp['connectors'][payload['project_id']]
            setData(tmp)

            // Execute the onSuccess function
            onSuccess()
        }, onFail)
    }

    function delete_project(payload, onSuccess, onFail) {
        // Call upload API
        call("delete_project", payload, (response) => {

            // Update local data without recalling the server
            let tmp = { ...data }
            delete tmp['projects'][payload['project_id']]
            setData(tmp)

            // Execute the onSuccess function
            onSuccess()
        }, onFail)
    }

    function delete_job(payload, onSuccess, onFail) {
        // Call upload API
        call("delete_job", payload, (response) => {

            // Update local data without recalling the server
            let tmp = { ...data }
            delete tmp['jobs'][payload['project_id']][payload['table']][payload['job_id']]
            setData(tmp)

            // Execute the onSuccess function
            onSuccess()
        }, onFail)
    }

    function configure_internal_storage(payload, onSuccess, onFail) {
        // Call upload API
        call("configure_internal_storage", payload, (response) => {

            // Update local data withou recalling the server
            //let tmp = {...data}
            //tmp['projects'][payload['project_id']] = payload
            //setData(tmp)

            // Trigger an update
            // download()

            // Execute the onSuccess function
            onSuccess()
        }, onFail)
    }

    function link_table_to_project(payload, onSuccess, onFail) {
        // Call upload API
        call("link_table_to_project", payload, () => {

            // Update local data withou recalling the server
            let tmp = { ...data }
            tmp['projects'][payload['project_id']]['project_tables'].push(payload)
            setData(tmp)

            onSuccess()
        }, onFail)
    }

    function get_tables(payload, onSuccess, onFail) {
        // Call upload API
        call("get_tables", payload, (response) => {

            // Update local data withou recalling the server
            let tmp = { ...data }
            if (!Object.keys(tmp).includes('tables'))
                tmp['tables'] = {}

            tmp['tables'][payload['project_id']] = response
            setData(tmp)

            onSuccess()
        }, onFail)
    }

    async function download_connectors(payload, onSuccess, onFail) {
        let tmp = { ...data }
        tmp['connectors'] = await get_connectors(payload)
        setData(tmp)
    }

    function get_jobs(payload, onSuccess, onFail) {
        // Call upload API
        call("get_jobs", payload, (response) => {

            let project_id = payload['project_id']
            let table_id = payload['table']

            let tmp = { ...data }
            if (!Object.keys(tmp).includes('jobs'))
                tmp['jobs'] = {}

            if (!Object.keys(tmp.jobs).includes(project_id))
                tmp['jobs'][project_id] = {}

            if (!Object.keys(tmp.jobs[project_id]).includes(table_id))
                tmp['jobs'][project_id][table_id] = []

            tmp['jobs'][project_id][table_id] = response
            setData(tmp)

            onSuccess()
        }, onFail)
    }

    function create_new_job(payload, onSuccess, onFail) {
        call("create_new_job", payload, (response) => {

            let tmp = { ...data }
            let project_id = payload['project_id']
            let table_id = payload['table']

            if (!Object.keys(tmp).includes('jobs'))
                tmp['jobs'] = {}

            if (!Object.keys(tmp.jobs).includes(project_id))
                tmp['jobs'][project_id] = {}

            if (!Object.keys(tmp.jobs[project_id]).includes(table_id))
                tmp['jobs'][project_id][table_id] = []
            tmp['jobs'][project_id][table_id].push(response)
            setData(tmp)

            onSuccess()

        }, onFail)
    }

    function get_metadata(payload, onSuccess, onFail) {
        call("get_metadata", payload, (response) => {

            let tmp = { ...data }
            let project_id = payload['project_id']
            let table_id = payload['table_id']

            if (!Object.keys(tmp).includes('metadata'))
                tmp['metadata'] = {}

            if (!Object.keys(tmp.metadata).includes(project_id))
                tmp['metadata'][project_id] = {}

            if (!Object.keys(tmp.metadata[project_id]).includes(table_id))
                tmp['metadata'][project_id][table_id] = {}

            tmp.metadata[project_id][table_id] = response
            setData(tmp)

            onSuccess(response)

        }, onFail)
    }

    function start_inference(payload, onSuccess, onFail) {
        call("start_inference", payload, (response) => {

            onSuccess()

        }, onFail)
    }

    function start_new_training(payload, onSuccess, onFail) {
        call("start_new_training", payload, (response) => {

            onSuccess()

        }, onFail)
    }

    function start_new_inference(payload, onSuccess, onFail) {
        call("start_new_inference", payload, (response) => {

            onSuccess()

        }, onFail)
    }

    function get_anomalies(payload, onSuccess, onFail) {
        call("get_anomalies", payload, (response) => {

            let tmp = { ...data }

            if (!Object.keys(tmp.anomalies).includes(response.job_id))
                tmp['anomalies'][response.job_id] = {}

            // Add the anomalies bundle
            tmp['anomalies'][response.job_id] = response.output

            setData(tmp)

            onSuccess(response.output)

        }, onFail)
    }

    function get_clusters(payload, onSuccess, onFail) {
        call("get_clusters", payload, (response) => {

            let tmp = { ...data }

            if (!Object.keys(tmp.clusters).includes(response.project_id))
                tmp['clusters'][response.project_id] = {}

            if (!Object.keys(tmp.clusters[response.project_id]).includes(response.table))
                tmp['clusters'][response.project_id][response.table] = {}

            // Add the clusters bundle
            tmp['clusters'][response.project_id][response.table] = response

            setData(tmp)

            onSuccess()

        }, onFail)
    }

    function get_data(payload, onSuccess, onFail) {

        call("get_data", payload, (response) => {

            let tmp = { ...data }
            if (!Object.keys(tmp.data).includes(payload.project_id))
                tmp.data[payload.project_id] = {}

            let d = JSON.parse(response)
            tmp.data[payload.project_id][payload.table] = d

            setData(tmp)

            onSuccess(d)

        }, onFail)
    }

    function get_chunks(payload, onSuccess, onFail) {

        call("get_data", payload, (response) => {
            let d = JSON.parse(response)
            onSuccess(d)
        }, onFail)

    }

    function get_logs(payload, onSuccess, onFail) {

        call("get_logs", payload, (response) => {

            let tmp = { ...data }
            if (!Object.keys(tmp.logs).includes(payload.job_id)){
                tmp.logs[payload.job_id] = {}
            }   
            let job_logs = tmp.logs[payload.job_id]

            if(payload.min_timestamp != null && job_logs[payload.job_name]!= null){
                job_logs[payload.job_name].push(...response)
            }else{
                job_logs[payload.job_name] = response
            }

            setData(tmp)

            onSuccess()

        }, onFail)
    }

    function get_pipelines(payload, onSuccess, onFail) {

        call("get_pipelines", payload, (response) => {

            let tmp = { ...data }

            tmp.pipelines[response["project_id"]] = {}
            tmp.pipelines[response["project_id"]][response["table"]] = response

            setData(tmp)

            onSuccess()

        }, onFail)
    }

    function add_user(payload, onSuccess, onFail) {

        call("add_user", payload, (response) => {

            onSuccess()

        }, onFail)
    }

    function activate_license(payload, onSuccess, onFail) {

        call("activate_license", payload, (response) => {

            let tmp = { ...data }
            tmp.license = response

            setData(tmp)
            onSuccess()

        }, onFail)
    }

    function restart_job(payload, onSuccess, onFail) {

        call("restart_job", payload, (response) => {

            onSuccess()

        }, onFail)
    }

    function stop_job(payload, onSuccess, onFail) {

        call("stop_job", payload, (response) => {

            onSuccess()

        }, onFail)
    }

    function get_probe_feedback(payload, onSuccess, onFail) {

        call("get_probe_feedback", payload, (response) => {
            
            onSuccess(response)

        }, onFail)
    }
    
    function update_probe_feedback(payload, onSuccess, onFail) {

        call("update_probe_feedback", payload, (response) => {

            onSuccess()

        }, onFail)
    }

    function delete_user(payload, onSuccess, onFail) {

        call("delete_user", payload, (response) => {

            onSuccess()

        }, onFail)
    }

    function force_metadata(payload, onSuccess, onFail) {

        call("force_metadata", payload, (response) => {

            onSuccess(response)

        }, onFail)
    }

    async function updateData({ request, payload, onSuccess, onFail }) {

        // Dictionary with the list of function needed to complete the action
        let functions = {
            "create_new_project": create_new_project,
            "link_table_to_project": link_table_to_project,
            "create_new_connector": create_new_connector,
            "get_tables": get_tables,
            "get_jobs": get_jobs,
            "configure_internal_storage": configure_internal_storage,
            "create_new_job": create_new_job,
            "get_metadata": get_metadata,
            "start_new_training": start_new_training,
            "start_new_inference": start_new_inference,
            "start_inference": start_inference,
            "get_anomalies": get_anomalies,
            "get_clusters": get_clusters,
            "get_data": get_data,
            "delete_connector": delete_connector,
            "delete_project": delete_project,
            "delete_job": delete_job,
            "get_logs": get_logs,
            "get_connectors": download_connectors,
            "get_pipelines": get_pipelines,
            "activate_license": activate_license,
            "force_metadata": force_metadata,
            "restart_job": restart_job,
            "stop_job": stop_job,
            "get_chunks": get_chunks,
            "get_probe_feedback": get_probe_feedback,
            "update_probe_feedback": update_probe_feedback,
            "get_users": get_users,
            "add_user": add_user,
            "delete_user": delete_user
        }

        try {
            // Call the selected function passing structured parameters
            functions[request](payload, onSuccess, onFail)

        } catch (error) {
            console.log(error)
            // If the function has not been implemented yet return an error
            console.error("[LOCAL EXCEPTION] Function not yet implemented!")
            //setErrorMessage("Failed! Please contact the developer and provide the error code 0x01!")
        }

    }

    async function download() {
        let new_data = data === null ? {} : { ...data }

        // Download basic projects info data
        new_data['projects'] = await get_projects({
            username: localStorage.getItem("adq-username")
        })

        // Download connectors info
        new_data['connectors'] = await get_connectors({
            username: localStorage.getItem("adq-username")
        })

        // Download basic configuration info
        new_data['config'] = {
            "internal_storage": await get_config({
                "config_id": "config_internal_storage"
            }),
            "probes": await get_config({
                "config_id": "probes"
            })
        }

        // Get product consumes
        new_data['consumes'] = await get_consumes()

        // License-related data
        new_data['license'] = await get_license()

        // Initialize the object with the anomalies
        new_data['anomalies'] = {}

        // Initialize the object with the data
        new_data['data'] = {}

        // Initialize the object with the pipelines
        new_data['pipelines'] = {}

        // Initialize the object with the logs
        new_data['logs'] = {}

        // Initialize the object with clusters
        new_data['clusters'] = {}
        
        // Initialize metadata
        new_data['metadata'] = {}

        // Initialize users
        new_data['users'] = await get_users()

        console.log(new_data)

        setData(new_data)
    }

    function showPopup(status, title, text) {
        setPopup({
            "status": status,
            "title": title,
            "text": text
        })

        setTimeout(() => {
            setPopup(null)
        }, 5000)
    }

    return (
        <DataContext.Provider value={{ data, updateData, showPopup }}>
            <div className="container-fluid">

                {/* Popup */}
                {popup !== null && <div className={"overlay-message shadow bg-body " + popup.status} onClick={() => setPopup(null)}>
                    <div className="row p-4">
                        <div className="col-12 title fw-bold">
                            {popup.title}
                        </div>

                        <div className="col-12 text fw-light">
                            {popup.text}
                        </div>
                    </div>
                    <div className="round-time-bar" data-style="smooth">
                        <div></div>
                    </div>
                </div>}

                {/* Main content */}
                {children}
            </div>
        </DataContext.Provider>
    )

}

export function logout() {
    localStorage.removeItem("adq-token")
    localStorage.removeItem("adq-username")
    window.location.reload()
}

// Function to determine the auth state
export function useData() {
    const { data, updateData, showPopup } = useContext(DataContext);
    return { data: data, updateData: updateData, showPopup: showPopup };
}