import './style.css'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { useEffect, useState } from 'react'
import { Table } from '../../components/Table/Table'

export function AnomaliesTable() {

  const { data, updateData, showPopup } = useData()
  const params = useParams()

  // ID of the current project
  const projectID = params.id

  // ID of the current table
  const tableID = params.table_id

  // ID of the current jobs
  const jobID = params.job_id

  // Current project
  let [page, setPage] = useState(0)
  let [pageWidth, setPageWidth] = useState(100)
  let [currentData, setCurrentData] = useState(null)
  let [metadata, setMetadata] = useState(null)
  let [anomalies, setAnomalies] = useState(null)
  let [DQMap, setDQMap] = useState(false)
  let [navBlock, setNavBlock] = useState(null)

  const additionalButtons = [{
    "text": "DQ Map",
    "action": () => openMap(),
    "icon": "bi bi-arrow-clockwise"
  }]

  function pickHex(color1, color2, weight) {
    var w1 = weight;
    var w2 = 1 - w1;
    var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)];
    return rgb;
  }

  const green = [0, 255, 0];
  const white = [230, 230, 230];
  const red = [255, 0, 0]

  const openMap = () => {
    setDQMap(!DQMap)
  }

  useEffect(() => {

    // Download anomalies detected by current pipeline
    /* if (data !== null && Object.keys(data.anomalies).includes(jobID))
      setAnomalies(data.anomalies[jobID])
    else
      updateData({
        request: "get_anomalies",
        payload: {
          job_id: jobID
        },
        onSuccess: (res) => {
          setAnomalies(res)
        },
        onFail: () => {
          showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_anomalies().")
        }
      }) */

    // Download anomalies detected by current pipeline
    if (data !== null && Object.keys(data).includes("metadata") && Object.keys(data.metadata).includes(projectID) && Object.keys(data.metadata[projectID]).includes(tableID))
      setMetadata(data.metadata[projectID][tableID])
    else {
      updateData({
        request: "get_metadata",
        payload: {
          table_id: tableID,
          project_id: projectID
        },
        onSuccess: (res) => {
          setMetadata(res)
        },
        onFail: () => {
          showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_metadata().")
        }
      })
    }

    // Download anomalies detected by current pipeline
    if (data !== null && Object.keys(data.data).includes(projectID) && Object.keys(data.data[projectID]).includes(tableID))
      setCurrentData(data.data[projectID][tableID])
    else
      updateData({
        request: "get_data",
        payload: {
          project_id: projectID,
          table: tableID,
          job_id: jobID,
          page: 0,
          offset: 30
        },
        onSuccess: (res) => {
          setCurrentData(res)
        },
        onFail: () => {
          showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_data().")
        }
      })
  }, [])

  useEffect(() => {

    if (currentData !== null && currentData.length < 1000)
      setPageWidth(15)

  }, [currentData])

  useEffect(() => {

    if (currentData !== null && metadata !== null && anomalies !== null && pageWidth > 0) {

      var res = []
      Array(parseInt(currentData.length / pageWidth)).fill(null).map((f, p) => {
        let tot = 0

        if (anomalies.row_outlier !== undefined)
          anomalies.row_outlier.slice((p * pageWidth), ((p + 1) * pageWidth)).map((anomaly, j) => {
            tot += (anomaly !== null && anomaly.score > 0.5) ? anomaly.score : 0
          })

        if (anomalies.null_fixing !== undefined)
          anomalies.null_fixing.slice((p * pageWidth), ((p + 1) * pageWidth)).map((anomaly, j) => {
            tot += (anomaly !== null) ? 0.5 : 0
          })

        if (anomalies.column_outlier !== undefined)
          anomalies.column_outlier.slice((p * pageWidth), ((p + 1) * pageWidth)).map((anomaly, j) => {
            tot += (anomaly !== null) ? 0.5 : 0
          })

        res[p] = tot
      })

      let max = Math.max.apply(null, res)

      if (max > 0)
        res = res.map((item) => { return parseFloat(item / max).toFixed(2) })

      setNavBlock(res)
    }

  }, [currentData, pageWidth, anomalies])

  const setNewBlock = (newPage) => {

    // Set new page
    setPage(newPage)

    // Close the popup
    setDQMap(!DQMap)
  }

  const downloadAnomalies = () => {
    // Download anomalies detected by current pipeline
    updateData({
      request: "get_anomalies",
      payload: {
        job_id: jobID
      },
      onSuccess: (res) => {
        setAnomalies(res)
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_anomalies().")
      }
    })
  }

  return <BaseTheme title={tableID} refresh={() => downloadAnomalies()} activeItem="projects" to={projectID} additionalButtons={additionalButtons}>

    <div className="container-fluid">

      <div className='row'>
        {(DQMap === true && currentData !== null && navBlock !== null) && Array(parseInt(currentData.length / pageWidth)).fill(null).map((f, p) => <div key={p} onClick={() => setNewBlock(p)} style={{ background: `rgba(${pickHex(red, white, navBlock[p]).join(",")})` }} className='col d-flex align-items-center justify-content-center navigation-block'>
          {/* {(p) * pageWidth} - {(p + 1) * pageWidth - 1} */}
          {p}
        </div>)}
      </div>

      {/* Details table */}
      {(DQMap === false && currentData !== null && anomalies !== null) && <Table metadata={metadata} defaultPage={page} defaultPageWidth={pageWidth} data={currentData} anomalies={anomalies} />}

    </div>

  </BaseTheme>
}