import './style.css'
import Logo from '../../images/fox.png'
import UserPlaceholder from '../../images/user.png'
import { Link } from 'react-router-dom'
import { Button } from '../Button/Button'
import { useNavigate } from 'react-router-dom'
import { logout, useData } from '../DataProvider/DataProvider'

// List of menu items
export const menu_items = [
  {
    "id": "Home",
    "link": "/",
    "icon": "bi bi-house",
    "only_auth": false
  },
  {
    "id": "Projects",
    "link": "/projects",
    "icon": "bi bi-grid",
    "only_auth": true
  },
  {
    "id": "Connections",
    "link": "/connections",
    "icon": "bi bi-plug",
    "only_auth": true
  },
  {
    "id": "Settings",
    "link": "/settings",
    "icon": "bi bi-gear",
    "only_auth": true
  }
]

// Class of the bubble item
const menu_bubble_class = 'd-flex align-items-center justify-content-center h-100'

export function BaseTheme({ children, title, activeItem, nav = true, to = "", refresh = () => { }, additionalButtons = [] }) {

  // Current menu item
  const current_item = get_current_item()
  const navigate = useNavigate()
  const { data, updateData } = useData()

  // Current logged in user
  const username = localStorage.getItem("adq-username")

  // Current logged in user picture
  const pic = localStorage.getItem("adq-pic")

  // Iterate the menu items array looking for the current item
  function get_current_item() {

    if (activeItem !== null)
      for (let i = 0; i < menu_items.length; i++)
        if (menu_items[i].id.toLowerCase().includes(activeItem) || menu_items[i].id.toLowerCase() === activeItem)
          return menu_items[i]

    return null
  }

  return <div className="container-fluid m-0 p-0">

    {/* Menu bar */}
    <div className="px-4 menu-bar position-fixed d-flex flex-row justify-content-between">

      {/* Logo */}
      <div className='block-logo h-100'>
        <img alt="logo" id="logo" src={Logo} />
      </div>

      <div className="menu-items h-100 d-flex flex-row align-items-center">

        {/* Create menu items */}
        {menu_items.map((item, index) => (
          (item.only_auth === false || (item.only_auth === true && data !== null && data.license !== null)) && <div key={index} className='menu-item'>
            <Link to={item.link} className="h-100 w-100 text-center d-block">
              <div className={activeItem != null && item.id.toLowerCase().includes(activeItem) ? menu_bubble_class + " active" : menu_bubble_class}>
                <i className={item.icon}></i><span className='ms-3'>{item.id}</span>
              </div>
            </Link>
          </div>
        ))}

      </div>

      <div className='block-user d-flex flex-row align-items-center justify-content-center'>
        <Link to={"/profile/"}><img alt="user" src={UserPlaceholder} /></Link>
      </div>

    </div>
    {/* End menu bar */}

    {/* Main block */}
    <div className="main-block position-absolute p-4 container-fluid d-flex flex-column">

      <div className="row mb-3 page-header">
        <div className='col d-flex align-items-center'>

          {/* Control bar */}
          {nav === true && <div className='row'>
            <div className='col-auto'>
              <Button icon="bi-arrow-left-short bi" action={() => navigate(-1)}>Go back</Button>
            </div>
          </div>}

        </div>

        {/* Page title */}
        <div className='col-auto page-title text-center'>
          {title}
        </div>

        <div className='col d-flex justify-content-end align-items-center'>

          {/* Control bar */}
          <div className='row'>
            {additionalButtons !== null && additionalButtons.map((button, index) => <div key={index} className='col-auto'>
              <Button icon={button.icon} action={button.action}>{button.text}</Button>
            </div>)}
            <div className='col-auto'>
              <Button icon="bi bi-arrow-clockwise" action={() => refresh()}>Refresh</Button>
            </div>
          </div>

        </div>
      </div>

      <div className="row flex-grow-1">
        {/* Include children components */}
        {children}
      </div>

    </div>
    {/* End main block */}

  </div>
}