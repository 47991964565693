import './style.css'
import { useParams } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { Button } from '../../components/Button/Button'
import { Block } from '../../components/Block/Block'
import { useEffect, useRef, useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import { toBeInTheDOM } from '@testing-library/jest-dom/dist/matchers'

export function Metadata() {

  const { data, updateData, showPopup } = useData()
  const params = useParams()

  // ID of the current project
  const projectID = params.id

  // ID of the current table
  const tableID = params.table_id

  let [metadata, setMetadata] = useState(null)
  let [ready, setReady] = useState(false)
  let [itemIndex, setItemIndex] = useState(1)

  const not_plottable_types = ["URL_OR_IP", "CODICE_FISCALE", "PARTITA_IVA", "PARTITA_IVA_E_CODICE_FISCALE", "IBAN", "EMAIL_ADDRESS"]

  useEffect(() => {

    if (data !== null && Object.keys(data).includes("metadata") && Object.keys(data.metadata).includes(projectID) && Object.keys(data.metadata[projectID]).includes(tableID)){
      enrichMetadata(data.metadata[projectID][tableID])
      setMetadata(data.metadata[projectID][tableID])
    }
    else
      updateData({
        request: "get_metadata",
        payload: {
          table_id: tableID,
          project_id: projectID
        },
        onSuccess: () => {
          setReady(true)
        },
        onFail: () => {
          showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_metadata().")
        }
      })

  })

  useEffect(() => {

    if (data !== null && Object.keys(data).includes("metadata") && Object.keys(data.metadata).includes(projectID) && Object.keys(data.metadata[projectID]).includes(tableID)){
      enrichMetadata(data.metadata[projectID][tableID])
      setMetadata(data.metadata[projectID][tableID])
    }

  }, [ready, data])

  useEffect(() => {
    generatePlot()
  }, [itemIndex, ready, metadata])

  useEffect(() => {
    generateGeneralPlot()
  }, [metadata])

  const setItem = (i) => {
    setItemIndex(i)
  }

  const enrichMetadata = (metadata) => {
    metadata["columns"].forEach((col) => {
      let den = col.col_len - col.col_nan.count
      if(col.col_aggregation_for_plot == null) return
      if( den > 0 ){
        col.col_aggregation_for_plot.forEach((v) =>{
          v["percentage_count"] = v.value / den * 100
        })
      }else{
        console.warn("empty column")
        col.col_aggregation_for_plot.forEach((v) =>{
          v["percentage_count"] = 0
        })
      }
    }
    )
  }

  function getTimeSeries(root, columnName, plotData) {

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        panX: false,
        layout: root.verticalLayout
      })
    );

    // Craete Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series with breaks",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "count",
        valueXField: columnName,
        openValueYField: "base"
      })
    );
    series.strokes.template.setAll({
      strokeWidth: 3
    });
    series.fills.template.setAll({
      fillOpacity: 0.5,
      visible: true
    });
    series.data.setAll(plotData);

  }

  function getCategorical(root, plotData) {

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        panX: true,
        wheelX: "zoomX", 
        layout: root.verticalLayout
      })
    );

    // Craete Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({
      rotation: 0,
      centerY: am5.p50,
      centerX: am5.p50,
      paddingRight: 0,
      paddingTop: 10,
      oversizedBehavior: "wrap",
      maxWidth: 100,
      tooltipText: "{category}",
    });


    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "index",
        renderer: xRenderer
      })
    );

    xAxis.labelsContainer.set("tooltip", am5.Tooltip.new(root, {
      pointerOrientation: "down"
    }));

    xRenderer.labels.template.setup = function(target) {
      target.set("background", am5.Rectangle.new(root, {
        fill: am5.color(0x000000), 
        fillOpacity: 0
      }));
    }; 

    xAxis.data.setAll(plotData);

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series with breaks",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "index",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "vertical"
        })
      })
    );

    series.columns.template.setAll({
      fillOpacity: 0.5,
      strokeWidth: 3,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      width: am5.percent(90),
      tooltipText: "{categoryX}\ncount: {percentage_count.formatNumber('#.00')}%",
    });

    series.data.setAll(plotData);

  }

  function getNumericalSeries(root, plotData) {

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        panX: false,
        layout: root.verticalLayout
      })
    );

    // Craete Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        /* baseInterval: { timeUnit: "day", count: 1 }, */
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series with breaks",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "index"
      })
    );
    series.strokes.template.setAll({
      strokeWidth: 3
    });
    series.fills.template.setAll({
      fillOpacity: 0.5,
      visible: true
    });
    
    series.data.setAll(plotData);

  }

  function getTypesFrequenciesBarChart(root, plotData) {

    // Convert data to the right array format
    var data = Object.keys(plotData)
    .map(function(key) {
      return {
        name: key,
        count: plotData[key]
      };
    })
    .sort(function(a, b) {
      return b.count - a.count; // Ordina in ordine decrescente
    });;

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout,
      background: am5.Rectangle.new(root, {
      fill: am5.color(0xffffff)
      }),
      height: am5.percent(100)
    }));

    // Add legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }))

   let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "name",
      renderer: am5xy.AxisRendererY.new(root, {
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
      })
    }));

    let yRenderer = yAxis.get("renderer");
    // hide horizontal grid lines
    yRenderer.grid.template.set("forceHidden", true);

    // setup for the tooltip text
    yAxis.labelsContainer.set("tooltip", am5.Tooltip.new(root, {
      pointerOrientation: "down"
    }));
    yRenderer.labels.template.setup = function(target) {
      target.set("background", am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 0
      }));
    };

    yAxis.data.setAll(data);
    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0
      }),
      maxPrecision: 0,
      min: 0
    }));
    let xRenderer = xAxis.get("renderer");
    // hide vertical grid lines
    // xRenderer.grid.template.set("forceHidden", true);
    // hide the x axis
    // xRenderer.labels.template.set('visible', false);

    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        //name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "count",
        categoryYField: "name",
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal"
        })
    }));
    series.columns.template.setAll({
      fillOpacity: 0.5,
      strokeWidth: 3,
      cornerRadiusBR: 5,
      cornerRadiusTR: 5,
      width: am5.percent(90),
      tooltipText: "{categoryY}\ncount: {valueX}",
    });

    series.columns.template.setAll({
        height: am5.p100,
        //strokeOpacity: 0
    });

  series.bullets.push(function() {
    return am5.Bullet.new(root, {
      locationX: 1,
      locationY: 0.5,
      sprite: am5.Label.new(root, {
        centerY: am5.p50,
        populateText: true
      })
    });
  });

  series.data.setAll(data);
}


  function getNumericalDateSeries(root, plotData) {

    var newData = []
    plotData.map((d, index) => {
      newData.push({
        index: new Date(d.index).getTime(),
        value: d.value
      })
    })

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        panX: false,
        layout: root.verticalLayout
      })
    );

    // Craete Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series with breaks",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "index"
      })
    );
    series.strokes.template.setAll({
      strokeWidth: 3
    });
    series.fills.template.setAll({
      fillOpacity: 0.5,
      visible: true
    });
    series.data.setAll(newData);

  }

  function getMap(root, plotData) {

    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        projection: am5map.geoMercator()
      })
    );

    var backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
    backgroundSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get("alternativeBackground"),
      fillOpacity: 0,
      strokeOpacity: 0
    });

    backgroundSeries.data.push({
      geometry: am5map.getGeoRectangle(90, 180, -90, -180)
    });

    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get("alternativeBackground"),
      fillOpacity: 0.15,
      strokeWidth: 0.5,
      stroke: root.interfaceColors.get("background")
    });

    let circleTemplate = am5.Template.new({
      tooltipText: "{index}: {value}"
    });

    let bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        calculateAggregates: true,
        valueField: "value"
      })
    );

    bubbleSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 5,
          templateField: "circleTemplate"
        }, circleTemplate)
      });
    });

    bubbleSeries.set("heatRules", [{
      target: circleTemplate,
      min: 5,
      max: 30,
      key: "radius",
      dataField: "value"
    }]);

    let colors = am5.ColorSet.new(root, {});

    for (var i = 0; i < plotData.length; i++) {
      var city = plotData[i];
      addCity(city.longitude, city.latitude, city.index, city.value);
    }

    function addCity(longitude, latitude, name, count) {
      bubbleSeries.data.push({
        geometry: { type: "Point", coordinates: [longitude, latitude] },
        name: name,
        value: count,
        title: name,
        index: name,
        circleTemplate: { fill: colors.getIndex(0) }
      });
    }

    polygonSeries.events.on("datavalidated", function () {
      chart.zoomToGeoPoint({ latitude: 50.9597, longitude: 10.3901 }, 10);
    })

    // Make stuff animate on load
    chart.appear(1000, 100);
  }

  function getMapForCountry(root, plotData) {

    /* Chart code */
    let data = [
      {
        id: "US",
        name: "United States",
        value: 100
      }, {
        id: "GB",
        name: "United Kingdom",
        value: 100
      }, {
        id: "CN",
        name: "China",
        value: 100
      }, {
        id: "IN",
        name: "India",
        value: 100
      }, {
        id: "AU",
        name: "Australia",
        value: 100
      }, {
        id: "CA",
        name: "Canada",
        value: 100
      }, {
        id: "BR",
        name: "Brasil",
        value: 100
      }, {
        id: "ZA",
        name: "South Africa",
        value: 100
      }
    ];

    let chart = root.container.children.push(am5map.MapChart.new(root, {}));

    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ["AQ"]
      })
    );

    let bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        valueField: "value",
        calculateAggregates: true,
        polygonIdField: "id"
      })
    );

    let circleTemplate = am5.Template.new({});

    bubbleSeries.bullets.push(function (root, series, dataItem) {
      let container = am5.Container.new(root, {});

      let circle = container.children.push(
        am5.Circle.new(root, {
          radius: 20,
          fillOpacity: 0.7,
          fill: am5.color(0xff0000),
          cursorOverStyle: "pointer",
          tooltipText: `{name}: [bold]{value}[/]`
        }, circleTemplate)
      );

      let countryLabel = container.children.push(
        am5.Label.new(root, {
          text: "{name}",
          paddingLeft: 5,
          populateText: true,
          fontWeight: "bold",
          fontSize: 13,
          centerY: am5.p50
        })
      );

      circle.on("radius", function (radius) {
        countryLabel.set("x", radius);
      })

      return am5.Bullet.new(root, {
        sprite: container,
        dynamic: true
      });
    });

    bubbleSeries.bullets.push(function (root, series, dataItem) {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: "{value.formatNumber('#.')}",
          fill: am5.color(0xffffff),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: "center"
        }),
        dynamic: true
      });
    });


    // minValue and maxValue must be set for the animations to work
    bubbleSeries.set("heatRules", [
      {
        target: circleTemplate,
        dataField: "value",
        min: 15,
        max: 15,
        minValue: 0,
        maxValue: 10000,
        key: "radius"
      }
    ]);

    bubbleSeries.data.setAll(plotData);

    /* updateData();
    setInterval(function () {
      updateData();
    }, 2000)

    function updateData() {
      for (var i = 0; i < bubbleSeries.dataItems.length; i++) {
        bubbleSeries.data.setIndex(i, { value: Math.round(Math.random() * 100), id: data[i].id, name: data[i].name })
      }
    } */
  }

  function maybeDisposeRoot(divId) {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root && root.dom.id == divId) {
        root.dispose();
      }
    });
  };

  function generateGeneralPlot() {
      if (metadata !== null && metadata.col_type_counts !== undefined) {
        try {
          maybeDisposeRoot("chartdivgeneralinfo");
          var root = am5.Root.new("chartdivgeneralinfo");
          root.setThemes(
            [am5themes_Animated.new(root)]
          );
          getTypesFrequenciesBarChart(root, metadata.col_type_counts)
          return () => root.dispose();
        } catch (e) {
          console.error("Cannot refresh the general plot! ", e)
          return false
        }
      }
    }

  function generatePlot() {
    if (metadata !== null && metadata.columns !== undefined && itemIndex !== null) {
      var item = metadata.columns[itemIndex-1]

      if (item.col_aggregation_for_plot !== null && item.col_aggregation_for_plot !== undefined) {

        if (item.col_subtype !== "KEY" && item.col_subtype !== "TEXT" && !not_plottable_types.includes(item.col_subtype)) {

          var root = null
          try {

            maybeDisposeRoot("chartdiv" + itemIndex);
            root = am5.Root.new("chartdiv" + itemIndex);

            root.setThemes([
              am5themes_Animated.new(root)
            ]);

          } catch (e) {
            console.error("Cannot refresh the plot!")
            return false
          }


          if (item.col_subtype === "NUMERICAL")
            getNumericalSeries(root, item.col_aggregation_for_plot)
          else if (item.col_subtype === "DATE")
            getNumericalDateSeries(root, item.col_aggregation_for_plot)
          else if (item.col_subtype === "CATEGORICAL" || item.col_subtype === "BINARY")
            getCategorical(root, item.col_aggregation_for_plot)
          else if (item.col_subtype === "TIMESTAMP")
            getNumericalDateSeries(root, item.col_aggregation_for_plot)
          else if (item.col_subtype === "IP_ADDRESS" || item.col_subtype === "IP_PORT")
            getCategorical(root, item.col_aggregation_for_plot)
          else if (item.col_subtype === "CODICE_ATECO")
            getCategorical(root, item.col_aggregation_for_plot)
          else if (item.col_subtype === "CITY")
            getMap(root, item.col_aggregation_for_plot)
          else if (item.col_subtype === "COUNTRY")
            getMapForCountry(root, item.col_aggregation_for_plot)

          return () => root.dispose();
        } else {
          console.error("Missing plot data for " + item.col_name)
        }
      } else {
        console.error("Missing plot data for " + item.col_name)
      }
    }
  }

  function convertNumber(value) {
    if (value < 1000)
      return value

    if (value < 1000000)
      return parseInt(value / 1000).toFixed(1) + "k"

    return (parseInt(value / 1000000) + parseInt((value % 1000000) / 100000)/10) + "M"
  }

  return <BaseTheme title={tableID} activeItem="projects">
    {metadata !== null && <div className="container-fluid d-flex flex-column">

       <Block>
          <div className='row'>
            <span className='w-100 text-md fw-bold text-center mt-2 text-break'>Dataset information</span>
          </div>
          {/* General information */}
          <div className='column px-5 mt-3 my-3 mx-auto' style={{ display: 'flex', justifyContent: 'space-between',  maxWidth: '60%' }}>
            <div className='column text-center'>
              <div>
                <div className='text-lg fw' style={{ fontSize: '110%' }}>Dataset Rows</div>
              </div>
              <span className='text-lg fw-bold' style={{ fontSize: '160%' }}>{convertNumber(metadata.dataset_rows)}</span>
            </div>
            <div className='column text-center'>
              <div>
                <div className='text-lg fw' style={{ fontSize: '110%' }}>Dataset Columns</div>
              </div>
              <span className='text-lg fw-bold' style={{ fontSize: '160%' }}>{convertNumber(metadata.dataset_columns)}</span>
            </div>
          </div>

          {/* Frequencies of column types: the div has height=max(165, num_col_types*50) */}
          {metadata && metadata.col_type_counts && (
              <div className='column mt-3 mx-auto' style={{ textAlign: 'center', maxWidth: '60%', minHeight: "165px" }}>
                <div className='text-md fw-bold mb-2'>Frequencies of column types</div>
                <div className='row mt-3 flex-grow-1 bg-light cluster-plot'>
                  <div id="chartdivgeneralinfo" className="col-12 h-100" style={{ width: "100%", minHeight:Object.keys(metadata.col_type_counts).length*50>165?Object.keys(metadata.col_type_counts).length*50:165+"px", padding: "0"}}></div>
                </div>
              </div>
            )}
        </Block>



      <div className='row flex-row flex-nowrap metadata-carousel'>
        {(metadata !== null && metadata.columns !== undefined) && metadata.columns.map((item, i) => {
          if (item.col_name !== "index")
            return <div key={i} onClick={() => setItem(i+1)} className="col-auto mt-3 me-3 px-0">
              <Block>
                <div className='row'>
                  <div className={`${(item.excluded !== undefined && item.excluded === true) ? 'back-grey' : (i+1 === itemIndex ? 'back-blue' : '')} col-12 px-4 p-3 metadata-on-hover`}>

                    <div className='w-100 text-md fw-bold text-center'>
                      <span className={`pill-${item.col_type == 'STRING' ? 'orange' : 'blue'} ${item.col_type == 'STRING' ? 'orange' : 'blue'} px-2 py-1 rounded`}>{i+1} / {metadata.columns.length}</span>
                    </div>

                    <div className='w-100 text-md fw-bold text-center mt-2 text-break'>
                      {item.col_name}
                    </div>

                    <div className={`w-100 mb-2 text-break text-center ${item.col_type == 'STRING' ? 'orange' : 'blue'}`}>
                      <span className='text-sm me-1'>{item.col_subtype}</span>
                      <span className='text-sm'>({item.col_type.toLowerCase()})</span>
                    </div>

                    <div className='row d-flex justify-content-center'>
                      <div className='col-auto'>
                        <div className='text-center rounded border d-flex flex-column py-2 px-3'>
                          <span className='text-sm fw-bold'>
                              {item.col_uniques ? convertNumber(item.col_uniques) : convertNumber(item.col_duplicates)}
                          </span>
                          <span className='text-sm'>{item.col_uniques ? 'Unique values' : 'Duplicates'}</span>
                        </div>
                      </div>

                      <div className='col-auto'>
                        <div className='text-center rounded border d-flex flex-column py-2 px-3'>
                          <span className='text-sm fw-bold'>{convertNumber(item.col_nan.count)}</span>
                          <span className='text-sm'>Missing values</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </Block>
            </div>
        }
        )}
      </div>

      {/* Graphs */}
      {(itemIndex >= 1 && metadata.columns[itemIndex-1].col_aggregation_for_plot != null && metadata.columns[itemIndex-1].col_aggregation_for_plot.length!==0) &&<div className='row mt-3 flex-grow-1 bg-light cluster-plot'>
        <div id={"chartdiv" + itemIndex} className="col-12 h-100" style={{ width: "100%", minHeight: "300px" }}></div>
      </div>}

      {/* <div ref={actionRef} className='col-12 pt-4 action-block d-flex flex-row justify-content-end'>
        <Button action={() => {
          updateMetadata(metadata.columns[itemIndex].col_name, (metadata.columns[itemIndex].excluded !== undefined ? !metadata.columns[itemIndex].excluded : true))
        }} className='ms-3' icon="bi bi-file-earmark-x">{metadata.columns[itemIndex].excluded !== undefined && metadata.columns[itemIndex].excluded === true ? "Include" : "Exclude"} variable</Button>
        <Button action={() => openPopup(metadata.columns[itemIndex].col_name)} className='ms-3' icon="bi bi-pencil">Change variable type</Button>
      </div> */}

    </div>
    }
  </BaseTheme >
}