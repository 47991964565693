import './style.css'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { Button } from '../../components/Button/Button'
import { useEffect, useState } from 'react'
import Checked from '../../images/checked.png'
import Waiting from '../../images/waiting.png'
import Refresh from '../../images/refresh.png'
import Cancel from '../../images/cancel.png'
import { getDate, getElapsed, getIcon } from '../../utils/utils'

export function ExecutionDetails() {

  const { data, updateData, showPopup } = useData()
  const params = useParams()
  const navigate = useNavigate()

  // ID of the current project
  const projectID = params.id

  // ID of the current table
  const tableID = params.table_id

  // ID of the current job
  const jobID = params.job_id

  // Current project
  let [table, setTable] = useState(null)
  let [ready, setReady] = useState(false)
  let [pipelines, setPipelines] = useState(null)

  useEffect(() => {

    if (data !== null && Object.keys(data).includes("pipelines")
      && Object.keys(data.pipelines).includes(params.id)
      && Object.keys(data.pipelines[params.id]).includes(tableID))
      setReady(true)

    let intervalID = setInterval(downloadData, 1000)

    return (() => {
      clearInterval(intervalID)
    })
  }, [])

  function downloadData() {
    updateData({
      request: "get_pipelines",
      payload: {
        project_id: params.id,
        table: params.table_id
      },
      onSuccess: () => {
        setReady(true)
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_pipelines().")
      }
    })
  }

  // Find the project
  useEffect(() => {

    // If the project is still null and data has been already downloaded
    // find the project and assign it to React state.
    if (table === null && data !== null && Object.keys(data).includes("pipelines") && Object.keys(data.pipelines).includes(projectID))
      data.tables[projectID].map((t, i) => {
        if (t.table === tableID)
          setTable(t)
      })

  }, [data, projectID, tableID, table])

  useEffect(() => {
    if (data && data !== null && Object.keys(data).includes("pipelines") && ready === true)
      prepareData()
  }, [data, ready])

  function prepareData() {

    if (Object.keys(data.pipelines).includes(projectID) && Object.keys(data.pipelines[projectID]).includes(tableID)) {
      let pipeline = data.pipelines[projectID][tableID]

      // Set the final dict
      if (Object.keys(pipeline).includes("jobs"))
        setPipelines(pipeline.jobs)
    }
  }

  function deletePipeline(job_id, index) {
    updateData({
      request: "delete_job",
      payload: {
        job_id: job_id,
        table: tableID,
        project_id: projectID,
        index: index
      },
      onSuccess: () => {
        alert("Pipeline removed!")
        prepareData()
      },
      onFail: () => {
        alert("Something went wrong! Please retry!")
      }
    })
  }

  function deleteJob(job_id) {
    let request = "delete_job"
    updateData({
      request: request,
      payload: {
        project_id: projectID,
        job_id: job_id
      },
      onSuccess: () => {
        showPopup("success", "Success!", "Delete job " + job_id + "!")
      },
      onFail: () => {
        showPopup("error", "Failed!", "Something went wrong! Please retry!")
      }
    })
  }

  function restartJob(project_id, table_id, job_id, probe_name, stage) {
    updateData({
      request: "restart_job",
      payload: {
        job_id: job_id,
        table: table_id,
        project_id: project_id,
        job_name: probe_name,
        stage: stage
      },
      onSuccess: () => {
        showPopup("success", "Success!", "Job restarted!")
        prepareData()
      },
      onFail: () => {
        showPopup("error", "Failed!", "Something went wrong! Please retry!")
      }
    })
  }

  function stopJob(project_id, table_id, job_id, probe_name, stage) {
    updateData({
      request: "stop_job",
      payload: {
        job_id: job_id,
        table: table_id,
        project_id: project_id,
        job_name: probe_name,
        stage: stage
      },
      onSuccess: () => {
        showPopup("success", "Success!", "Job killed!")
        prepareData()
      },
      onFail: () => {
        showPopup("error", "Failed!", "Something went wrong! Please retry!")
      }
    })
  }

  function getFontStyle(status) {

    if (status === "completed" || status === "running")
      return "green"
    else if (status === "failed")
      return "red"
    else if (status === "waiting")
      return "text-info"
    else if (status === "scheduled")
      return "text-info"

  }

  const getQuery = (phase) => {
    let query = pipelines[phase][jobID].jobs[0].query

    if (query === undefined || query === null)
      return <div className='col-12'>No query has been provided. The execution has been perfomed on the whole dataset.</div>

    let select = query.select === null ? "*" : query.select
    let where = query.where === null ? null : query.where.join(" & ")

    return <><div className='col-12'>{"SELECT " + select}</div><div className='col-12'>{"FROM " + query.from}</div>{where !== null && <div className='col-12'>{"WHERE " + where}</div>}</>
  }

  const convertProbeName = (probe_name) => {
    // Replace underscores with spaces
    probe_name = probe_name.replace(/_/g, " ")

    // Capitalize first letter of each word
    probe_name = probe_name.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })

    // Remove "probe" from the end of the string
    probe_name = probe_name.replace(/Probe$/, "")

    return probe_name
  }

  return <BaseTheme title={"Job - " + jobID} activeItem="projects" additionalButtons={[{
    "text": "Delete",
    "icon": "bi bi-trash3",
    "action": () => {
      deleteJob(jobID)
      navigate(-1)
    }
  }]}>

    <div className="container-fluid">

      {(pipelines !== null && pipelines.metadata_learner[jobID] !== undefined) && <><div className='row mt-5'>
        <div className='col-12 text-xl fw-bold'>
          Metadata analysis
        </div>
      </div>

        <table className='table mt-4 mb-5'>
          <thead className='back-blue'>
            <tr>
              <th scope="col" className="col-2">Probe name</th>
              <th scope="col" className="col-2">Start time</th>
              <th scope="col" className="col-2">Finish time</th>
              <th scope="col" className="col-2">Duration</th>
              <th scope="col" className="col-2">Status</th>
              <th scope="col" className="col-2">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='align-middle'>
                {convertProbeName("metadata_learner")}
              </td>
              <td className='align-middle'>
                {getDate(pipelines.metadata_learner[jobID].timestamp)}
              </td>
              <td className='align-middle'>
                {getDate(pipelines.metadata_learner[jobID].complete)}
              </td>
              <td className='align-middle'>
                {getElapsed(pipelines.metadata_learner[jobID].timestamp, pipelines.metadata_learner[jobID].complete)}
              </td>
              <td className={'align-middle fw-bold ' + getFontStyle(pipelines.metadata_learner[jobID].status)}>
                {getIcon(pipelines.metadata_learner[jobID].status)} {pipelines.metadata_learner[jobID].status.toUpperCase()}
              </td>
              <td className='align-middle'>
                <Button action={() => navigate("/projects/" + projectID + "/table/" + tableID + "/job/" + jobID + "/logs/" + pipelines.metadata_learner[jobID].job_name)}>Logs</Button>
                <Button className='ms-2' action={() => restartJob(projectID, tableID, jobID, pipelines.metadata_learner[jobID].job_name, "training")}>Restart</Button>
                <Button className='ms-2' action={() => stopJob(projectID, tableID, jobID, pipelines.metadata_learner[jobID].job_name, "training")}>Stop</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </>}

      {pipelines !== null && pipelines.training[jobID] !== undefined && <><div className='row'>
        <div className='col-12 text-xl fw-bold'>
          Training executions
        </div>
      </div>

        <div className='row mt-4'>
          <div className='col-12 text-sm fw-bold'>
            PROVIDED QUERY
          </div>
          <div className='col-12 text-sm'>
            The query is used to filter the table obtaining the input dataset of executing probes.
          </div>
          <div className='col-12 mt-2'>
            <div className='w-100 bg-dark text-white px-4 py-2'>
              {getQuery("training")}
            </div>
          </div>
        </div>

        <table className='table mt-4 mb-5'>
          <thead className='back-blue'>
            <tr>
              <th scope="col" className="col-2">Probe name</th>
              <th scope="col" className="col-2">Start time</th>
              <th scope="col" className="col-2">Finish time</th>
              <th scope="col" className="col-2">Duration</th>
              <th scope="col" className="col-2">Status</th>
              <th scope="col" className="col-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {pipelines.training[jobID].jobs.map((value, index) => <tr key={index}>
              <td className='align-middle'>
                {convertProbeName(value.job_name)}
              </td>
              <td className='align-middle'>
                {getDate(value.timestamp)}
              </td>
              <td className='align-middle'>
                {getDate(value.complete)}
              </td>
              <td className='align-middle'>
                {getElapsed(value.timestamp, value.complete)}
              </td>
              <td className={'align-middle fw-bold ' + getFontStyle(value.status)}>
                {getIcon(value.status)} {value.status.toUpperCase()}
              </td>
              <td className='align-middle'>
                <Button action={() => navigate("/projects/" + projectID + "/table/" + tableID + "/job/" + jobID + "/logs/" + value.job_name)}>Logs</Button>
                <Button className='ms-2' action={() => restartJob(projectID, tableID, jobID, value.job_name, "training")}>Restart</Button>
                <Button className='ms-2' action={() => stopJob(projectID, tableID, jobID, value.job_name, "training")}>Stop</Button>
              </td>
            </tr>)}
          </tbody>
        </table>
      </>}

      {/* INFERENCE */}
      {pipelines !== null && pipelines.inference[jobID] !== undefined && <><div className='row'>
        <div className='col-12 text-xl fw-bold'>
          Inference executions
        </div>
      </div>

        <div className='row mt-4'>
          <div className='col-12 text-sm fw-bold'>
            PROVIDED QUERY
          </div>
          <div className='col-12 text-sm'>
            The query is used to filter the table obtaining the input dataset of executing probes.
          </div>
          <div className='col-12 mt-2'>
            <div className='w-100 bg-dark text-white px-4 py-2'>
              {getQuery("inference")}
            </div>
          </div>
        </div>

        <table className='table mt-4 '>
          <thead className='back-blue'>
            <tr>
              <th scope="col" className="col-2">Probe name</th>
              <th scope="col" className="col-2">Start time</th>
              <th scope="col" className="col-2">Finish time</th>
              <th scope="col" className="col-2">Duration</th>
              <th scope="col" className="col-2">Status</th>
              <th scope="col" className="col-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {pipelines.inference[jobID] !== undefined && pipelines.inference[jobID].jobs.map((value, index) => <tr key={index}>
              <td className='align-middle'>
                {convertProbeName(value.job_name)}
              </td>
              <td className='align-middle'>
                {getDate(value.timestamp)}
              </td>
              <td className='align-middle'>
                {getDate(value.complete)}
              </td>
              <td className='align-middle'>
                {getElapsed(value.timestamp, value.complete)}
              </td>
              <td className={'align-middle fw-bold ' + getFontStyle(value.status)}>
                {getIcon(value.status)} {value.status.toUpperCase()}
              </td>
              <td className='align-middle'>
                <Button action={() => navigate("/projects/" + projectID + "/table/" + tableID + "/job/" + jobID + "/logs/" + value.job_name)}>Logs</Button>
                <Button className='ms-2' action={() => restartJob(projectID, tableID, jobID, value.job_name, "inference")}>Restart</Button>
                <Button className='ms-2' action={() => stopJob(projectID, tableID, jobID, value.job_name, "inference")}>Stop</Button>
              </td>
            </tr>)}
          </tbody>
        </table>
      </>}

    </div>

  </BaseTheme >
}