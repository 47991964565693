import { createContext, useContext, useEffect, useState } from "react"

// Initialize the context
const AuthContext = createContext(null);

// Create auth context provider
export function AuthContextProvider({ children }) {

    const [user, setUser] = useState(null);

    useEffect(() => {

        // Execute update
        update()

        // Add event listener on the storage
        window.addEventListener('storage', (e) => update());

    }, [])

    function update() {

        // Read values from the local storage
        var token = localStorage.getItem("adq-token")
        var username = localStorage.getItem("adq-username")

        if (token !== null && username !== null)
            setUser({
                "username": username,
                "token": token
            })
        else
            console.log("Please sign in.")
    }

    return (
        <AuthContext.Provider value={user}>
            {children}
        </AuthContext.Provider>
    )

}

// Function to determine the auth state
export function useAuth() {
    const user = useContext(AuthContext);
    return { user: user, isAuthenticated: user !== null };
}