import './style.css'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { useEffect, useState } from 'react'
import { getDate } from '../../utils/utils'
import { useParams } from 'react-router-dom'
import { useRef } from 'react'

export function Logs() {

  const { data, updateData, showPopup } = useData()
  const params = useParams()

  // ID of the current project
  const projectID = params.id

  // ID of the current table
  const jobID = params.job_id

  // ID of the current table
  const jobName = params.job_name

  // Scroll ref
  const messagesEndRef = useRef(null)


  // Current project
  let [ready, setReady] = useState(false)
  let selectedLog = null
  let currentTimestamp = null

  const initializeSelectedLogFromData = () =>{
    let logs = null
    if ( data !== null && Object.keys(data).includes("logs") && Object.keys(data.logs).includes(params.job_id)){
      logs = data.logs[jobID][jobName]
      if (logs != null && logs.length > 0)
        currentTimestamp  = logs[logs.length -1].timestamp
      selectedLog = logs
    }
  }

  useEffect(() => {
    if (ready){
      let intervalID = setInterval(downloadData, 5000)
      return (() => {
          clearInterval(intervalID)
      })
    }
  }, [ready])

  useEffect(() => {
    downloadData()
  }, [])

  function downloadData() {
    updateData({
      request: "get_logs",
      payload: {
        job_id: jobID,
        job_name: jobName,
        min_timestamp: currentTimestamp,
      },
      onSuccess: () => {
        if (!ready)
          setReady(true)
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_logs().")
      }
    })
  }

  // Find the project
/*   useEffect(() => {

    // If the project is still null and data has been already downloaded
    // find the project and assign it to React state.
    if (ready === true && data !== null && Object.keys(data).includes("logs") && Object.keys(data.logs).includes(params.job_id)){
      let logs = data.logs[jobID][jobName]
      setSelectedLog(logs)
      if (logs.length > 0)
        setCurrentTimestamp(logs[logs.length -1])
    }

  }, [data]) */

  /* useEffect(() => {
    scrollToBottom()
  }, [selectedLog])  */

  const scrollToBottom = () => {
    if (selectedLog != null) 
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  initializeSelectedLogFromData()
  scrollToBottom()

  return <BaseTheme title={"Logs"} activeItem="projects" to={projectID}>

    <div className="container-fluid">

      {/* <div className='row flex-row flex-nowrap metadata-carousel mt-3'>
        <div className="col-auto">
          <div className='text-md fw-bold'>Job name</div>
          <div className='text-lg'>{jobName}</div>
        </div>
        <div className="col-auto">
          <div className='text-md fw-bold'>Job ID</div>
          <div className='text-lg'>{jobID}</div>
        </div>
      </div> */}

      {/* No jobs placeholder */}
      {((ready === true && (selectedLog === null || selectedLog === undefined || selectedLog.length === 0))) && <div className='row mt-5'>
        <div className='col-12'>
          No logs available for the current job.
        </div>
      </div>}

      {/* Loading placeholder */}
      {((ready === false)) && <div className='row mt-5'>
        <div className='col-12'>
          Loading logs from the server...
        </div>
      </div>}

      {/* Logs list */}
      {(selectedLog != null && selectedLog.length > 0) && <div className='row'>
        <div className='col-12 h-100'>
          <div className='log-window bg-dark text-white px-3'>
            {selectedLog.map((log, index) => <div key={index} className='col-12 mb-1'>
              {getDate(log.timestamp * 1000)} [{log.level}] - {log.message}
            </div>)}
            <div ref={messagesEndRef} />
          </div>
        </div>
      </div>}

    </div>

  </BaseTheme >
}