import './style.css'

export function Block({ children, title = null }) {

    return <div className="row">

        <div className='col-12'>
            <div className='block statblock'>
                {title != null && <div className='block-title mb-3'>
                    {title}
                </div>}

                {children}
            </div>
        </div>

    </div>
}