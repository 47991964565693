import './style.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { Button } from '../../components/Button/Button'
import { Block } from '../../components/Block/Block'
import { useEffect, useState } from 'react'
import { TableExecutions } from '../executions/Executions'
import { AnomaliesCenter } from '../anomalies_center/AnomaliesCenter'
import Tooltip from '@mui/material/Tooltip';
import { icons } from '../../utils/utils'
import { SelectFromWhereBlock } from '../../components/SelectFromWhereBlock/SelectFromWhereBlock'
import { Popup } from '../../components/Popup/Popup'
import Alert from '@mui/material/Alert'


const subtypes = {
  "number": [
    "key",
    "numerical",
    "binary",
    "num_ID"
  ],
  "string": [
    "categorical",
    "city",
    "text",
    "key",
    "timestamp",
    "date",
    "country",
    "url_or_ip",
    "str_id",
    "ip_address",
    "ip_port",
    "codice_fiscale",
    "partita_iva",
    "partita_iva_e_codice_fiscale",
    "iban",
    "email_address",
    "codice_ateco"
  ]
}

export function Project() {

  const navigate = useNavigate()
  const { hash } = useLocation();
  const { data, updateData, showPopup } = useData()
  const params = useParams()

  let projectID = params.id
  let tableID = params.table_id === undefined ? null : params.table_id

  let [project, setProject] = useState(null)  // Current project
  let [title, setTitle] = useState(projectID)  // Current page title
  let [table, setTable] = useState(null)  // Current table
  let [ready, setReady] = useState(false)
  let [currentTable, setCurrentTable] = useState(-1)
  let [action, setAction] = useState(null)
  let [pipelines, setPipelines] = useState(null)
  let [metadata, setMetadata] = useState(null)
  let [clusters, setClusters] = useState(null)
  let [showChangePopup, setShowChangePopup] = useState(false)
  let [currentType, setCurrentType] = useState(null)
  let [currentSubType, setCurrentSubType] = useState(null)
  let [currentColumn, setCurrentColumn] = useState(null)
  let [itemIndex, setItemIndex] = useState(-1)
  let [tableNames, setTableNames] = useState(null)

  // fuzzy join
  let [showFuzzyJoinPopup, setShowFuzzyJoinPopup] = useState(false)
  let [currentJoinType, setCurrentJoinType] = useState(null)
  let [select, setSelect] = useState(null)
  let [where, setWhere] = useState(null)
  let [selectJoin, setSelectJoin] = useState(null)
  let [whereJoin, setWhereJoin] = useState(null)
  let [renameDict, setRenameDict] = useState(null)
  let [renameDictJoin, setRenameDictJoin] = useState(null)
  let [fuzzyColumn, setFuzzyColumn] = useState(null)
  let [fuzzyColumnJoin, setFuzzyColumnJoin] = useState(null)
  let [fuzzyTable, setFuzzyTable] = useState("")
  let [fuzzyTableJoin, setFuzzyTableJoin] = useState("")
  let [removeDuplicates, setRemoveDuplicates] = useState(false)

  useEffect(() => {
    if (data !== null && Object.keys(data).includes("tables") && Object.keys(data.tables).includes(params.id)) {
      let newTables = data.tables[params.id]
      setTable(newTables)
      let tableNames = newTables.map((t) => t["table"])
      setTableNames(tableNames)
    }
    if (table === null)
      downloadTables()
  }, [data])


  useEffect(() => {
    let active = true
    if (currentTable >= 0) {
      downloadMetadata()
      downloadClusters()
      downloadPipelines()
    }
    if (active) {
      // in case the clusters or metadata are retrieved from the server before rerendering
      // avoid setting to null if the effect is stale
      setClusters(null)
      setMetadata(null)
    }

    let intervalID = setInterval(downloadPipelines, 1000)

    return (() => {
      clearInterval(intervalID)
      active = false
    })
  }, [currentTable])

  useEffect(() => {
    if (table !== null)
      table.map((t, i) => {
        if (t.table === tableID && i !== currentTable)
          setCurrentTable(i)
      })
  }, [table, tableID])

  useEffect(() => {
    setAction(hash.slice(1))
  }, [hash])

  function downloadTables() {
    updateData({
      request: "get_tables",
      payload: {
        project_id: params.id
      },
      onSuccess: () => {
        setReady(true)
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_tables().")
      }
    })
  }

  function downloadMetadata() {
    // Download metadata
    updateData({
      request: "get_metadata",
      payload: {
        table_id: tableID,
        project_id: projectID
      },
      onSuccess: () => {

      },
      onFail: () => {
        // showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_metadata().")
      }
    })
  }

  function downloadClusters() {
    // Download active jobs
    updateData({
      request: "get_clusters",
      payload: {
        project_id: projectID,
        table: tableID
      },
      onSuccess: () => {

      },
      onFail: () => {
        // showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_clusters().")
      }
    })
  }

  function downloadPipelines() {
    if (currentTable >= 0) {
      // Download jobs
      updateData({
        request: "get_pipelines",
        payload: {
          project_id: params.id,
          table: table[currentTable].table
        },
        onSuccess: () => {
        },
        onFail: () => {
          showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_pipelines().")
        }
      })
    }
  }

  function prepareData() {
    let pipeline = data.pipelines[params.id][table[currentTable].table]

    // Set the final dict
    if (Object.keys(pipeline).includes("jobs"))
      setPipelines(pipeline.jobs)
  }

  useEffect(() => {
    if (data && data !== null && project === null)
      data.projects.map((p, i) => {
        if (p.project_id === params.id)
          updateProject(p)
      })

    /* Prepare executions */
    if (currentTable >= 0 && data && data !== null && Object.keys(data).includes("pipelines") && Object.keys(data.pipelines).includes(params.id) && Object.keys(data.pipelines[params.id]).includes(table[currentTable].table))
      prepareData()

    /* Prepare metadata */
    if (currentTable >= 0 && data && data !== null && Object.keys(data).includes("metadata") && Object.keys(data.metadata).includes(projectID) && Object.keys(data.metadata[projectID]).includes(tableID))
      setMetadata(data.metadata[projectID][tableID])

    /* Prepare clusters */
    if (currentTable >= 0 && data && data !== null && Object.keys(data).includes("clusters") && Object.keys(data.clusters).includes(projectID) && Object.keys(data.clusters[projectID]).includes(tableID))
      setClusters(data.clusters[projectID][tableID])

  }, [data])

  /* useEffect(() => {
    if (data && data !== null && ready === true) {
      setTable(data.tables[params.id])
      console.log(data.tables, table)
    }
  }, [data, ready]) */

  const updateProject = (newProject) => {
      setProject(newProject)
      if (newProject != null && newProject.project_name != null)
        setTitle(newProject.project_name)
  }

  const closePopup = () => {
    setCurrentSubType(null)
    setCurrentType(null)
    setItemIndex(-1)
    setShowChangePopup(false)
  }

  const openPopup = (col_index) => {
    setItemIndex(col_index)
    setCurrentSubType(metadata.columns[col_index].col_subtype.toLowerCase())
    setCurrentType(metadata.columns[col_index].col_type.toLowerCase())
    setShowChangePopup(true)
  }

  const changeCurrentType = (val) => {
    setCurrentSubType(subtypes[val][0])
    setCurrentType(val)
  }

  const updateMetadata = (col_name, exclude = undefined, is_key = undefined) => {
    // get previous values
    let prev_excluded = metadata.columns.filter(col => col.col_name === col_name)[0].excluded
    let prev_is_key = metadata.columns.filter(col => col.col_name === col_name)[0].is_key
    if (itemIndex >= 0 || col_name !== undefined)
      updateData({
        request: "force_metadata",
        payload: {
          col_name: col_name !== undefined ? col_name : metadata.columns[itemIndex].col_name,
          col_type: currentType !== null ? currentType.toUpperCase() : null,
          col_subtype: currentSubType !== null ? currentSubType.toUpperCase() : null,
          project_id: projectID,
          table_id: tableID,
          excluded: (exclude !== undefined ? exclude : prev_excluded),
          is_key: (is_key !== undefined ? is_key : prev_is_key),
        },
        onSuccess: () => {
          showPopup("success", "Success!", "Metadata changes has been saved!")
          closePopup()
          downloadMetadata()
        },
        onFail: () => {
          showPopup("error", "Failed!", "Communication error with the RelAI API server while performing force_metadata().")
          closePopup()
        }
      })
  }

  const getConfig = (conn) => {
    if (conn !== undefined && conn !== null)
      if (conn.type === "google-cloud-bigquery")
        return "bigquery > " + conn.config.project_id + " > " + conn.config.dataset_name
    if (conn.type === "service-now-basic-auth")
      return conn.config.server + " > " + conn.config.dataset_name

    return ""
  }

  const getConfigExtended = (conn) => {
    if (conn !== undefined && conn !== null)
      if (conn.type === "google-cloud-bigquery")
        return <div className="row">
          <div className="col-12">
            <div className='text-sm fw-bold w-100'>BigQuery</div>
            <div className='w-100'>{conn.config.project_id + " > " + conn.config.dataset_name}</div>
          </div>
        </div>
    if (conn.type === "service-now-basic-auth")
      return <div className="row">
        <div className="col-12">
          <div className='text-sm fw-bold w-100'>Service Now Basic Auth</div>
          <div className='w-100'>{conn.config.server + " > " + conn.config.dataset_name}</div>
        </div>
      </div>

    return ""
  }

  const getIcon = (conn) => {
    if (conn !== undefined && conn !== null)
      return <img src={icons[conn.vendor]} alt={conn.type} />

    return ""
  }

  const getConnectors = () => {
    return project.project_data_source.map((t, i) => <div key={i + t.config.project_id + t.config.dataset_name} className='col-12 mb-2 on-hover'>
      <div className='row'>
        <div className='col-auto'>
          <div className='pill-rounded text-center back-blue'><img src={icons[t.vendor]} /></div>
        </div>
        <div className='col align-items-center d-flex text-break'>
          {getConfigExtended(t)}
        </div>
      </div>
    </div>)
  }

  const getMenu = () => {
    if (table === undefined || table === null)
      return <></>

    return table.map((t, i) => <div key={i + params.id + t.table} onClick={() => navigate("/projects/" + params.id + "/table/" + t.table + "#executions", { replace: true })} className='col-12 mb-4 on-hover'>
      <Block>
        <div className={'col-12 p-4 ' + (i === currentTable ? "back-blue" : "")}>
          <div className='row d-flex align-items-center'>
            <div className="col-auto">
              <div className="pill-rounded d-flex justify-content-center back-blue align-items-center fw-bold">{getIcon(t.connector)}</div>
            </div>
            <div className="col">
              <div className='text-sm'>{getConfig(t.connector)}</div>
              <div className="project-title text-lg fw-bold text-break">{t.table}</div>

              {/* For a storage account in FULL we don't show any attributes.*/}
              {(t.rows !== 'NA') && (t.columns !== 'NA') &&
                <div>{t.rows} records | {t.columns} attributes</div>
              }
              {/*For a storage account in DELTA we show the num of files under the selected folder.*/}
              {(t.columns !== 'NA') && (t.rows === 'NA') &&
                <div>{t.columns} files</div>
              }
            </div>
          </div>
        </div>
      </Block>
    </div>)
  }

  const getUsers = () => {
    return project.project_users.map((user, index) =>
      <Tooltip key={index + user.username} placement="bottom" title={user.name + " " + user.surname} arrow>
        <div className='user-icon me-2 back-blue'>{user.name.substring(0, 1)}{user.surname.substring(0, 1)}</div>
      </Tooltip>
    )
  }

  const getTableView = (t) => {
    return <Block>
      {/* Second row */}
      <div className='row d-flex align-items-center justify-content-center menu-bar'>

        {/* First button */}
        <div onClick={() => navigate("#executions", { replace: true })} className="col-auto">
          <div className={"menu-item d-flex align-items-center justify-content-center " + (action === "executions" ? "active" : "")}>Executions</div>
        </div>

        {/* Second button */}
        {metadata !== undefined && <div onClick={metadata !== null && Object.keys(metadata).length > 0 ? () => navigate("#metadata", { replace: true }) : () => { }} className="col-auto">
          <div className={"menu-item d-flex align-items-center justify-content-center " + (metadata !== null && Object.keys(metadata).length > 0 ? "" : "on-hover-denied") + (action === "metadata" ? " active" : "")}>Metadata</div>
        </div>}

        {/* Third button */}
        {clusters !== undefined && <div onClick={(clusters !== null && Object.keys(clusters.jobs).length > 0) ? () => navigate("#reports", { replace: true }) : () => { }} className="col-auto">
          <div className={"menu-item d-flex align-items-center justify-content-center " + ((clusters !== null && Object.keys(clusters.jobs).length > 0) ? "" : "on-hover-denied") + (action === "reports" ? " active" : "")}>DQ Reports</div>
        </div>}
      </div>

      <div className='col-12 p-4 pt-0'>
        {/* First row */}
        {/*         <div className='row d-flex align-items-center mb-4'>
          <div className="col-auto">
            <div className="pill-rounded d-flex justify-content-center back-blue align-items-center fw-bold">{t.table.substring(0, 1)}</div>
          </div>
          <div className="col-auto">
            <div className="ms-1 project-title text-lg fw-bold">{t.table}</div>
          </div>
        </div> */}

        {/* First action content */}
        {(action === "executions") && <div className='row'>
          <TableExecutions projectID={params.id} table={table[currentTable]} pipelines={pipelines} tableNames={tableNames} />
        </div>}

        {/* Second action content */}
        {(action === "metadata" && metadata !== null && Object.keys(metadata).length > 0) && <div className='mt-4 row'>
          <div className='row'>
            <div className='col-auto'>
              <div className='pill-blue-dark'>
                Trainings
              </div>
            </div>
          </div>
          <div className='col-auto mt-3'><Button icon="bi bi-search" action={() => navigate("/projects/" + projectID + "/table/" + tableID + "/metadata/")}>Open metadata inspector</Button></div>
          <div className='col-12'>
            {metadata.processed_by_metadata_learner != null && metadata.processed_by_metadata_learner === false &&
              <div className='col-auto mt-3'><Alert severity="warning">Rerun the metadata learner after forcing some metadata to apply the changes.</Alert></div>}
            {metadata.has_duplicates_or_nans_in_primary_key != null && metadata.has_duplicates_or_nans_in_primary_key === true &&
              <div className='col-auto mt-3'><Alert severity="warning">The metadata learner detected duplicates or missing values in the primary key. Please consider checking the indicated primary key.</Alert></div>}
            <table className='execution-table table mt-3'>
              <thead className='back-blue'>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Subtype</th>
                  <th className='text-center'>Primary Key</th>
                  <th className='text-center'>Include/Exclude</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>

              <tbody>
                {metadata.columns.map((col, j) => <tr key={j}>
                  <td className='align-middle'>
                    {/* {pipelines.metadata_learner[value].job_id} */}
                    {j + 1}
                  </td>
                  <td className='align-middle'>
                    {col.col_name}
                  </td>
                  <td className='align-middle'>
                    {col.col_type}
                  </td>
                  <td className='align-middle'>
                    {col.col_subtype}
                  </td>
                  <td className='align-middle text-center'>
                    <Button action={() => {
                      updateMetadata(metadata.columns[j].col_name, undefined, (metadata.columns[j].is_key !== undefined ? !metadata.columns[j].is_key : true))
                    }} className={metadata.columns[j].is_key !== undefined && metadata.columns[j].is_key === true ? "back-green" : "back-red"}>{metadata.columns[j].is_key !== undefined && metadata.columns[j].is_key === true ? "Primary key" : "Not Primary"}</Button>
                  </td>
                  <td className='align-middle text-center'>
                    <Button action={() => {
                      updateMetadata(metadata.columns[j].col_name, (metadata.columns[j].excluded !== undefined ? !metadata.columns[j].excluded : true))
                    }} className={metadata.columns[j].excluded !== undefined && metadata.columns[j].excluded === true ? "back-green" : "back-red"}>{metadata.columns[j].excluded !== undefined && metadata.columns[j].excluded === true ? "Include" : "Exclude"}</Button>
                  </td>
                  <td className='align-middle text-center'>
                    <Button action={() => openPopup(j)} icon="bi bi-pencil">Edit</Button>
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </div>
        }

        {/* Third action content */}
        {(action === "reports" && clusters !== null) && <div className='mt-4 row'>
          <AnomaliesCenter projectID={projectID} tableID={tableID} clusters={clusters} />
        </div>
        }

      </div>
    </Block>
  }

  const get_fuzzy_button = () => {
    return <div style={{marginTop:"1rem", marginBottom:"1rem"}}><Button action = {() => {setShowFuzzyJoinPopup(true)}} text="Fuzzy matching" >Fuzzy matching</Button></div>
    //return [{
    //  "text": "Fuzzy matching", "action": () => {
    //    setShowFuzzyJoinPopup(true)
    //  }
    //}]
  }


  const updateRemoveDuplicates = () => {
    setRemoveDuplicates(!removeDuplicates)
  }

  const closeJoinPopup = () => {
    setCurrentJoinType(null)
    setSelect(null)
    setWhere(null)
    setSelectJoin(null)
    setWhereJoin(null)
    setRenameDict(null)
    setRenameDictJoin(null)
    setFuzzyColumn(null)
    setFuzzyColumnJoin(null)
    setFuzzyTable("")
    setFuzzyTableJoin("")
    setShowFuzzyJoinPopup(false)
    setRemoveDuplicates(false)
  }



  function start_new_fuzzy_join() {
    let request = "create_new_job"
    updateData({
      request: request,
      payload: {
        project_id: projectID,
        table: fuzzyTable,
        job_name: "fuzzy_matching",
        username: localStorage.getItem("adq-username"),
        stage: "inference",
        query: {
          select: select,
          from: fuzzyTable,
          where: where
        },
        fuzzy_matching: {
          fuzzy_column: fuzzyColumn,
          fuzzy_column_join: fuzzyColumnJoin,
          table_join: fuzzyTableJoin,
          join_type: currentJoinType,
          query_join: {
            select: selectJoin,
            from: fuzzyTableJoin,
            where: whereJoin
          },
          rename_columns: jsonifyString(renameDict),
          rename_columns_join: jsonifyString(renameDictJoin),
          remove_duplicates: removeDuplicates
        }
      },
      onSuccess: () => {
        showPopup("success", "Success!", "New fuzzy join started!")
        closeJoinPopup()
      },
      onFail: () => {
        showPopup("error", "Failed!", "Something went wrong! Please retry!")
      }
    })
  }


  function jsonifyString(string) {
    if (string === null || string === "")
      return null
    return tryParseJSONObject("{" + string + "}")

  }


  function tryParseJSONObject(string) {
    if (string === null || string === "")
      return null
    try {
      var o = JSON.parse(string);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object", 
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === "object") {
        return o;
      }
    }
    catch (e) { }

    return null;
  };

  function checkFuzzyJoinFieldsComplete() {
    return fuzzyColumn !== null && fuzzyColumnJoin !== null && select !== null && selectJoin !== null && select.length !== 0 && selectJoin.length !== 0
      && currentJoinType !== null && fuzzyTable.length !== 0 && fuzzyTableJoin.length !== 0
  }


  function fuzzyJoinForm() {
    return <>
      <div className='col-12 text-xl fw-bold'>
        Start a new fuzzy join
      </div>
      <div className='row'>
        {/* FUZZY MATCHING POPUP - FIRST COLUMN */}
        <div className='col fw-bold'>
          <div className='flex-col mt-4'>
            1. Select the first table: <div className=" col-6">
              <select onChange={(e) => setFuzzyTable(e.target.value)} disabled={tableNames === null} className={"form-select" + (fuzzyTable !== "" ? " is-valid" : "")}>
                <option value={""}>Select a table</option>
                {tableNames !== null && tableNames.map((t, i) => {
                  return <option key={i + t} value={t}>{t}</option>
                })}
              </select>
              <div id="emailHelp" className="form-text">Select a table.</div>
            </div>
          </div>

          <div className='flex-col mt-2'>
            <div className='w-100 text-sm mb-1 fw-bold'>Provide a column name to use for the join.</div>
            <input type="text" className="w-100 form-control" onChange={(e) => setFuzzyColumn(e.target.value)} placeholder={"colA"} />
          </div>


          <SelectFromWhereBlock select={select} from={fuzzyTable} where={where} setSelect={setSelect} setWhere={setWhere} number={2} />


          <div className='flex-col mt-2'>
            <div className='w-100 mb-1 fw-bold'>3. Rename columns:</div>
            <input type="text" className={"w-100 form-control" + ((jsonifyString(renameDict) !== null) ? " is-valid" : "")} onChange={(e) => setRenameDict(e.target.value)} placeholder={"\"colA\":\"col1\", \"colB\":\"col2\", \"colC\":\"col3\""} />
            <small id="emailHelp" className="form-text text-muted">Provide a list of columns in the form "old_colA":"new_colA".</small>
          </div>

          <div className='col mt-3 '>
            <div className="mb-2 col-6">
              <div className='w-100 mb-1 fw-bold'>4. Join type:</div>
              <select onChange={(e) => setCurrentJoinType(e.target.value)} className={"form-select" + (currentJoinType !== null ? " is-valid" : "")}>
                <option value={null}>Join type</option>
                <option key={0} value={"1to1"}>One to one</option>
                <option key={1} value={"NtoM"}>One to many</option>
              </select>
              <div className="valid-feedback">
                Looks good!
              </div>
            </div>
          </div>
        </div>


        {/* FUZZY MATCHING POPUP - SECOND COLUMN */}
        <div className='col fw-bold'>
          <div className='flex-col mt-4'>
            1. Select the table to join with: <div className="col-6">
              <select onChange={(e) => setFuzzyTableJoin(e.target.value)} disabled={tableNames === null} className={"form-select" + (fuzzyTableJoin !== "" ? " is-valid" : "")}>
                <option value={null}>Select a table</option>
                {tableNames !== null && tableNames.map((t, i) => {
                  return <option key={i + t} value={t}>{t}</option>
                })}
              </select>
              <div id="emailHelp" className="form-text">Select a table.</div>
            </div>
          </div>

          <div className='flex-col mt-2'>
            <div className='w-100 text-sm mb-1 fw-bold'>Provide a column name to use for the join.</div>
            <input type="text" className="w-100 form-control" onChange={(e) => setFuzzyColumnJoin(e.target.value)} placeholder={"colA"} />
          </div>

          <SelectFromWhereBlock from={fuzzyTableJoin} setSelect={setSelectJoin} setWhere={setWhereJoin} number={2} />


          <div className='flex-col mt-2'>
            <div className='w-100 mb-1 fw-bold'>3. Rename columns:</div>
            <input type="text" className={"w-100 form-control" + ((jsonifyString(renameDictJoin) !== null) ? " is-valid" : "")} onChange={(e) => setRenameDictJoin(e.target.value)} placeholder={"\"colA\":\"col1\", \"colB\":\"col2\", \"colC\":\"col3\""} />
            <small id="emailHelp" className="form-text text-muted">Provide a list of columns in the form "old_colA":"new_colA".</small>
          </div>

          <div className='flex-col mt-2'>
            <div className='w-100 mb-1 mt-3 fw-bold'>4. Drop duplicates:</div>
            <div className="form-check pt-2">
              <label className={"text-md form-label fw-normal"}>Drop duplicates during join</label>
              <input className="form-check-input" type="checkbox" value="remove_duplicates" id="remove_duplicates" onChange={() => updateRemoveDuplicates()} />

            </div>
          </div>
        </div>



      </div>
    </>
  }


  return <BaseTheme title={title} refresh={downloadTables} activeItem="projects" >

    {/* Change type/subtype popup */}
    {showChangePopup === true && <div className='row change-type-popup d-flex justify-content-center align-items-center'>

      <div className='change-type-popup-box px-4 pt-4'>

        <div className='w-100 text-center text-xl fw-bold'>{currentColumn}</div>

        <div className='w-100 mb-2'>Variable type</div>

        <div className='row'>
          <div className='col-6'><div onClick={() => changeCurrentType("number")} className={'change-var-type-selector p-2 ' + (currentType === 'number' ? 'active' : '')}>Number</div></div>
          <div className='col-6'><div onClick={() => changeCurrentType("string")} className={'change-var-type-selector p-2 ' + (currentType === 'string' ? 'active' : '')}>String</div></div>
        </div>

        <div className='row'>
          <div className='w-100 mt-4 mb-2'>Variable sub-type</div>
          <div className='col-12'>
            <select onChange={(e) => setCurrentSubType(e.target.value)} className="w-100 change-var-type-selector p-2" disabled={currentType === null} name="current-subtype" id="current-subtype">
              {currentType !== null && subtypes[currentType.toLowerCase()].map((subtype, index) => <option selected={subtype === currentSubType} key={index} value={subtype}>{subtype}</option>)}
            </select>
          </div>
        </div>

        <div className='mt-3 py-3 bg-light action-block d-flex flex-row justify-content-end'>
          <Button className='me-3' action={() => closePopup()}>Cancel</Button>
          <Button action={() => updateMetadata(metadata.columns[itemIndex].col_name)}>Update metadata</Button>
        </div>

      </div>

    </div>}


    {/* FUZZY MATCHING POPUP */}
    {showFuzzyJoinPopup === true &&
      <Popup children={fuzzyJoinForm()}
        button={<Button disabled={!checkFuzzyJoinFieldsComplete()}
          action={() => start_new_fuzzy_join()} icon="bi bi-lightbulb">Start new fuzzy join</Button>}
        onPopupClose={closeJoinPopup} />
    }

    {/* MAIN CONTENT */}
    <div className="container-fluid">

      <div className='row mt-4'>

        {/* Table side bar */}
        <div className="col-12 col-md-3">
          <div className='row'>
            <div className='col-12 mb-2 text-sm fw-bold'>
              PROJECT USERS
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex flex-row'>
                {project !== null && getUsers()}
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-12 mb-2 text-sm fw-bold'>
              DATA CONNECTIONS
            </div>
          </div>
          <div className='row'>
            {project !== null && getConnectors()}
          </div>
          
            {get_fuzzy_button()}
          
          <div className='row'>
            <div className='col-12 mb-2 text-sm fw-bold'>
              TABLE LIST
            </div>
          </div>
          <div className='row'>
            {getMenu()}
          </div>
        </div>

        {(table !== null && currentTable >= 0) && <div className="col-12 col-md-9">
          <div className='row'>
            <div className='col-12 mb-2 text-sm fw-bold'>
              DETAILS
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              {getTableView(table[currentTable])}
            </div>
          </div>
        </div>}

      </div >
    </div >

  </BaseTheme >
}