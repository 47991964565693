import './style.css'

export function StatBlock({ children, title = null, subtitle = null, color = '', icon = null, action = null }) {

    return <div className="row">

        <div className='col-12'>
            <div className='block statblock p-3'>
                <div className='statblock-title mb-3'>
                    {title}
                </div>
                <div className={'statblock-content text-center ' + color}>
                    {children}
                </div>
                <div className={'statblock-content-subtitle text-center mb-3 ' + color}>
                    {subtitle}
                </div>
                {icon != null && <div className={'statblock-content-icon ' + color}>
                    <i className={icon}></i>
                </div>}
            </div>
        </div>

    </div>
}