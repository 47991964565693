import './style.css'


export function SelectFromWhereBlock({ select = null, from, where = null, setSelect, setWhere, number = -1 }) {


    return <div>
        {number !== -1 && <div className='flex-col mt-3'>
            {number}. Provide a query in standard SQL format to select the first dataset:
        </div>}
        <div className='flex-col mt-2'>
            <div className='w-100 text-sm mb-1 fw-bold'>SELECT</div>
            <input type="text" className="w-100 form-control" onChange={(e) => setSelect(e.target.value)} placeholder={"colA, colB, colC"} />
            <small id="emailHelp" className="form-text text-muted">Provide a list of columns, separated by commas. Enter "*" to select all columns.</small>
        </div>

        <div className='flex-col mt-2'>
            <div className='w-100 text-sm mb-1 fw-bold'>FROM</div>
            <input type="text" className="w-100 form-control" value={from===null?"":from} disabled={true} />
            <small id="emailHelp" className="form-text text-muted">Name of the table involved in the query.</small>
        </div>

        <div className='flex-col mt-2'>
            <div className='w-100 text-sm mb-1 fw-bold'>WHERE</div>
            <input type="text" className="w-100 form-control" onChange={(e) => setWhere(e.target.value)} placeholder="colA=10 & colB>12 & colC='APPLE'" />
            <small id="emailHelp" className="form-text text-muted">Set of conditions to filter the dataset. OPTIONAL.</small>
        </div>
    </div>
}