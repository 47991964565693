import './style.css'

export function SmartComponent({ children, isLoaded = false, className = "" }) {

  const getNetworkErrorComponent = () => {
    return <>
      <div className='smart-component-spinner'>
        <div className='h-100 d-flex justify-content-center flex-column align-items-center'>
          <div className="spinner-border" role="status">
          </div>
          <div className="sr-only">Loading</div>
        </div>
      </div>
    </>
  }

  return <>
    <div className={'smart-component row ' + className}>
      <div className='col-12'>
        {children}
        {isLoaded === false && getNetworkErrorComponent()}
      </div>
    </div>
  </>
}