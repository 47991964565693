import './style.css'


export function Popup({children, button, onPopupClose}){


    return <div className='popup d-flex flex-column justify-content-center align-items-center'>

    <div className='popup-content p-4' onClick={() => null}>
      {children}
      <div className='col-12 mt-4 justify-content-end d-flex'>
        {button}
      </div>

    </div>

    <div className="overlay" onClick={() => onPopupClose()}></div>
  </div>
}