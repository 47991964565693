import './style.css'
import { useNavigate } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { logout, useData } from '../../components/DataProvider/DataProvider'
import { useEffect, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { getDate } from '../../utils/utils';

export function UserManagement() {

  const navigate = useNavigate();
  const { data, updateData, showPopup } = useData()

  const [flag, setFlag] = useState(false)
  const [addUserPopup, setAddUserPopup] = useState(false)

  useEffect(() => {
    get_users()
  }, [])

  useEffect(() => {
    if (data.users !== undefined && data.users !== null && data.users.length > 0)
      setFlag(true)
  }, [data])

  function get_users() {
    updateData({
      request: "get_users",
      payload: {},
      onSuccess: () => {
        //showPopup("success", "Success!", "New metadata analysis started.")
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_users().")
      }
    })
  }

  function add_user() {
    updateData({
      request: "add_user",
      payload: {
        name: document.getElementById("name").value,
        surname: document.getElementById("surname").value,
        username: document.getElementById("username").value,
        role: document.getElementById("role").value
      },
      onSuccess: () => {
        showPopup("success", "Success!", "The user " + document.getElementById("username").value + "has been created.")
        setAddUserPopup(false)
        get_users()
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing add_user().")
      }
    })
  }

  function delete_user(username) {
    updateData({
      request: "delete_user",
      payload: {
        username: username
      },
      onSuccess: () => {
        showPopup("success", "Success!", "The user " + username + "has been deleted.")
        get_users()
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing delete_user().")
      }
    })
  }

  return <BaseTheme title="Profile Management" nav={false} refresh={() => get_users()} additionalButtons={[{
    "icon": "bi bi-door-closed",
    "action": () => logout(),
    "text": "Logout"
  }]}>
    <div className="container-fluid" id="user-management">

      {addUserPopup === true && <div className="user-management-popup d-flex align-items-center justify-content-center">
        <div className="user-management-popup-content">
          <div className="text-bold text-xl text-center">Add new user</div>

          <div className="row mt-4">

            {/* Name */}
            <div className="col-12">
              <label htmlFor="name" className="form-label text-sm text-bold text-uppercase">Name</label>
              <input type="text" id="name" className="form-control" placeholder="Paolo" />
            </div>

            {/* Surname */}
            <div className="col-12 mt-4">
              <label htmlFor="surname" className="form-label text-sm text-bold text-uppercase">Surname</label>
              <input type="text" id="surname" className="form-control" placeholder="Rossi" />
            </div>

            {/* Surname */}
            <div className="col-12 mt-4">
              <label htmlFor="name" className="form-label text-sm text-bold text-uppercase">E-mail address</label>
              <input type="text" id="username" className="form-control" placeholder="paolo.rossi@mail.com" />
            </div>

            {/* Role */}
            <div className="col-12 mt-4">
              <label htmlFor="name" className="form-label text-sm text-bold text-uppercase">Role</label>
              <select className="form-select" id="role">
                <option selected value="admin">Administrator</option>
                <option value="explorer">Explorer</option>
                <option value="viewer">Viewer</option>
              </select>
            </div>

            {/* Button */}
            <div className='col-auto mt-4 mx-auto'>
              <Button className='mx-2' icon={"bi bi-person-plus"} action={() => add_user()}>Add user</Button>
              <Button className='mx-2' icon={"bi bi bi-x-circle"} action={() => setAddUserPopup(false)}>Cancel</Button>
            </div>

          </div>
        </div>
      </div>}

      <div className="row">
        <div className="col-12">

          <div className="row">
            <div className="col-12 mt-4 d-flex align-items-center justify-content-center">
              {/* User profile pic */}
              <div className="overflow-hidden d-flex align-items-center justify-content-center" id="profile-pic">
                <img src={localStorage.getItem("adq-pic")} alt="Profile" />
              </div>
            </div>
            <div className="col-auto mx-auto mt-2">
              <div className="text-center text-xl fw-bold">{localStorage.getItem("adq-username")}</div>
              <div className="text-center pill-blue">{localStorage.getItem("adq-role")}</div>
            </div>
          </div>

        </div>
      </div>

      <div className="row mt-5">
        <div className='col-12 text-xl fw-bold'>
          RelAI Users
          <Button action={() => setAddUserPopup(true)} className='ms-4' icon={"bi bi-person-plus"}>Add user</Button>
        </div>
        <div className="col-12 mt-3">
          <table className="table table-hover">
            <thead className='back-blue'>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Surname</th>
                <th scope="col">Email</th>
                <th scope="col">Creation date</th>
                <th scope="col">Role</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {(data.users !== null && flag === true) && data.users.map((user, index) => <tr className='align-middle' key={index+user.username}>
                <td>{user.name}</td>
                <td>{user.surname}</td>
                <td>{user.username}</td>
                <td>{getDate(user.created)}</td>
                <td>{user.role}</td>
                <td>
                  <Button action={() => { delete_user(user.username) }} disabled={user.username === localStorage.getItem("adq-username")}>Delete</Button>
                </td>
              </tr>)}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </BaseTheme >
}