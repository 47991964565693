import './style.css'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { Button } from '../../components/Button/Button'
import { Block } from '../../components/Block/Block'
import { MiniBlock } from '../../components/MiniBlock/MiniBlock'
import { useEffect, useState } from 'react'
import { StatBlock } from '../../components/StatBlock/StatBlock'

export function Inference() {

  const navigate = useNavigate()
  const { data, updateData } = useData()

  const params = useParams()
  const projectName = params.id
  const datasetID = params.dataset_id

  let [tableData, setTableData] = useState(null)
  let [results, setResults] = useState(null)
  let [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    if (data != null) {
      setTableData(JSON.parse(data.projects[projectName].datasets[datasetID].data))
      setResults(data.projects[projectName].datasets[datasetID].results)
    }
  }, [data])

  function convertNumber(value) {
    if (value < 1000)
      return value

    if (value < 1000000)
      return parseInt(value / 1000).toFixed(1) + "k"

    return parseInt(value / 1000000).toFixed(1) + "M"
  }

  function updateSelectedItem(v0, v1) {
    if (Object.keys(results).includes(v0) === true) {
      if (Object.keys(results[v0]).includes(v1))
        setSelectedItem([v0, v1])
    }
  }

  return <BaseTheme title={"Anomalies inspector"} activeItem="projects" to="project page">
    {selectedItem !== null && <div className='popup'>

      <div className='popup-content w-100 h-100 d-flex flex-column align-items-center justify-content-center'>

        <div className='content p-4'>
          <div onClick={() => setSelectedItem(null)} className='close'>
            <i className="bi bi-x-circle"></i>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='row p-4'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-auto'>
                      <div className='text-xl text-bold'>
                        Anomaly Deep Dive Panel
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-3'>
                      <MiniBlock title="record index" icon="bi bi-list" color="red">
                        {selectedItem[1]}
                      </MiniBlock>
                    </div>
                    <div className='col-3'>
                      <MiniBlock title="column name" icon="bi bi-list" color="red">
                        {tableData.columns[selectedItem[0]]}
                      </MiniBlock>
                    </div>
                    <div className='col-3'>
                      <MiniBlock title="average quality score" icon="bi bi-list" color="red">
                        {results[selectedItem[0]][selectedItem[1]].score.toFixed(2)}%
                      </MiniBlock>
                    </div>
                    <div className='col-3'>
                      <MiniBlock title="involved probes" icon="bi bi-list" color="red">
                        {results[selectedItem[0]][selectedItem[1]].values.length}
                      </MiniBlock>
                    </div>
                  </div>

                  <div className='row mt-5'>
                    {results[selectedItem[0]][selectedItem[1]].values.map((value, i) =>
                      <div className='col-12 mb-4'>
                        <div className='row mb-2'>
                          <div className='col-auto'>
                            <div className='pill-orange'>
                              {value.probe} Probe
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-auto'>
                            <MiniBlock title="quality score" icon="bi bi-list" color="orange">
                              {value.score}%
                            </MiniBlock>
                          </div>

                          {value.probe === "Accuracy" && <div className='col-auto mb-2'>
                            <MiniBlock title="imputed value" icon="bi bi-list" color="orange">
                              {value.value}
                            </MiniBlock>
                          </div>}

                          {value.probe === "Completeness" && <div className='col-auto mb-2'>
                            <MiniBlock title="suggested value" icon="bi bi-list" color="orange">
                              {value.value}
                            </MiniBlock>
                          </div>}

                          {value.probe === "Consistency" && <div className='col-auto mb-2'>
                            <MiniBlock title="target columns" icon="bi bi-list" color="orange">
                              {value.targets}
                            </MiniBlock>
                          </div>}

                          {value.probe === "Validity" && <div className='col-auto mb-2'>
                            <MiniBlock title="target value" icon="bi bi-list" color="orange">
                              {value.value}
                            </MiniBlock>
                          </div>}


                          <div className='col-auto'>
                            <MiniBlock title="root causes" icon="bi bi-list" color="orange">
                              {value.causes}
                            </MiniBlock>
                          </div>
                          {value.probe === "Distribution" && <div className='col-12 mt-3'>
                            <div className="text-bold">Involved columns in sum relationship:</div> {value.cols}
                          </div>}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>}
    <div className="container-fluid">

      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12 col-md-auto me-md-3'>
              <div className='col-12 text-xl'>
                {projectName}
              </div>
              <div className='col-12 text-secondary'>
                ID of the current inspected project
              </div>
            </div>
            <div className='col-12 col-md-auto'>
              <div className='col-12 text-xl'>
                {datasetID}
              </div>
              <div className='col-12 text-secondary'>
                ID of the current inspected dataset
              </div>
            </div>
            <div className='col-12 col-md-auto'>
              <div className='col-12 text-xl'>
                2020-01-03 21:19:02
              </div>
              <div className='col-12 text-secondary'>
                Date and time of metadata inspection
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row my-5'>
        <div className="col">
          <StatBlock color="green" subtitle="completeness" icon="bi bi-activity">
            91.3%
          </StatBlock>
        </div>

        <div className="col">
          <StatBlock color="green" subtitle="uniqueness" icon="bi bi-activity">
            100%
          </StatBlock>
        </div>

        <div className="col">
          <StatBlock color="green" subtitle="validity" icon="bi bi-activity">
            93.7%
          </StatBlock>
        </div>

        <div className="col">
          <StatBlock color="orange" subtitle="consistency" icon="bi bi-activity">
            88.3%
          </StatBlock>
        </div>

        <div className="col">
          <StatBlock color="green" subtitle="timeliness" icon="bi bi-activity">
            100%
          </StatBlock>
        </div>

        <div className="col">
          <StatBlock color="orange" subtitle="accuracy" icon="bi bi-activity">
            80.4%
          </StatBlock>
        </div>
      </div>

      <div className='row'>
        <div className='col-auto'>
          <Button icon="bi bi-search" action={() => navigate("/projects/" + projectName + "/clusters/" + datasetID)}>Inspect anomalies clusters</Button>
        </div>
      </div>

      <div className='row mt-4'>
        <div className='col-12'>
          <Block>
            <div className='col-12 custom-overflow'>
              {tableData != null && <table className='table'>
                <thead>
                  <tr className='table-dark'>
                    {tableData != null && tableData.columns.map((item, i) => <th className='py-2 px-3' key={i} scope="col">
                      {item}
                    </th>)}
                  </tr>
                </thead>
                <tbody>
                  {tableData != null && tableData.data.map((item, i) => <tr key={i}>
                    {item.map((val, j) => <td onClick={() => updateSelectedItem(j.toString(), item[0].toString())} key={j} className={(Object.keys(results[j.toString()]).includes(item[0].toString()) ? "back-red" : "")} key={j}>
                      <div className='h-100 py-2 px-3 d-flex align-items-center'>{val !== null ? val.toString() : "-"}</div>
                    </td>)}
                  </tr>)}
                </tbody>
              </table>}
            </div>
          </Block>
        </div>
      </div>
    </div>
  </BaseTheme>
}