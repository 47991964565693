import { useEffect, useState } from 'react';
import './style.css'
import './api.js'
import Logo from '../../images/fox.png'
import { Navigate } from 'react-router-dom';
import { fetchLogin } from './api';
import { useAuth } from '../../components/AuthProvider/AuthProvider';

export function Login() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [authenticated, setAuthenticated] = useState(useAuth().isAuthenticated)

    return authenticated ? <Navigate to="/" /> : (
        <div className="container-fluid container-fluid-login justify-content-center align-items-center d-flex p-0 m-0 w-100 h-100">
            <div className="login-mask justify-content-center align-items-center d-flex flex-column p-5 bg-login shadow-lg">

                {/* Logo image */}
                <img alt="logo" className="w-50 mb-4" src={Logo} />

                {/* Username text-box */}
                <label className='text-white mb-2'>Username</label>
                <input type="text" onChange={(e) => setUsername(e.target.value)} className="input-login-adq p-3 text-center mb-4 w-100 text-white" />

                {/* Username text-box */}
                <label className='text-white mb-2'>Password</label>
                <input type="password" onChange={(e) => setPassword(e.target.value)} className="input-login-adq p-3 text-center mb-4 w-100 text-white" />

                {/* Login button */}
                <button
                    className="btn btn-login-adq p-3 mt-3"
                    onClick={() => fetchLogin(username, password, setAuthenticated)}
                >
                    Login
                </button>
            </div>
        </div>
    );
}