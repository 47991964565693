import './style.css'
import UserPlaceholder from '../../images/user.png'
import { useNavigate } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useEffect, useState } from 'react'
import { Button } from '../../components/Button/Button'
import { useData } from '../../components/DataProvider/DataProvider'
import { get_all_projects_id, get_all_users } from '../../components/DataProvider/api'

export function NewProject() {

  const navigate = useNavigate()
  const { data, updateData, showPopup } = useData()

  let [projectID, setProjectID] = useState(null)
  let [projectName, setProjectName] = useState(null)
  let [projectAdmin] = useState(localStorage.getItem("adq-username"))
  let [projectDescription, setProjectDescription] = useState(null)
  let [isCompleted, setIsCompleted] = useState(false)  // Check if the user has completed all fields

  let [selectedUsers, setSelectedUsers] = useState({})


  /**
   * Validate the project name.
   */
  function validateProjectName(value) {
    const re = new RegExp('^[A-Za-z0-9-_,. ]+$');
    setProjectName(value.length > 6 && re.test(value) ? value : null)
  }

  /**
   * Validate the content of the field of project description.
   */
  function validateProjectDescription(value) {
    const re = new RegExp('^[A-Za-z0-9-_,. ]+$');
    setProjectDescription(value.length > 10 && re.test(value) ? value : null)
  }

  /**
   * API call to create a new project.
   */
  function create_new_project() {
    updateData({
      request: "create_new_project",
      payload: {
        project_name: projectName,
        project_owner: projectAdmin,
        project_description: projectDescription,
        project_users: Object.values(selectedUsers).map((value, index) => {
            return value.username
        })
      },
      onSuccess: () => {
        showPopup("success", "Success!", "Project successfully created. Please provide a valid connector to a data source.")
        navigate(-1)
      },
      onFail: (e) => {
        showPopup("error", "Failed!", e.exception)
      }
    })
  }

  /**
   * Set the isCompleted field that indicates if the form is filled correctly.
   */
  useEffect(() => {
    setIsCompleted(projectName !== null && projectAdmin !== null && projectDescription !== null)
  }, [projectName, projectAdmin, projectDescription])

  const toggleUser = (username) => {

    if (username.length === 0)
      return

    if (selectedUsers[username])
      delete selectedUsers[username]
    else
      selectedUsers[username] = data.users.find((value) => value.username === username)

    setSelectedUsers({ ...selectedUsers })
  }

  return <BaseTheme title="Create a new project" activeItem="projects">
    <div className="container-fluid">
      <div className="row">
        {/* First column */}
        <div className='col-12'>

          <div className='row'>

            {/* Project Name */}
            <div className="col-12 col-sm-6 mb-4">
              <label className={"text-lg form-label"}>Project Name</label>
              <input onChange={(e) => validateProjectName(e.target.value)} type="text" className={"form-control" + (projectName != null ? " is-valid" : "")} />
              <div id="emailHelp" className="form-text">Only lowercase letters, numbers, spaces and - admitted.</div>
              <div className="valid-feedback">
                Looks good!
              </div>
            </div>

            {/* Project Owner */}
            <div className="col-12 col-sm-6 mb-4">
              <label className={"text-lg form-label"}>Project Owner</label>
              <input disabled value={projectAdmin} type="text" className={"form-control" + (projectAdmin != null ? " is-valid" : "")} />
              <div className="valid-feedback">
                Looks good!
              </div>
            </div>

            {/* Project description */}
            <div className="col-12 mb-4">
              <label className={"text-lg form-label"}>Project description</label>
              <textarea row={3} onChange={(e) => validateProjectDescription(e.target.value)} type="text" className={"form-control" + (projectDescription != null ? " is-valid" : "")} />
              <div id="emailHelp" className="form-text">Only letters, numbers and -_,. admitted.</div>
              <div className="valid-feedback">
                Looks good!
              </div>
            </div>

          </div>

          {/* Users list */}
          <div className="col-12 mb-4">
            <label className={"text-lg form-label"}>List of enabled users</label>
            <div className='row'>
              <div className="col">
                Selected users: <span>{Object.keys(selectedUsers).length}</span>
              </div>
              <div id="emailHelp" className="form-text mt-2">Click on the pills to add/remove a user.</div>
            </div>
            <div className='row'>
              {data.users.map((value, index) => {
                if (value.username !== projectAdmin)
                return <div className="col-auto pe-0 mt-3" key={index}>
                    <div onClick={() => toggleUser(value.username)} className={"px-3 py-2 user-card-pill on-hover on-hover-bg-" + (Object.keys(selectedUsers).includes(value.username) === true ? "green back-green" : "green back-blue")}>
                      {value.name} {value.surname}
                    </div>
                  </div>
              })}
            </div>

            <div className="mt-5">
              <Button action={() => create_new_project()} disabled={!isCompleted} color="btn-outline-1" icon="bi bi-boxes">Create {projectName} project</Button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </BaseTheme>
}