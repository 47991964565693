import React from 'react';
import ReactDOM from 'react-dom';
import { useContext, useEffect, useCallback } from 'react';
import { Button } from '../components/Button/Button';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';


/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker, when = true) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) return;

        const unblock = (navigator).block(
            tx => {
                const autoUnblockingTx = {
                    ...tx,
                    retry() {
                        // Automatically unblock the transition so it can play all the way
                        // through before retrying it. TODO: Figure out how to re-enable
                        // this block if the transition is cancelled for some reason.
                        unblock();
                        tx.retry();
                    },
                };

                blocker(autoUnblockingTx);
            }
        );

        return unblock;
    }, [navigator, blocker, when]);
}

// function ExitDialog({ open, onConfirm, onClose, message }) {
//     const handleConfirm = () => {
//         onConfirm();
//         onClose();
//     };

//     return (
//         <div class="modal" tabindex="-1" role="dialog">
//             <div class="modal-dialog" role="document">
//                 <div class="modal-content">
//                 <div class="modal-header">
//                     <h5 class="modal-title">RelAI</h5>
//                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
//                     <span aria-hidden="true">&times;</span>
//                     </button>
//                 </div>
//                 <div class="modal-body">
//                     <p>{message}</p>
//                 </div>
//                 <div class="modal-footer">
//                 <Button action={() => onClose()}>
//                     Leave
//                 </Button>
//                 <Button action={() => handleConfirm()}>
//                     Save Changes
//                 </Button>
//                 </div>
//                 </div>
//             </div>
//         </div>
//     );
// };


/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(message, when = true) {
    const blocker = useCallback(
        tx => {
            const element = document.createElement('div');
            element.setAttribute('id', 'prompt-dialog-container');
            element.setAttribute('aria-hidden', 'true');

            const closePrompt = (state) => {
                if (element) {
                    ReactDOM.unmountComponentAtNode(element);
                }
                if (!state) {
                    document.body.removeChild(element);
                } else {
                    tx.retry();
                }
            };

            document.body.appendChild(element);
            let answer = window.confirm(message);
            if (answer) {
                closePrompt(true)
            } else {
                closePrompt(false)
            }
            // ReactDOM.render(
            //     <ExitDialog
            //         open={!!message}
            //         message={message}
            //         onClose={() => closePrompt(false)}
            //         onConfirm={() => closePrompt(true)}
            //     />,
            //     element
            // );
        },
        [message]
    );

    useBlocker(blocker, when);
}
