import './style.css'
import { useNavigate } from 'react-router-dom'
import { getDate } from '../../utils/utils'

export function AnomaliesCenter({ projectID, tableID, clusters }) {

  const navigate = useNavigate()

  const getColor = (x) => {
    if (x < 50)
      return "red"
    else if (x < 100)
      return "orange"
    return "green"
  }

  const openDeepDivePage = (job, metric) => {
    if (Object.keys(clusters.jobs[job].clusters[metric].clusters).length > 0) {
      navigate("/projects/" + projectID + "/table/" + tableID + "/anomalies/" + job + "/clusters/" + metric + "#0")
    }
  }

  function MetricBlock({ metricName, index, metric, job }) {
    return <div className='col-xl-4 col-6 mt-3 text-center flex-column d-flex align-items-center' >

      <div onClick={() => openDeepDivePage(job, metricName)} className={'w-100 on-hover border rounded-3 p-4 px-5 border-' + getColor(metric.score) + ' on-hover-bg-' + getColor(metric.score)}>
        <div className={'cluster-score fw-bold d-flex justify-content-center align-items-center flex-row ' + getColor(metric.score)}>
          {metric.score}<span className='text-lg fw-bolder'>%</span>
        </div>

        <div className={'text-capitalize fw-bold ' + getColor(metric.score)}>
          {metricName}
        </div>

        <div className='text-sm'>
          {metric.anomalies} anomalies
        </div>
        <div className='text-sm'>
          {Object.keys(metric.clusters).length} clusters
        </div>

        {/* <div className='mt-2'>
      {Object.keys(clusters.jobs[key].clusters[metric].clusters).length > 0 && <Button action={() => navigate("/projects/" + projectID + "/table/" + tableID + "/anomalies/" + key + "/clusters/" + metric)}>Explore</Button>}
    </div> */}
      </div>
    </div>
  }

  function compareDates(date1, date2){
    if(date1===null){
      if(date2===null)
        return 0
      return 1
    }
    if(date2===null)
      return -1
    return date2-date1
  }

  function orderJobsByDate(){
    let jobOrderer = Object.keys(clusters.jobs).map((jobId, i) => {return {jobId: jobId, complete: clusters.jobs[jobId].complete}})

    jobOrderer.sort((j1, j2) => compareDates(j1.complete, j2.complete))

    return jobOrderer.map((v) => {return [v.jobId, clusters.jobs[v.jobId]]})
  }




  return <div className="container-fluid">

    {orderJobsByDate().map(([jobId, job], index) => (job.clusters !== null) &&
      <div key={index+jobId} className="mb-5">
        <div className='row' key={jobId + " " + index}>
          <div className='col-12'>

            <div className='row'>
              <div className='col-auto'>
                <div className='pill-blue-dark'>
                  DQ Analysis - {Object.keys(clusters.jobs).length- index}
                </div>
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-auto'>
                <div className='text-sm text-bold'>Analysis date</div>
                <div>{getDate(job.complete * 1000)}</div>
              </div>

              <div className='col-auto ms-5'>
                <div className='text-sm text-bold'>Analysis completed</div>
                <div>{job.status === true ? "Yes" : "No"}</div>
              </div>

              <div className='col-auto ms-5'>
                <div className='text-sm text-bold'>Job ID</div>
                <div>{jobId}</div>
              </div>
            </div>

            <div className='row mt-3'>
              {Object.entries(job.clusters).map(([metricName, metric], index2) => <MetricBlock key={index2+metricName+jobId} metricName={metricName} index={index2} metric={metric} job={jobId} />)}
            </div>
          </div>
        </div>
      </div>

    )}

  </div>
}