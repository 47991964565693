import './style.css'

export function MiniBlock({ children, textSize = "xl", className = null, title = null, icon = null, color = null }) {

  return <div className={"row " + className !== null ? className : ""}>

    <div className='col-12'>
      <div className='row miniblock'>
        <div className='d-flex flex-row'>
          {icon !== null && <div className={'me-3 d-flex flex-row align-items-center justify-content-center icon back-' + color}>
            <i className={icon + " " + color}></i>
          </div>}
          <div className="d-flex flex-column align-items-start justify-content-center">
            <div className={textSize === "xl" ? "value" : ("text-" + textSize)}>{children}</div>
            <div className="label text-secondary">{title}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
}