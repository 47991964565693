import './style.css'
import { useParams, useNavigate } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { useEffect, useState } from 'react'
import { Table } from '../../components/Table/Table'
import { Block } from '../../components/Block/Block'
import { Button } from '../../components/Button/Button'
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { MiniBlock } from '../../components/MiniBlock/MiniBlock'
import { getDescription, getCategorical, getNumericalSeries, getNumericalTimeSeries, getFormattingDescription, isValidRootCause, isArrayOfDict } from '../../utils/cluster_utils'
import _ from "lodash";

export function ClusterInspector() {

  const { data, updateData, showPopup } = useData()
  const params = useParams()

  // Navigation
  const navigate = useNavigate()

  // ID of the current project
  const projectID = params.id

  // ID of the current table
  const tableID = params.table_id

  // ID of the current jobs
  const jobID = params.job_id

  // ID of the current metric
  //TODO change name to param
  const metric = params.cluster

  // ID of the current cluster
  const cluster_id = params.cluster_id


  // Current project
  let [cluster, setCluster] = useState(null)
  let [dimension, setDimension] = useState(null)
  let [anomalies, setAnomalies] = useState(null)
  let [metadata, setMetadata] = useState(null)
  let [chunks, setChunks] = useState(null)
  let [inlierChunks, setInlierChunks] = useState(null)
  let [showInlierPreview, setShowInlierPreview] = useState(false)
  let [hidePlot, setHidePlot] = useState(false)
  let [excludedColumns, setExcludedColumns] = useState(null)
  let [positiveFeedbacks, setPositiveFeedbacks] = useState(new Array())
  let [negativeFeedbacks, setNegativeFeedbacks] = useState(new Array())
  let [outliersCount, setOutliersCount] = useState(null)
  let [feedbackReload, setFeedbackReload] = useState(false)

  /*
  Main download of chunks
  */
  useEffect(() => {

    if (cluster === null && data !== null && Object.keys(data).includes("clusters")
      && Object.keys(data.clusters).includes(projectID)
      && Object.keys(data.clusters[projectID]).includes(tableID)
      && Object.keys(data.clusters[projectID][tableID].jobs).includes(jobID)) {

      let dc = data.clusters[projectID][tableID].jobs[jobID].clusters[metric]
      setDimension(dc)

      let cl = dc.clusters[cluster_id]

      downloadChunks(cl.short_indices !== undefined ? cl.short_indices : cl, cl.table !== undefined ? cl.table : tableID, setChunks)
      setCluster(cl)

      if (data !== null && Object.keys(data).includes("metadata") && Object.keys(data.metadata).includes(projectID) && Object.keys(data.metadata[projectID]).includes(tableID)){
        setMetadata(data.metadata[projectID][tableID])
      }
      else{
        updateData({
          request: "get_metadata",
          payload: {
            table_id: tableID,
            project_id: projectID
          },
          onSuccess: (res) => {
            setMetadata(res)
          },
          onFail: () => {
            showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_metadata().")
          }
        })
      }

      if (cl.inlier_indices != null) {
        downloadChunks(cl.inlier_indices, cl.table !== undefined ? cl.table : tableID, setInlierChunks)
      }

    }

  }, [])
  /*
  Effect for number of outliers.
  */
  useEffect(() => {
    if (cluster) {
      let newVal = cluster.anomalies !== undefined ? cluster.anomalies : (cluster.indices !== undefined ? cluster.indices.length : cluster.length)
      setOutliersCount(newVal)
    }
  }, [cluster])

  /*
  Downloads a sample of the anomalies of the current cluster.
  */
  const downloadChunks = (indexes, table, setVariable = setChunks) => {
    updateData({
      request: "get_chunks",
      payload: {
        project_id: projectID,
        table: table,
        job_id: jobID,
        indices: indexes.length > 30 ? indexes.slice(0, 30) : indexes
      },
      onSuccess: (res) => {
        setVariable(res)
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_chunks().")
      }
    })
  }

  /*
  Feedback reload
   */
  useEffect(() => {
    if (feedbackReload) {
      updateData({
        request: "get_clusters",
        payload: {
          project_id: projectID,
          table: tableID
        },
        onSuccess: () => {
          let dc = data.clusters[projectID][tableID].jobs[jobID].clusters[metric]
          setDimension(dc)

          console.log(dc.clusters)
          if (Object.hasOwn(dc.clusters, cluster_id)) {
            let cl = dc.clusters[cluster_id]
            downloadChunks(cl.short_indices !== undefined ? cl.short_indices : cl, cl.table !== undefined ? cl.table : tableID, setChunks)
            setCluster(cl)
            setFeedbackReload(false)
          } else {
            setFeedbackReload(false)
            navigate(-1)
          }
        },
        onFail: () => {
          // showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_clusters().")
        }
      })
    }
  }, [feedbackReload])

  /*
  Activate the library that builds the plot.
  */
  useEffect(() => {
    if (dimension !== null && metric !== "accuracy" && metadata !== null)
      buildPlots()
  }, [dimension, metadata])

  useEffect(() => {
    if (metadata != null && metadata.columns != null) {
      let excludedColsList = metadata.columns.filter(col => col.excluded === true).map(col => col.col_name)
      setExcludedColumns(excludedColsList)
    }
  }, [metadata])

  const getProbeName = (name) => {
    if (name.toLowerCase().includes('row'))
      return "row_outlier"
    else if (name.toLowerCase().includes('null'))
      return "null_fixing"
    else if (name.toLowerCase().includes('col'))
      return "column_outlier"
     else if (name.toLowerCase().includes('ts'))
      return "ts_univariate"
    else if (name.toLowerCase().includes('root-cause-'))
      return "formatting"
    else if (name.toLowerCase().includes('match') || name.toLowerCase().includes('similar'))
      return "deduplication"
    else
      console.log("TO BE IMPLEMENTED")
  }

  const getOutlierIndices = () => {
    let currCluster = data.clusters[projectID][tableID].jobs[jobID].clusters[metric].clusters[cluster_id]
    if (Object.hasOwn(currCluster, "short_indices"))
      return currCluster.short_indices
    else
      return []
  }

  function maybeDisposeRoot(divId) {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root !== undefined && root.dom.id == divId) {
        root.dispose();
      }
    });
  };

  const buildPlots = () => {

    // for the probes having root causes
    let metricsWithRootCauses = ["validity", "consistency", "accuracy", "completeness", "timeliness", "integrity"]
    // for the probes not having root causes
    let metricsWithoutRootCauses = ["time validity"]

    let div_id = "deepdiveplotcluster" + cluster_id

    var plotDisplayCondition = true
    // use the display condition that best fits the type of metric we are dealing with
    if(metricsWithoutRootCauses.includes(metric))
      plotDisplayCondition = !cluster
    else if(metricsWithRootCauses.includes(metric))
      plotDisplayCondition = !cluster || !cluster.cluster_root_cause || !isValidRootCause(cluster.cluster_root_cause, metric)

    if (plotDisplayCondition) {
      setHidePlot(true)
    } else {
      var root = null
      try {
        maybeDisposeRoot(div_id);
        root = am5.Root.new(div_id);

        root.setThemes([
          am5themes_Animated.new(root)
        ]);

      } catch (e) {
        console.error("Cannot refresh the plot! " + div_id)
        console.error(e)
        return false
      }

      var plotData = null
      switch (metric) {
        case "validity":
          plotData = cluster.cluster_root_cause
          break;
        case "time validity":
          // uniforming the structure to the cases with root causes
            plotData = {
              anomalies: [],
              kde: []
            };
            // Converti il campo 'data' in 'kde'
            plotData.kde = Object.values(cluster.data)
            // Converti il campo 'indices' in 'anomalies'
            plotData.anomalies = cluster.indices.map(indexItem => {
              const matchingDataItem = plotData.kde.find(dataItem => new Date(dataItem.index).getTime() === new Date(indexItem.timestamp).getTime());
              if (matchingDataItem) {
                return {
                  index: indexItem.timestamp,
                  value: matchingDataItem.value
                };
              } else {
                return {
                  index: indexItem.timestamp,
                  value: null
                };
              }
            });
          break;
        case "consistency":
          plotData = cluster.cluster_root_cause
          break;
        case "accuracy":
          plotData = cluster.cluster_root_cause
          break;
        case "completeness":
          plotData = cluster.cluster_root_cause
          break;
        case "timeliness":
          plotData = cluster.cluster_root_cause
          break;
        case "integrity":
          plotData = cluster.cluster_root_cause
          break;
        default:
          let ix = parseInt(cluster[0])
          anomalies[getProbeName(cluster_id)][ix] !== null && anomalies[getProbeName(cluster_id)][ix].anomalies.map((an, ik) => {
            if (an !== null)
              plotData = an.root_causes
          })
          break;
      }

        if (plotData && plotData !== null)
          if(metric === "time validity")
            getNumericalTimeSeries(root, plotData["kde"], plotData["anomalies"])
          else
            if (isArrayOfDict(plotData))
              getCategorical(root, plotData, metric)
            else
              getNumericalSeries(root, plotData["kde"], plotData["anomalies"])


      return () => {
        root.dispose();
      };
    }

  }

  const feedbackIndexIncludes = (fb, index) => {
    if (Number.isInteger(index)) {
      return fb.includes(index)
    } else {
      for (let fbIdx of fb) {
        if (_.isEqual(fbIdx, index))
          return true
      }
      return false
    }
  }

  const feedbackIndexOf = (fb, index) => {
    if (Number.isInteger(index)) {
      return fb.indexOf(index)
    } else {
      let i = 0
      for (let fbIdx of fb) {
        if (_.isEqual(fbIdx, index))
          return i
        else
          i += 1
      }
      return null
    }
  }

  const feedbackIndexAdd = (fb, index) => {
    let included = feedbackIndexIncludes(fb, index)
    if (!included)
      fb.push(index)
  }

  const feedbackIndexDelete = (fb, index) => {
    let included = feedbackIndexIncludes(fb, index)
    let idx = feedbackIndexOf(fb, index)
    if (included)
      fb.splice(idx, 1);
  }

  const onSampleFeedback = (index, vote) => {
    // console.log("Vote is", vote)
    if (vote > 0) {
      feedbackIndexAdd(positiveFeedbacks, index)
      setPositiveFeedbacks(positiveFeedbacks)
      feedbackIndexDelete(negativeFeedbacks, index)
      setNegativeFeedbacks(negativeFeedbacks)
      // console.log("Setting POS:", positiveFeedbacks)
      // console.log("NEG:", negativeFeedbacks)
    } else if (vote < 0) {
      feedbackIndexAdd(negativeFeedbacks, index)
      setNegativeFeedbacks(negativeFeedbacks)
      feedbackIndexDelete(positiveFeedbacks, index)
      setPositiveFeedbacks(positiveFeedbacks)
      // console.log("Setting NEG:", negativeFeedbacks)
      // console.log("POS:", positiveFeedbacks)
    } else {
      feedbackIndexDelete(positiveFeedbacks, index)
      setPositiveFeedbacks(positiveFeedbacks)
      feedbackIndexDelete(negativeFeedbacks, index)
      setNegativeFeedbacks(negativeFeedbacks)
      // console.log("New POS:", positiveFeedbacks)
      // console.log("New NEG:", negativeFeedbacks)
    }
  }

  const cleanFeedbacks = () => {
    setPositiveFeedbacks(new Set())
    setNegativeFeedbacks(new Set())
  }

  const onSubmitFeedback = (successCallback, failCallback) => {
    let neutrals = getOutlierIndices().filter(x => !feedbackIndexIncludes(positiveFeedbacks, x) && !feedbackIndexIncludes(negativeFeedbacks, x))

    let probe_name = getProbeName(cluster_id)
    let content = {
      positive: positiveFeedbacks,
      negative: negativeFeedbacks,
      neutral: neutrals
    }

    updateData({
      request: "update_probe_feedback",
      payload: {
        table_id: tableID,
        job_id: jobID,
        probe_name: probe_name,
        content: content
      },
      onSuccess: (res) => {
        showPopup("success", "Success!", "Feedback updated!")
        cleanFeedbacks()
        successCallback(res)
        setChunks(null)
        setFeedbackReload(true)
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing update_probe_feedback().")
        cleanFeedbacks()
        failCallback()
      }
    })
  }

  const onGetFeedback = (successCallback, failCallback) => {
    cleanFeedbacks()
    let probe_name = getProbeName(cluster_id)
    updateData({
      request: "get_probe_feedback",
      payload: {
        job_id: jobID,
        probe_name: probe_name
      },
      onSuccess: (res) => {
        // Compute positive / negative indices
        let positives = []
        let negatives = []
        if (res)
          positives = res["positive"]
        if (res)
          negatives = res["negative"]

        // Update indices and callback
        setPositiveFeedbacks(positives)
        setNegativeFeedbacks(negatives)
        successCallback(res)
      },
      onFail: () => {
        showPopup("error", "Failed!", "Communication error with the RelAI API server while performing get_probe_feedback().")
        failCallback()
      }
    })
  }

  return <BaseTheme title={tableID} activeItem="projects" to={projectID}>

    <div className="container-fluid">

      {cluster !== null && <div className='mt-3'>
        <Block>
          <div className='col-12 p-4'>

            <div className='row'>
              <div className='col-auto'>
                <div className='pill-blue-dark'>
                  Cluster - {cluster_id}
                </div>
              </div>
            </div>

            <div className='row mt-3'>

              {/* Amount of anomalies per cluster */}
              <div className={'col-md-auto me-3'}>
                <MiniBlock icon={"bi bi-exclamation-triangle"} color={"red"} title="anomalies">{outliersCount}
                </MiniBlock>
              </div>

              {/* Amount of anomalies per cluster */}
              <div className={'col-md-auto me-3'}>
                <MiniBlock icon={"bi bi-eyedropper"} color={"red"} title="probe">{cluster.probe !== undefined ? cluster.probe.replace("_", " ").replace("probe", "") : metric}</MiniBlock>
              </div>

              {/* Description */}
              <div className={'col'}>
                <MiniBlock icon={"bi bi-chat"} color={"red"} title="description">{getDescription(cluster)}</MiniBlock>
              </div>

              {/*  {dimension !== null && <div className={'col-auto'}>{console.log(cluster.short_indices, dimension.data[cluster.short_indices[0]])}
                Synthetic DQ rule: <span className='fw-bold'>{metric === "validity" ? (dimension.data[cluster.short_indices[0]]["root_causes_rule"] !== undefined ? dimension.data[cluster.short_indices[0]]["root_causes_rule"] : "Not available") : "Not available"}</span>
              </div>} */}

              {(cluster.probe !== undefined && cluster.probe.replace("_probe", "") !== "formatting") && <div className='col-12 mt-3 cluster-plot'>
                {hidePlot === false && <div id={"deepdiveplotcluster" + cluster_id} className="p-0" style={{ width: "100%", height: "250px" }}></div>}
                {dimension === null && <div height={250} className="loading-cluster-plot justify-content-center align-items-center d-flex">
                  Generating the root cause plot...
                </div>}
              </div>}

              {/* KPIs section -> visible only if the metric is ACCURACY */}
              {(cluster.probe !== undefined && cluster.probe.replace("_probe", "") === "formatting") && <div className='col-12 mt-4 bg-light rounded p-3 mb-2'>
                <div className="row">
                  <div className='col-12 text-center'>
                    Root cause KPIs
                    <hr className='mt-1'></hr>
                  </div>

                </div>
                <div className="row mt-1">
                  {Object.keys(cluster.global_kpis).map((key, index) => {
                    if (cluster.global_kpis[key] !== cluster.local_kpis[key])
                      return <div className='col' key={key}>
                        <div className='row'>
                          <div className='col-12 text-center'>
                            <span className='text-lg red text-bold'>{cluster.local_kpis[key]}</span><span className='text-sm ms-2 green'>[AVG = {cluster.global_kpis[key]}]</span>
                          </div>
                          <div className='text-center'>{getFormattingDescription(key)}</div>
                        </div>
                      </div>
                  })}
                  <div className='col'>
                    <div className='row'>
                      <div className='col-12 text-center'>
                        <span className='text-lg red text-bold'>{cluster.score}%</span>
                      </div>
                      <div className='text-center'>Average reconstruction error</div>
                    </div>
                  </div>
                </div>
              </div>}

            </div>

            {cluster.inlier_indices != null &&
              <div className='row bg-light'>

                {inlierChunks == null && <div className='col-12 px-3 pt-2'>
                  Loading table...
                </div>}

                {inlierChunks != null && inlierChunks.length > 0 &&
                  <><div className='col-12'>
                    <Button action={() => setShowInlierPreview(!showInlierPreview)} className="mt-1 mb-3" icon={showInlierPreview === false ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}>{!showInlierPreview ? "Show in-distribution values preview" : "Hide in-distribution values preview"}</Button>
                  </div>
                    {(dimension !== null && showInlierPreview && metadata != null) && <div className='col-12 mt-3'>
                      {<Table
                        tableID={0}
                        data={inlierChunks}
                        metadata={metadata}
                        //anomalies={dimension}
                        cluster_id={cluster_id}
                        cluster={cluster} metric={null}
                        excludedColumns={excludedColumns} />}
                    </div>}</>}

                {inlierChunks != null && inlierChunks.length === 0 && <div className='col-12 px-3 pt-2'>
                  The webapp could not retrieve data from table.
                </div>}

              </div>}

            <div className='row'>

              {chunks === null && <div className='col-12 px-3 pt-2'>
                Loading table...
              </div>}

              {(dimension !== null && chunks !== null && chunks.length > 0 && metadata != null) && <div className='col-12 mt-3'>
                {<Table
                  tableID={1}
                  data={chunks}
                  metadata={metadata}
                  //anomalies={dimension}
                  cluster_id={cluster_id}
                  cluster={cluster}
                  metric={metric}
                  excludedColumns={excludedColumns}
                  outlierIndices={getOutlierIndices()}
                  useAnomalyFeedback={getProbeName(cluster_id) == "row_outlier" || getProbeName(cluster_id) == "column_outlier" || getProbeName(cluster_id) == "deduplication" || getProbeName(cluster_id) == "formatting" || getProbeName(cluster_id) == "ts_univariate" ? true : false}
                  getFeedbackCallback={(cb1, cb2) => onGetFeedback(cb1, cb2)}
                  sampleFeedbackCallback={(idx, vote) => onSampleFeedback(idx, vote)}
                  submitFeedbackCallback={(cb1, cb2) => onSubmitFeedback(cb1, cb2)} />}
              </div>}

              {chunks != null && chunks.length === 0 && <div className='col-12 px-3 pt-2'>
                The webapp could not retrieve data from table.
              </div>}

            </div>

          </div>


        </Block>
      </div>}

    </div>

  </BaseTheme>
}