import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Home } from './pages/home/Home'
import { Projects } from './pages/projects/Projects'
import { Project } from './pages/project/Project'
import { NewProject } from './pages/new_project/NewProject'
import { useAuth } from './components/AuthProvider/AuthProvider';
import { Metadata } from './pages/metadata/Metadata';
import { Quality } from './pages/quality/Quality';
import { Inference } from './pages/inference/Inference';
import { Sources } from './pages/sources/Sources';
import { Login } from './pages/login/Login';
import { NewConnector } from './pages/new_connector/NewConnector';
import { Settings } from './pages/settings/Settings';
import { Logs } from './pages/logs/Logs';
import { AnomaliesCenter } from './pages/anomalies_center/AnomaliesCenter';
import { Cluster } from './pages/cluster/Cluster';
import { ExecutionDetails } from './pages/execution_details/ExecutionDetails';
import { AnomaliesTable } from './pages/anomalies_table/AnomaliesTable';
import { ClusterInspector } from './pages/cluster_inspection/ClusterInspector';
import { UserManagement } from './pages/user_management/UserManagement';

// The private route checks if the user is currently logged and if so return the
// children otherwise redirect to the login page
export const PrivateRoute = ({ children }) => {

  // Call useAuth to know if the user is authenticated
  let { isAuthenticated } = useAuth()

  // If-else with the redirect
  return isAuthenticated === true ? children : <Navigate to="/login" />

}

function App() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL.replace(/^.*\/\/[^\/]+/, '')}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/projects" element={<PrivateRoute><Projects /></PrivateRoute>} />
        <Route path="/projects/new" element={<PrivateRoute><NewProject /></PrivateRoute>} />
        <Route path="/projects/:id" element={<PrivateRoute><Project /></PrivateRoute>} />
        <Route path="/projects/:id/table/:table_id" element={<PrivateRoute><Project /></PrivateRoute>} />
        <Route path="/projects/:id/table/:table_id/job/:job_id" element={<PrivateRoute><ExecutionDetails /></PrivateRoute>} />
        <Route path="/projects/:id/table/:table_id/metadata/" element={<PrivateRoute><Metadata /></PrivateRoute>} />
        <Route path="/projects/:id/table/:table_id/anomalies/" element={<PrivateRoute><AnomaliesCenter /></PrivateRoute>} />
        <Route path="/projects/:id/table/:table_id/anomalies/:job_id" element={<PrivateRoute><AnomaliesTable /></PrivateRoute>} />
        <Route path="/projects/:id/table/:table_id/anomalies/:job_id/tabular" element={<PrivateRoute><AnomaliesTable /></PrivateRoute>} />
        <Route path="/projects/:id/table/:table_id/anomalies/:job_id/clusters/:cluster" element={<PrivateRoute><Cluster /></PrivateRoute>} />
        <Route path="/projects/:id/table/:table_id/anomalies/:job_id/clusters/:cluster/inspect/:cluster_id" element={<PrivateRoute><ClusterInspector /></PrivateRoute>} />
        <Route path="/projects/:id/table/:table_id/job/:job_id/logs/:job_name" element={<PrivateRoute><Logs /></PrivateRoute>} />
        <Route path="/projects/:id/quality/:dataset_id" element={<PrivateRoute><Quality /></PrivateRoute>} />
        <Route path="/projects/:id/anomalies/:dataset_id" element={<PrivateRoute><Inference /></PrivateRoute>} />
        <Route path="/connections/" element={<PrivateRoute><Sources /></PrivateRoute>} />
        <Route path="/connections/new" element={<PrivateRoute><NewConnector /></PrivateRoute>} />
        <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><UserManagement /></PrivateRoute>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
