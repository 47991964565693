import './style.css'
import { useState, forwardRef, useImperativeHandle } from 'react'
import { useRef } from 'react';
import { Button } from '../Button/Button';
import _ from "lodash";
import { usePrompt } from '../../utils/prompt';


const ThumbIcon = forwardRef(function IconComponent(props, ref) {
  const iconRef = useRef(null);

  let [active, setActive] = useState(false)

  let toggle = () => {
    active = !active
    setActive(active)
  }

  useImperativeHandle(ref, () => ({
    impActive: () => { return active },
    impToggle: () => { toggle() },
    impClear: () => { setActive(false) },
    impEnable: () => { setActive(true) }
  }), [active]);

  let changeState = () => {
    toggle()

    let vote = 0
    if (props.upThumb) {
      if (active) {
        vote = 1
      } else {
        vote = 0
      }
    } else {
      if (active) {
        vote = -1
      } else {
        vote = 0
      }
    }

    props.changeStateCallback(props.index, vote)
  }

  if (props.upThumb) {
    return <i ref={iconRef}
      className={active == true ? "bi bi-hand-thumbs-up-fill" : "bi bi-hand-thumbs-up"}
      style={{ "font-size": "1.25em", "cursor": "pointer" }}
      onClick={() => changeState()}>
    </i>
  } else {
    return <i ref={iconRef}
      className={active == true ? "bi bi-hand-thumbs-down-fill" : "bi bi-hand-thumbs-down"}
      style={{ "font-size": "1.25em", "cursor": "pointer" }}
      onClick={() => changeState()}>
    </i>
  }

});

const FeedbackIcons = forwardRef(function IconsComponent({ index = null, changeStateCallback = null }, ref) {

  const posRef = useRef(null);
  const negRef = useRef(null);

  const onChangeState = (index, vote) => {
    if (posRef.current.impActive() == true && vote == -1)
      posRef.current.impClear()
    else if (negRef.current.impActive() == true && vote == 1)
      negRef.current.impClear()

    changeStateCallback(index, vote)
  }

  useImperativeHandle(ref, () => ({
    impClear: () => {
      posRef.current.impClear()
      negRef.current.impClear()
    },
    impEnableNeg: () => {
      negRef.current.impEnable()
    },
    impEnablePos: () => {
      posRef.current.impEnable()
    }
  }), []);

  return <div className="text-center">
    <ThumbIcon ref={posRef} index={index} upThumb={true} changeStateCallback={(idx, vote) => onChangeState(idx, vote)}></ThumbIcon>
    <ThumbIcon ref={negRef} index={index} upThumb={false} changeStateCallback={(idx, vote) => onChangeState(idx, vote)}></ThumbIcon>
  </div>
});

export function Table({
  data,
  metadata,
  defaultPage = 0,
  tableID = 0,
  defaultPageWidth = 15,
  //anomalies = null,
  cluster_id = null,
  cluster = null,
  metric = null,
  excludedColumns = null,
  outlierIndices = null,
  useAnomalyFeedback = false,
  getFeedbackCallback = null,
  sampleFeedbackCallback = null,
  submitFeedbackCallback = null }) {

  const tableRef = useRef();
  const iconsRef = useRef(new Array());

  let [columns, setColumns] = useState(metadata["columns"].map(((col_obj) => col_obj["col_name"])))
  let [onlyAnomalousColumns, setOnlyAnomalousColumns] = useState(false)

  let [enabledAnomalyFeedback, setEnabledAnomalyFeedback] = useState(false)
  let [isLoadingFeedback, setLoadingFeedback] = useState(false);
  let [isSendingFeedback, setSendingFeedback] = useState(false);

  let [isUncommitedFeedback, setUncommitedFeedback] = useState(false);
  let uncommitedFeedbackMessage = 'You have uncommited changes in the feedbacks. Discard the changes?'
  usePrompt(uncommitedFeedbackMessage, isUncommitedFeedback);

  /**
   * Feedback stuff
   */
  const onFeedbackChanged = (index, vote) => {
    sampleFeedbackCallback(index, vote)
    setUncommitedFeedback(true)
  }

  const outlierIndexIncludes = (index) => {
    if (Number.isInteger(index)) {
      return outlierIndices.includes(index)
    } else {
      for (let outlierIdx of outlierIndices) {
        if (_.isEqual(outlierIdx, index))
          return true
      }
      return false
    }
  }

  const outlierIndexOf = (index) => {
    if (Number.isInteger(index)) {
      return outlierIndices.indexOf(index)
    } else {
      let i = 0
      for (let outlierIdx of outlierIndices) {
        if (_.isEqual(outlierIdx, index))
          return i
        else
          i += 1
      }
      return null
    }
  }

  const getFeedbackCell = (row) => {
    let index = outlierIndices[row]
    return <td>
      <FeedbackIcons ref={(ref) => iconsRef.current.push(ref)} index={index} changeStateCallback={onFeedbackChanged}></FeedbackIcons>
    </td>
  }

  /**
   * Build the cell item
   */
  const getCell = (v, row, col, active) => {
    var classes = []
    var real_v = v

    // Standard cell
    if (cluster === null)
      return <td key={col}>
        {real_v !== null ? v.toString() : '-'}
      </td>

    // Error cell
    else {
      var id = cluster.short_indices[row]
      if (typeof id !== "string") // if following the old convention, no need to stringify
        id = JSON.stringify(cluster.short_indices[row])

      let targetCol = columns[col]

      try {

        var cluster_anomalies = Object.fromEntries(Object.entries(cluster.data).map(([key, value]) => [JSON.stringify(JSON.parse(key.replace("'", "\""))), value]))
        var anomaly_description = cluster_anomalies[id]

        if (anomaly_description != null) {

          // In case the value is empty, set the value as "<emtpy>"
          if (v === null || v.length === 0)
            real_v = "<empty>"

          if (metric === "time validity") {
            if (cluster.target_column === targetCol) {
              classes.push("back-red")
              classes.push("red")
              classes.push("fw-bold")
              classes.push("on-hover")
            }
          }

          // Add row outlier probe classes
          if (metric === "consistency" || metric === "timeliness" || metric === "integrity") {

            if (anomaly_description.root_causes) {
              if (anomaly_description.root_causes.map((x) => x.index.replace('_extraction_port', '').replace('_extraction_ip_address', '')).includes(targetCol)) {
                classes.push("back-red")
                classes.push("fw-bold")
                classes.push("red")
                classes.push("on-hover")
              }
            }
          }

          if (metric === "completeness") {

            if (cluster.target_column === targetCol && anomaly_description[targetCol]) {

              let anomaly = anomaly_description[targetCol]

              classes.push("green")
              classes.push("back-green")
              classes.push("fw-bold")
              classes.push("on-hover")
              real_v = anomaly["fix"]
            }
          }

          if (metric === "validity") {

            if (cluster.target_column === targetCol) {

              classes.push("back-red")
              classes.push("red")
              classes.push("fw-bold")
              classes.push("on-hover")
            }
          }

          if (metric === "accuracy") {

            if (cluster.target_column === targetCol) {
              classes.push("back-red")
              classes.push("red")
              classes.push("fw-bold")
              classes.push("on-hover")
            }
          }

        }
      } catch (e) {
        console.error(e)
      }

      if (onlyAnomalousColumns === true)
        classes.push("text-center")
      return <td className={classes.join(' ')} key={col}>
        {real_v !== null ? real_v.toString() : '-'}
      </td>
    }
  }

  const getHeader = () => {
    let out_headers = null
    let targetCols = []
    if (cluster.target_column != null && metric !== "timeliness") {// validity, completeness, accuracy, metric==null and target_column defined
      targetCols.push(cluster.target_column)
    }
    else {// consistency, integrity metric==null and target_column not defined
      targetCols.push(...cluster.cluster_root_cause.map((x) => x.index.replace('_extraction_port', '').replace('_extraction_ip_address', '')))
    }
    out_headers = columns.map((k, j) => {
      if ((excludedColumns != null && !excludedColumns.includes(k)) && (onlyAnomalousColumns === false || targetCols.includes(k)))
        return <th className={onlyAnomalousColumns === true ? "text-center" : undefined} key={j}>{k}</th>
    })
    if (enabledAnomalyFeedback)
      out_headers = [<th className={onlyAnomalousColumns === true ? "text-center" : undefined}>feedback</th>].concat(out_headers)
    return out_headers
  }

  const getRows = (row, i) => {
    let targetCols = []
    if (cluster.target_column != null && metric !== "timeliness") { // validity, completeness, accuracy, metric==null and target_column defined
      targetCols.push(cluster.target_column)
    }
    else { // consistency, integrity, metric==null and target_column not defined
      targetCols.push(...cluster.cluster_root_cause.map((x) => x.index.replace('_extraction_port', '').replace('_extraction_ip_address', '')))
    }
    let out_rows = Object.values(row).map((v, j) => {
      if ((excludedColumns != null && !excludedColumns.includes(columns[j])) && (onlyAnomalousColumns === false || targetCols.includes(columns[j])))
        return getCell(v, i, j, true)
    })
    if (enabledAnomalyFeedback)
      out_rows = [getFeedbackCell(i)].concat(out_rows)
    return out_rows
  }

  const OnFeedbackFail = () => {
    setLoadingFeedback(false)
    setSendingFeedback(false)
    setEnabledAnomalyFeedback(false)
    setUncommitedFeedback(false)
  }

  const onUpdateFeedback = (res) => {
    setLoadingFeedback(false)
    setSendingFeedback(false)
    setEnabledAnomalyFeedback(false)
    setUncommitedFeedback(false)
  }

  const onSubmitFeedback = () => {
    setLoadingFeedback(true)
    setSendingFeedback(true)
    submitFeedbackCallback(onUpdateFeedback, OnFeedbackFail)
  }

  const getFeedbackIcons = (index) => {
    let currentRef = iconsRef.current
    let currentIdx = null
    if (currentRef.length == outlierIndices.length)
      currentIdx = index
    else {
      let nullIndices = Math.floor((iconsRef.current.length - 1) / outlierIndices.length) * outlierIndices.length
      currentIdx = index + nullIndices
    }
    currentRef = currentRef[currentIdx]
    return currentRef
  }

  const onGetFeedback = (res) => {
    enabledAnomalyFeedback = true
    setEnabledAnomalyFeedback(enabledAnomalyFeedback)
    setLoadingFeedback(false)

    let currentIdx = null
    let currentRef = null

    // for (let outlierIdx of outlierIndices) {
    //   currentIdx = outlierIndexOf(outlierIdx)
    //   currentRef = getFeedbackIcons(currentIdx)
    //   currentRef.impClear()
    // }

    if (res) {
      for (let pos of res["positive"]) {
        if (outlierIndexIncludes(pos)) {
          currentIdx = outlierIndexOf(pos)
          currentRef = getFeedbackIcons(currentIdx)
          currentRef.impEnablePos()
        }
      }
      for (let neg of res["negative"]) {
        if (outlierIndexIncludes(neg)) {
          currentIdx = outlierIndexOf(neg)
          currentRef = getFeedbackIcons(currentIdx)
          currentRef.impEnableNeg()
        }
      }
    }
  }

  const onSwitchFeedbackVisibility = () => {
    if (!enabledAnomalyFeedback) {
      setLoadingFeedback(true)
      getFeedbackCallback(onGetFeedback, OnFeedbackFail)
    } else {
      iconsRef.current.length = 0

      if (isUncommitedFeedback) {
        let answer = window.confirm(uncommitedFeedbackMessage);
        if (!answer) {
          return
        }
      }

      enabledAnomalyFeedback = !enabledAnomalyFeedback
      setEnabledAnomalyFeedback(enabledAnomalyFeedback)
      setUncommitedFeedback(false)
    }
  }

  return <div className='row'>

    {/* Show table button */}
    <div className="container">
      <div className='row justify-content-between'>{/*TODO check */}
        <div className='col-4'>
          <Button action={() => setOnlyAnomalousColumns(!onlyAnomalousColumns)} className="mt-1 mb-3" icon={onlyAnomalousColumns === false ? "bi bi-brightness-high-fill" : "bi bi-brightness-high"}>
            {onlyAnomalousColumns === false ? "Only columns of interest" : "Show all data"}
          </Button>
        </div>
        {useAnomalyFeedback == true && <div className='col-4 d-flex justify-content-end'>
          {enabledAnomalyFeedback == true &&
            <Button action={() => onSubmitFeedback()} disabled={isSendingFeedback || !isUncommitedFeedback} className="mt-1 mb-3" icon="bi bi-arrow-bar-up">
              {isSendingFeedback ? "Loading..." : "Submit feedbacks"}
            </Button>}
          <Button action={() => onSwitchFeedbackVisibility()} disabled={isLoadingFeedback} className="mt-1 mb-3" icon={enabledAnomalyFeedback === false ? "bi bi-brightness-high-fill" : "bi bi-brightness-high"}>
            {enabledAnomalyFeedback === false ? (isLoadingFeedback ? "Loading..." : "Show feedbacks") : (isLoadingFeedback ? "Loading..." : "Hide feedbacks")}
          </Button>
        </div>}
      </div>
    </div>

    <div ref={tableRef} className="col-12 overflow-table">
      {/* Table containing data */}
      <table className='table'>
        <thead>
          <tr>
            {getHeader()}
          </tr>
        </thead>
        <tbody>
          {/* Generate the rows of the table */}
          {data.map((row, i) => <tr key={i}>
            {getRows(row, i)}
          </tr>)}
        </tbody>
      </table>
    </div>

  </div>
}