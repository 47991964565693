import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import { AuthContextProvider } from './components/AuthProvider/AuthProvider';
import { DataContextProvider } from './components/DataProvider/DataProvider';

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <DataContextProvider>
        <App />
      </DataContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);