import './style.css'
import { Button } from '../../components/Button/Button'
import { Block } from '../../components/Block/Block'
import { useNavigate } from 'react-router-dom'
import { BaseTheme } from '../../components/BaseTheme/BaseTheme'
import { useData } from '../../components/DataProvider/DataProvider'
import { useState } from 'react'
import { useEffect } from 'react'

export function Settings() {

  const { data, updateData, showPopup } = useData()

  let [vendor, setVendor] = useState(null)
  let [technology, setTechnology] = useState(null)
  let [currentConfig, setCurrentConfig] = useState(null)
  let [currentProbeConfig, setCurrentProbeConfig] = useState(null)
  let [newConfig, setNewConfig] = useState(false)
  let [configDict, setConfigDict] = useState({})

  let config = {
    "azure": [
      {
        "type": "azure-blob-storage",
        "vendor": "Azure",
        "descriptor": "Azure Blob Storage"
      }
    ],
    "google": [
      {
        "type": "google-cloud-storage",
        "vendor": "Google",
        "descriptor": "Google Cloud Storage"
      }
    ],
    "aws": [
      {
        "type": "aws-s3",
        "vendor": "AWS",
        "descriptor": "AWS S3 Bucket"
      }
    ]
  }

  useEffect(() => {
    if (data !== null && Object.keys(data).includes('config') && data.config !== null) {

      if (data.config.internal_storage.length > 0)
        setCurrentConfig(data.config.internal_storage[0])

      if (data.config.probes > 0)
        setCurrentProbeConfig(data.config.probes)

    }
  }, [data])

  /**
   * Validate the selected vendor.
   */
  function validateVendor(value) {
    if (value.length > 0)
      setVendor(value)
    else {
      setVendor(null)
      setTechnology(null)
      setConfigDict({})
    }
  }

  /**
   * Validate the selected technology.
   */
  function validateTechnology(value) {
    if (value.length > 0)
      setTechnology(value)
    else {
      setTechnology(null)
      setConfigDict({})
    }
  }


    /**
   * Validate the provided key.
   */
    function validateAzureBucket(key, value) {

      // Create a temp dictionary
      let tmp = { ...configDict }
      
      // TODO: double check azure bucket from regEX ^[a-z0-9](?!.*--)[a-z0-9-]{1,61}[a-z0-9]$
      if ((value.length > 3) && (value.length < 64))
        // Set the key and the value
        tmp[key] = value
  
      // Set the config dictionary
      setConfigDict(tmp)
    }

  /**
   * Validate the provided key.
   */
  function validateKey(key, value) {

    // Create a temp dictionary
    let tmp = { ...configDict }

    if (value.length > 0)
      // Set the key and the value
      tmp[key] = value

    // Set the config dictionary
    setConfigDict(tmp)
  }

  /**
   * API call to create a new project.
   */
  function create_new_connector() {
    if (technology !== null && vendor !== null)
      updateData({
        request: "configure_internal_storage",
        payload: {
          type: technology,
          vendor: vendor,
          config: configDict
        },
        onSuccess: () => {
          showPopup("success", "Success!", "The new configuration to the internal storage has been saved.")
          setNewConfig(false)
          setCurrentConfig({
            vendor: vendor,
            type: technology,
            config: configDict
          })
        },
        onFail: () => {
          showPopup("error", "Failed!", "Failed to save the new configuraiton.")
        }
      })
  }

  function get_technology_fields(technology) {

    switch (technology) {

      case "azure-blob-storage":
        return <div className="row mb-4">

          {/* Storage name */}
          <div className='col-12'>
            <label className={"text-bold form-label"}>Connection string or access key</label>
            <input disabled={technology === null} type="text" onChange={(e) => validateKey("connection_string", e.target.value)} className={"form-control" + ("connection_string" in configDict ? " is-valid" : "")} />
            <div id="emailHelp" className="form-text">Provide the key to access the storage.</div>
            <div className="valid-feedback">
              Looks good!
            </div>
          </div>

          {/* Bucket name */}
          <div className='col-12 mt-3'>
            <label className={"text-bold form-label"}>Name of the bucket in the storage</label>
            <input disabled={technology === null} type="text" onChange={(e) => validateAzureBucket("bucket", e.target.value)} className={"form-control" + ("bucket" in configDict ? " is-valid" : "")} />
            <div id="bucket" className="form-text">Provide the bucket name.</div>
            <div className="valid-feedback">
              Looks good!
            </div>
          </div>
        </div>

      case "google-cloud-storage":
        return <div className="row mb-4">

          {/* Storage name */}
          <div className='col-12'>
            <label className={"text-bold form-label"}>ID of the GCP project</label>
            <input disabled={technology === null} type="text" onChange={(e) => validateKey("project_id", e.target.value)} className={"form-control" + ("project_id" in configDict ? " is-valid" : "")} />
            <div id="emailHelp" className="form-text">Provide the ID of the project.</div>
            <div className="valid-feedback">
              Looks good!
            </div>
          </div>

          {/* Bucket name */}
          <div className='col-12 mt-3'>
            <label className={"text-bold form-label"}>Name of the bucket in the storage</label>
            <input disabled={technology === null} type="text" onChange={(e) => validateKey("bucket", e.target.value)} className={"form-control" + ("bucket" in configDict ? " is-valid" : "")} />
            <div id="emailHelp" className="form-text">Provide the bucket name.</div>
            <div className="valid-feedback">
              Looks good!
            </div>
          </div>

        </div>

      default:
        return <div className='row'></div>

    }
  }

  return <BaseTheme title="Settings" activeItem="settings" nav={false}>
    <div className="container-fluid">

      <div className='row'>

        {/* INTERNAL STORAGE CONFIGURATION */}
        <div className='col-6'>
          <Block>
            <div className='col-12 p-4 pb-0'>

              {/* First info block */}
              <div className='row mt-2'>

                {/* Title bar */}
                <div className="col-12">
                  <div className="project-title text-lg">RelAI Internal Storage</div>
                </div>

                {/* Description */}
                <div className='col-12 text-secondary text-md mt-1'>
                  The internal storage persists ML models and all other artifacts.
                </div>

              </div>

              {(currentConfig !== null) && <div className='row mt-4'>
                <div className='col-6'>
                  <div className='text-bold label'>Vendor</div>
                  <div className='value'>{currentConfig.vendor}</div>
                </div>
                <div className='col-6'>
                  <div className='text-bold label'>Technology</div>
                  <div className='value'>{currentConfig.type}</div>
                </div>
                {currentConfig.config !== null && Object.keys(currentConfig.config).map((k, index) => <div key={index} className='col-6 mt-3 text-break'>
                  <div className='text-bold label'>{k}</div>
                  <div className='value'>{currentConfig.config[k]}</div>
                </div>)}
              </div>}

              {(newConfig === true && currentConfig !== null) && <hr className='mt-5 mb-4' />}

              {newConfig === true &&
                <div className='row mt-4'>

                  {/* SELECT THE VENDOR */}
                  <div className="mb-4 col-6">
                    <label className={"text-bold form-label"}>Vendor</label>
                    <select onChange={(e) => validateVendor(e.target.value)} className={"form-select" + (vendor != null ? " is-valid" : "")}>
                      <option value="">Select a vendor...</option>
                      {Object.keys(config).map((vendor, i) => {
                        return <option value={vendor}>{vendor}</option>
                      })}
                    </select>
                    <div id="emailHelp" className="form-text">Select the vendor of the data source.</div>
                    <div className="valid-feedback">
                      Looks good!
                    </div>
                  </div>

                  {/* SELECT THE TECHNOLOGY */}
                  <div className="mb-4 col-6">
                    <label className={"text-bold form-label"}>Technology</label>
                    <select value={technology === null ? "" : technology} onChange={(e) => validateTechnology(e.target.value)} disabled={vendor === null} className={"form-select" + (technology !== null ? " is-valid" : "")}>
                      <option value="">Select a technology...</option>
                      {vendor !== null && config[vendor].map((tech, i) => {
                        return <option value={tech.type}>{tech.descriptor}</option>
                      })}
                    </select>
                    <div id="emailHelp" className="form-text">Select the technlogy of the data source.</div>
                    <div className="valid-feedback">
                      Looks good!
                    </div>
                  </div>

                  {/* FIELDS */}
                  {technology !== null && get_technology_fields(technology)}
                </div>}

              {/* Action block */}
              <div className='col-12 m-0 px-0 py-3 mt-3 action-block d-flex flex-row justify-content-end'>
                {/* <Button className="me-2" icon="bi bi-people">Add team members</Button> */}
                {newConfig === true && <Button className="me-2" icon="bi bi-x-circle	" action={() => setNewConfig(false)}>Cancel</Button>}
                {newConfig === true && <Button icon="bi bi-arrow-up-circle	" action={() => create_new_connector()}>Save the configuration</Button>}
                {newConfig === false && <Button icon="bi bi-plus-circle	" action={() => setNewConfig(true)}>Create new configuration</Button>}
              </div>
              
            </div>
          </Block>
        </div>

      </div>

    </div>
  </BaseTheme>
}